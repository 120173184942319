import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import { getToken, collChatFunctions } from "../../../components/src/Base64Conversion";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import React, {  RefObject } from "react";
import { Response } from "../../cfproposalmanagement/src/UnderReviewProposal.web";
import { Milestone as MileStoneDataType } from "./ContractDetailsController.web";
import { GroupChannel, GroupChannelHandler, GroupChannelModule, SendbirdGroupChat } from "@sendbird/chat/groupChannel";
import SendbirdChat, { BaseChannel, SendbirdChatWith, SendbirdError, User } from "@sendbird/chat";
import { SendBird_Info } from "../../../../packages/blocks/chat/src/ChatController";
import { BaseMessage, FileMessage, FileMessageCreateParams, PreviousMessageListQuery, PreviousMessageListQueryParams, UserMessage, UserMessageCreateParams } from "@sendbird/chat/message";
const config = require("../../../framework/src/config.js")
export const configJSONChat = require("../../chat/src/config.js");

export interface WorkOpportunityData {
  data?: {
    id: string;
    type: string;
    attributes: WorkOpportunityAttributes;
  };
}

interface WorkOpportunityAttributes {
  title: string;
  description: string;
  skills: Skill[];
  deliverables: string;
  work_type: string;
  location: string;
  experience_level: string;
  required_hours_per_week: number;
  rate_type: string;
  rate_amount: number;
  project_timeline_type: string;
  project_timeline: number;
  start_date: string;
  files_or_links: string | null;
  added_projects: string[];
  attached_files: string[];
  question: string[];
  agree_to_terms_and_conditions: boolean;
  created_at: string;
  updated_at: string;
  Posted_time_ago: string;
  account_id: number;
  profile_image: string;
  account_details: AccountDetails;
  first_name: string;
  take_action_until: string;
  decline_time: string | null;
  contract: ContractData;
  activate_status: string | null;
  Post_history: PostHistory;
  contract_history: string | null;
  status: string | null;
  ongoing_status: string | null;
  is_saved_opportunity: boolean;
  saved_opportunity_id: string | null;
}

interface Skill {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  category_id: number;
}

interface AccountDetails {
  background_color: string | null;
  user_location: string | null;
}

interface ContractData {
  data: Contract[];
}

interface Contract {
  id: string;
  type: string;
  attributes: ContractAttributes;
}

interface ContractAttributes {
  id: number;
  start_date: string;
  edited_start_date: string | null;
  proposal_id: number;
  account_id: number;
  terms_and_condition_accept: boolean;
  decline_contract: boolean;
  project_timeline: number;
  created_at: string;
  updated_at: string;
  contract_status: ContractStatus;
  contract_overall_budget: number;
  milestones: Milestones;
  work_opportunity_title: string;
  after_edit_project_timeline: number;
  project_timeline_type: string;
  project_rate_type: string;
  after_edits_overall_budget: number;
  contract_sent_on_date: string;
  resent_contract_sent_on_date: string;
  take_action_until: string;
  edit_request_n_date: string;
  awaing_action_till: string;
}

interface ContractStatus {
  id: number;
  receiver_id: number;
  status: string;
  contract_id: number;
  created_at: string;
  updated_at: string;
  action_taken_by: number;
}

interface Milestones {
  data: Milestone[];
}

interface Milestone {
  id: number;
  name: string;
  description: string;
  amount: number;
  due_date: string | null;
  milestone_status: string;
  activate: boolean;
  deliverables: Deliverable[];
  milestone_type: string;
  work_submission: boolean;
  view_request_change: boolean;
  is_approved: boolean;
  after_activate_description: string | null;
  after_activate_name: string | null;
  after_activate_amount: number | null;
  after_activate_due_date: string | null;
  remaining_milestones?: number;
}

export type Deliverable = {
  id: number;
  deliverable: string;
  deliverable_type: string;
  after_activate_deliverable: string | null;
};
interface DeliverableAttributes {
  id: number;
  deliverable: string;
}

interface PostHistory {
  data: PostHistoryItem[];
}

interface PostHistoryItem {
  id: string;
  type: string;
  attributes: PostHistoryAttributes;
}

interface PostHistoryAttributes {
  id: number;
  work_opportunity_id: number;
  event: string;
  event_date: string;
  referenceable_type: string;
  referenceable_id: number;
}

export interface ProposalData {
  data?: {
    id: string;
    type: string;
    attributes: ProposalAttributes;
  };
}

interface ProposalAttributes {
  id: number;
  name: string;
  location: string | null;
  submitted_rate: number;
  ratings: number;
  skills: string[];
  projects: string[];
  give_your_pitch: string;
  hourly_rate: number;
  overall_project_estimate: number;
  resource_files: any[];
  links: string | null;
  terms_and_conditions: boolean;
  created_at: string;
  updated_at: string;
  proposal_status: string;
  answer: string[];
  profile_photo: string | null;
  work_timeline: number;
  work_opportunity_title: string;
  work_opportunity_status: string;
  start_date: string;
  work_timeline_type: string;
  rate_type: string;
  rate_amount: number;
  background_color: string | null;
  required_hours_per_week: number;
  received_time_ago: string;
  user_name: string;
  views: number;
  likes: number;
  proposal_history: ProposalHistory;
}

interface ProposalHistory {
  data: ProposalHistoryItem[];
}

interface ProposalHistoryItem {
  id: string;
  type: string;
  attributes: ProposalHistoryAttributes;
}

interface ProposalHistoryAttributes {
  id: number;
  proposal_id: number;
  event: string;
  event_date: string;
  ref_type: string;
  ref_id: number;
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isContractEnd: boolean;
  expanded: boolean[];
  showUpcoming: boolean;
  mileStoneData:any[];
  accordingToCondiitonData:any[];
  remaining_milestones:number;
  token:string;
  userType:string;
  activeID:number;
  isViewAllMilestone:boolean;
  contractData:any;
  addMileStone:boolean;
  milestoneName:string;
  initialMilestoneName:string;
  milestoneDescription:string;
  initialMilestoneDescription:string;
  milestoneDeliverables:{
    id:number|string;
    deliverable:string;
    deliverableError:boolean;
    deliverable_type: string;
    prevAdd:boolean
  }[];
  initialMilestoneDeliverables:{
    id:number|string;
    deliverable:string;
    deliverableError:boolean;
    deliverable_type: string;
    prevAdd:boolean
  }[];
  milestoneAmount:number|null;
  initialMilestoneAmount:number|null;
  milestoneDueDate:Dayjs|null;
  initialMilestoneDueDate:Dayjs|null;
  isMilestoneNameLimit:boolean;
  isWordLimitDescription:boolean;
  openCalender:boolean;
  toastOpen:boolean;
  toastError:string;
   message:string;
  action:string;
  submitWorkOpen:boolean;
  files: {
    filename:string
      data: string
  }[],
  files_or_links: string,
  linkList: string[],
  submitDescritpion:string;
  isWordLimitSubmitDescritpion:boolean;
  isWordLimitReasonDescritpion:boolean;
  isSubmession:boolean;
  active_contract_milestone:any;
  editPopUp:boolean;
  milestoneId:number;
  milestoneType:string;
  isViewSubmessionPopUp:boolean;
  isViewSubmessionPopUpAll:boolean;
  isViewSubmessionMilestonePopUp:boolean;
  viewSubmessionData:any;
  afterSubmessiondata:boolean;
  editAmountCheck:boolean;
  awaitMilestoneIndex:null|number;
  notificationdata:any;
  showNotification:boolean;
  resourcelinks:any[];
  notification_count:number;
  unread_notification_count:number;
  page:number;
  submessionLinksData:any[];
  deleteDataState:any[];
  viewAllMilestoneData:any[];
  indexOfNotification:boolean;
  menuOpen:boolean;
  temp:number;
  endContractPopUp:boolean;
  reasonEndContractDescription:string;
  giveRatingPopUp:boolean;
  ratingCount:number;
  feedBack:string;
  feedbackError:boolean;
  raiseDisputePopUp:boolean;
  raiseDisputeDescription:string;
  raiseDisputeDescriptionError:boolean;
  raiseDisputeDetails:any;
  raiseDisputeDetailsData:{
    attributes:{
        id: number;
contract_id: number;
issue: string;
created_at:string;
raised_by:string;
updated_at:string;
is_resolved: boolean,
raised_date: string;
resolved_date: null|string;
    }
  }[];
  viewWorkPost:boolean;
  viewProposal:boolean;
  proposalData:ProposalData;
  workOpportunityData:WorkOpportunityData;
  addOrEditMilestoneTitle: string;
  addOrEditMilestoneButtonTitle: string;
  openRequestChangeModal: boolean;
  activeMilestoneData: MileStoneDataType;
  sendBirdAppMainChannel: GroupChannel | null;
  messages: (UserMessage | FileMessage)[];
  isMessagesLoading: boolean;
  channelUrl: string;
  fileUploadAnchorEl: null | HTMLElement;
  input: string;
  unreadMessageCount: number;
  error: string;
  user: User | null;
  loading: boolean;
  isChatRoomOpen: boolean;
  query: null | PreviousMessageListQuery;
  userId: string;
  accessToken: string;
  changeRequestString: string;
  inputKey:number;
  activeIndex: number;
  designer_transaction: number
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CfActiveContractForDesignerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataContractId:string="";
  postAddMilestoneID:string="";
  submitWorkId:string="";
  editMilestoneId:string="";
  refElm: RefObject<HTMLInputElement> = React.createRef();
viewSubmissionId:string='';
resourceLinkId:string='';
notificationLinkId:string='';
notificationViewId:string='';
allSubmessionId:string="";
viewAllMilestoneId:string="";
createContractEnd:string="";
raiseDisputeId:string="";
raiseDisputeAllId:string="";
viewProposalId:string="";
viewWrokPostId:string="";
resolveRaiseDisputeId:string="";
sendBirdApp: SendbirdChatWith<GroupChannelModule[]> | null = null;
lastMessageRefOne: HTMLDivElement | null = null;
chatHistoryConRefOne: HTMLDivElement | null = null;
getRequestedChangesApiId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      isContractEnd:false,
      accessToken: "",
      userId: "",
      query: null,
      isChatRoomOpen: false,
      error: "",
      user: null,
      loading: false,
      fileUploadAnchorEl: null,
      input: "",
      unreadMessageCount: 0,
      isMessagesLoading: false,
      channelUrl: "",
      messages: [],
      sendBirdAppMainChannel: null,
      submessionLinksData:[],
      workOpportunityData:{},
      proposalData:{},
      raiseDisputeDetailsData:[],
      raiseDisputeDetails:{},
      raiseDisputeDescription:"",
      raiseDisputeDescriptionError:false,
      raiseDisputePopUp:false,
      giveRatingPopUp:false,
  ratingCount:0,
  feedBack:"",
  feedbackError:false,
      reasonEndContractDescription:"",
      endContractPopUp:false,
      temp:0,
      indexOfNotification:false,
      viewAllMilestoneData:[],
      showNotification:false,
      menuOpen:false,
      deleteDataState:[],
      page:1,
      resourcelinks: [  ],
notification_count:0,
unread_notification_count:0,
      notificationdata: [
      ],
      expanded: [],
      awaitMilestoneIndex:null,
      isViewSubmessionMilestonePopUp:false,
      toastError:"",
      editAmountCheck:false,
      submitDescritpion:"",
      isViewSubmessionPopUp:false,
      isViewSubmessionPopUpAll:false,
      showUpcoming: false,
      milestoneId:0,
      milestoneType:"",
      isSubmession:false,
      isWordLimitSubmitDescritpion:false,
      isWordLimitReasonDescritpion:false,
  submitWorkOpen:false,
  files: [],
  files_or_links: "",
  active_contract_milestone:{},
  linkList: [],
      activeID:0,
      userType:"",
      token:"",
      mileStoneData:[],
      accordingToCondiitonData:[],
      remaining_milestones:1,
      contractData:[],
      isViewAllMilestone:false,
      addMileStone:false,
      milestoneName:"",
      initialMilestoneName:"",
      initialMilestoneDescription:"",
  milestoneDescription:"",
  milestoneDeliverables:[{id:0,deliverable:"",deliverableError:false,deliverable_type: "BxBlockDashboard::Deliverable",prevAdd:false}],
  initialMilestoneDeliverables:[{id:0,deliverable:"",deliverableError:false,deliverable_type: "BxBlockDashboard::Deliverable",prevAdd:false}],
  milestoneAmount:null,
  initialMilestoneAmount:null,
  milestoneDueDate:dayjs(),
  initialMilestoneDueDate:dayjs(),
  isMilestoneNameLimit:false,
  openCalender:false,
  isWordLimitDescription:false,
  toastOpen:false,
  message:"",
  action:"",
  editPopUp:false,
  viewSubmessionData:[],
  afterSubmessiondata:false,
  viewWorkPost:false,
  viewProposal:false,
  addOrEditMilestoneTitle: "Request new milestone",
  addOrEditMilestoneButtonTitle: "Request milestone",
  openRequestChangeModal: false,
  activeMilestoneData: {} as MileStoneDataType,
  changeRequestString: "",
  inputKey: 0,
  activeIndex: 1,
  designer_transaction: 0
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (responseJson?.error || responseJson?.errors) return;

    switch (apiRequestCallId) {
      case this.viewProposalId:
        this.setState({ proposalData: responseJson });
        break;

      case this.resolveRaiseDisputeId:
        this.getAllListOfDispute();
        this.setState({ raiseDisputeDetails: null });
        break;

      case this.viewWrokPostId:
        this.setState({ workOpportunityData: responseJson });
        break;

      case this.notificationViewId:
        this.setState({ indexOfNotification: true });
        this.getNotificationLink(this.state.page);
        break;

      case this.getDataContractId:
        this.handleContractDataResponse(responseJson)
        break;

      case this.postAddMilestoneID:
        this.showSuccessToast("Milestone created successfully!");
        this.resetStateForAddMilestone();
        this.setState({ addMileStone: false,showUpcoming:false });
        this.getDataContract(this.state.activeID);
        break;

      case this.editMilestoneId:
        this.showSuccessToast("Milestone edited successfully!");
        this.resetStateForAddMilestone();
        this.getDataContract(this.state.activeID);
        break;

      case this.submitWorkId:
        this.setState({ isSubmession: true, submitWorkOpen: false });
        this.showSuccessToast("Work submitted successfully.");
        this.resetSubmitFormState();
        this.getDataContract(this.state.activeID);
        break;

      case this.viewSubmissionId:
        this.setState({ viewSubmessionData: responseJson?.data, afterSubmessiondata: responseJson?.data.length === 2 });
        break;

      case this.notificationLinkId:
        this.notificationLinkResponse(responseJson)
        break;

      case this.allSubmessionId:
        this.getSubmissionData(responseJson)
        break;

      case this.resourceLinkId:
        this.setState({ resourcelinks: responseJson });
        break;

      case this.viewAllMilestoneId:
        this.setState({ viewAllMilestoneData: responseJson?.combined_milestones });
        break;

      case this.createContractEnd:
        this.setState({ giveRatingPopUp: false });
        this.showSuccessToast(responseJson.message);
        setTimeout(() => this.props.navigation.navigate("ContractPage"), 2000);
        break;

      case this.raiseDisputeId:
        this.setState({ raiseDisputePopUp: false });
        this.getDataContract(this.state.activeID);
        this.getAllListOfDispute();
        break;

      case this.raiseDisputeAllId:
        this.setState({ raiseDisputeDetailsData: responseJson?.data });
        break;

      case this.getRequestedChangesApiId:
        this.showRequestedChangesModal(responseJson);
        break;

      default:
        break;
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
  await this.getTokenData()
    const url = window.location.href;
    const id = url.split('/').pop();
    this.setState({ activeID: Number(id)})
    this.getDataContract(Number(id));
    this.getResourceLink();
    this.getAllSubmessionFn()
    this.getNotificationLink(this.state.page);
    this.getAllMilestone();
    this.getAllListOfDispute();
    this.initializeSendBirdApp();
  }

  getSubmissionData = (responseJson: any) => {
    const transformedData = responseJson.data.flatMap((item: any) => {
      const submissionTime = item.attributes.submission_time;

      const files = (item.attributes.files || []).map((file: any) => ({
        file_type: file.file_type,
        file_name: file.file_name,
        url: file.url,
        submission_time: submissionTime,
      }));

      const links = (item.attributes.submission_links || []).map((link: any) => ({
        link_url: link,
        submission_time: submissionTime,
      }));

      return [...files, ...links];
    });
    this.setState((prevState) => ({
      submessionLinksData: [...transformedData, ...prevState.submessionLinksData],
    }));
  }

  notificationLinkResponse = (responseJson: any) => {
    this.setState({
      notificationdata: this.state.indexOfNotification ? this.state.notificationdata : [...this.state.notificationdata, ...responseJson?.notifications],
      unread_notification_count: responseJson?.unread_notification_count,
      notification_count: responseJson?.notification_count
    })
    this.setState({ indexOfNotification: false });
  }

  handleContractDataResponse = (responseJson: any) => {
    this.getProposalData(responseJson);
    this.getWorkPostData(responseJson);
    this.setState({ contractData: responseJson })
    const contractEnded = responseJson.contracts.data.attributes.contract_end_date ? true : false
    const milestoneData = responseJson?.activate_milestone_details;
    if(contractEnded){
      const index = milestoneData.map((m: { milestone_status: string; }) => m.milestone_status).lastIndexOf("approved");
        milestoneData.splice(index+1, 0, {
          "activate": false,
          "after_activate_amount": "",
          "after_activate_description": "",
          "after_activate_due_date": "",
          "after_activate_name": "",
          "amount": null,
          "deliverables": [],
          "description": "",
          "due_date": responseJson.contracts.data.attributes.contract_end_date,
          "id": 1,
          "is_approved": false,
          "milestone_status": "ended",
          "milestone_type": "BxBlockDashboard::ContractMilestone",
          "name": responseJson.contracts.data.attributes.contract_end_text,
          "view_request_change": false,
          "work_submission": false
      });
    }
    const remainingMilestone = milestoneData.length - 2;
    const accordingToConditionData = milestoneData.length > 2 ? milestoneData.slice(0, 2) : milestoneData;

    this.setState({
      isContractEnd : responseJson.contracts.data.attributes.contract_end_date? true:false,
      accordingToCondiitonData: this.state.isViewAllMilestone ? milestoneData :
        [...accordingToConditionData, { remaining_milestones: remainingMilestone > 0 ? remainingMilestone : 0 }],
      remaining_milestones: remainingMilestone > 0 ? remainingMilestone : 0,
      mileStoneData: milestoneData,
      expanded: new Array(milestoneData.length).fill(false),
      raiseDisputeDetails: responseJson?.dispute_raise_details,
      channelUrl: responseJson.contracts.data.attributes.sendbird_data.channels?.[0].channel_url,
      designer_transaction: responseJson.contracts.data.attributes.designer_earning,
    })
  }

  showSuccessToast = (message: string) => {
    this.setState(
      {
        toastOpen: true,
        action: "success",
        message: message,
      },
      () => {
        setTimeout(() => {
          this.setState({
            toastOpen: false,
            action: "",
            message: "",
          });
        }, 4000);
      }
    );
  }

  getMinMaxDate = () => {
    let maxDate0: Dayjs;
    let minDate0: Dayjs;

    let index = this.state.mileStoneData.findIndex(
      (item: Milestone) => item.id == this.state.milestoneId
    );

    if (index + 1 >= this.state.mileStoneData.length) {
      maxDate0 = dayjs("2099-12-31");
    } else {
      maxDate0 = dayjs(this.state.mileStoneData[index + 1].due_date, "DD/MM/YY", true);
    }

    if (index <= 0) {
      minDate0 = dayjs();
    } else {
      minDate0 = dayjs(this.state.mileStoneData[index - 1].due_date, "DD/MM/YY", true);
    }

    return { minDate0, maxDate0 };
  };
  

  initializeSendBirdApp = async () => {
    const sendBirdChatObj = SendbirdChat.init({
      localCacheEnabled: false,
      appId: SendBird_Info.appId,
      modules: [
        new GroupChannelModule()
      ]
    }) as SendbirdGroupChat
    if (!!sendBirdChatObj) {
      this.sendBirdApp = sendBirdChatObj;
      collChatFunctions(this.inialSendBirdFunctions, 2000)
    }
  }

  inialSendBirdFunctions = () => {
    this.getTokenDataFromLocalStorage();
  }

  getTokenDataFromLocalStorage = async () => {
    let tokenDetail = await getStorageData("userInfo");
    const parseData = JSON.parse(tokenDetail);
    let userId: string = "";
    let accessToken: string = "";
    if (parseData && parseData.meta) {
      (parseData.meta.id) && (userId = parseData.meta.sendbird_user_id);
      (parseData.meta.id) && (accessToken = parseData.meta.sendbird_access_token);
      this.connectingUserToSendbirdApp(`${userId}`, accessToken);
      this.setState({ userId: `${userId}`, accessToken })
    }
  }

  connectingUserToSendbirdApp = async (userId: string, accessToken: string) => {
    if (!!this.sendBirdApp) {
      try {
        const user = await this.sendBirdApp.connect(userId, accessToken);
        this.setState({ user: user }, () => this.connectSendBirdChannel());

      }
      catch (error) {
        if (error instanceof SendbirdError) {
          this.setState({ error: error.message });
        } else {
          this.setState({ error: "" });
        }
      }
    }
  }
  connectSendBirdChannel = async () => {
    if (!!this.sendBirdApp && !!this.state.channelUrl) {
      try {
        const sendBirdAppMainChannel = await this.sendBirdApp
        .groupChannel
        .getChannel(this.state.channelUrl);
        if (!!sendBirdAppMainChannel) {
          this.setState({ sendBirdAppMainChannel, unreadMessageCount: sendBirdAppMainChannel.unreadMessageCount }, () => {
            this.getMessages()
          });
          const groupChannelHandlerObj = new GroupChannelHandler({
            onMessageReceived: this.onMesssageReceiveFromChannel
          });

          this.sendBirdApp.groupChannel.addGroupChannelHandler("UNIQUE_HANDLER_ID", groupChannelHandlerObj);
        }

      } catch (error) {
        this.setState({ error: configJSONChat.errorMessage });
      }
    }
  }

  onMesssageReceiveFromChannel = (_channel: BaseChannel, message: BaseMessage) => {
    if (this.state.sendBirdAppMainChannel) {
      this.setState(prev => ({ messages: [...prev.messages, message as UserMessage] }), () => {
        setTimeout(this.scrollToLastMessageInChat, 1000)
      })
      if (this.state.isChatRoomOpen) {
        this.handleMarkAsReadFn()
      }
    }
  }

  handleMarkAsReadFn = () => {
    this.state.sendBirdAppMainChannel?.markAsRead().then(() => this.setState({
      unreadMessageCount: 0
    }));
  }

  getMessages = async () => {
    if (!!this.state.sendBirdAppMainChannel) {
      const { messages, query } = await this.retriveMessageFromChannel(this.state.sendBirdAppMainChannel);
      this.setState({ messages: messages as UserMessage[], isMessagesLoading: false, query }, () => {
        this.scrollToLastMessageInChat()
      }
      );
    }
  }

  retriveMessageFromChannel = async (sendBirdAppMainChannel: GroupChannel) => {
    const params: PreviousMessageListQueryParams = {
      limit: 20,
      reverse: false,
      includeReactions: true,
    };

    const query = sendBirdAppMainChannel.createPreviousMessageListQuery(params);
    const messages = await query.load();
    return { messages, query };
  }

  updateLastMessageRef = (element: HTMLDivElement) => {
    this.lastMessageRefOne = element;
  }

  updateChatHistoryConRef = (element: HTMLDivElement) => {
    this.chatHistoryConRefOne = element;
  }

  handleChatRoomOpen = () => {
    if (!this.state.isChatRoomOpen) {
      this.connectSendBirdChannel().then(() => {
        this.handleMarkAsReadFn()
      })
    }
    this.setState({
      isChatRoomOpen: !this.state.isChatRoomOpen
    })
  }

  handleFilesMenuFn = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ fileUploadAnchorEl: event.currentTarget })
  }

  handleCloseFn = () => {
    this.setState({ fileUploadAnchorEl: null })
  }

  handleVideoImagesUploadFn = (event: React.ChangeEvent<HTMLInputElement>) => {

    if (event.target.files) {
      const file = event.target.files[0];

      if (file && (file.type.startsWith('image/') || file.type.startsWith('video/')) || this.isDocumentType(file.type)) {
        if (this.state.sendBirdAppMainChannel) {
          const params: FileMessageCreateParams = {
            file: file,
            fileName: file.name,
            fileSize: file.size,
            thumbnailSizes: [{ maxWidth: 200, maxHeight: 300 }, { maxWidth: 400, maxHeight: 600 }],
            mimeType: file.type,
          };
          this.state.sendBirdAppMainChannel.sendFileMessage(params)
            .onSucceeded((message) => {
              const newMessage = message as (UserMessage | FileMessage);
              this.setState(previous => ({ messages: [...previous.messages, newMessage], input: "", fileUploadAnchorEl: null }))
              this.scrollToLastMessageInChat();
            });
        }
      }
    }
  }

  handleChatInputChangeFn = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ input: event.target.value })
  }

  isDocumentType = (type: string) => {
    const documentTypes = [
      'application/vnd.ms-excel',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/pdf',
      'text/plain',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    return documentTypes.includes(type)
  }

  handleChatInputKeyDownFn = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      this.sendMessageToChannelFn()
    }
  }

  sendMessageToChannelFn = () => {
    if (this.state.input.trim().length > 0
    ) {
      this.sendMessage(this.maskSecuredDataFn(this.state.input));
    }
  }

  sendMessage = (message: string) => {
    if (message.length > 0 && !!this.state.sendBirdAppMainChannel) {
      const params: UserMessageCreateParams = {
        message: message,
      }
      this.state.sendBirdAppMainChannel.sendUserMessage(params)
        .onSucceeded((message) => {
          const newMessage = message as UserMessage;
          this.setState((previous: S) => {
            return ({
              messages: [...previous.messages, newMessage],
              input: "",
            })
          }, () => {
            this.scrollToLastMessageInChat();
          }
          )
        })
    }
  }
  maskSecuredDataFn = (inputDataString: string) => {
    const matchFunction = (matchedDataString: string) => {
      return matchedDataString.slice(0, 3) + configJSONChat.starText.repeat(matchedDataString.length - 3)
    }

    const emailMatchFumction = (matchedDataString: string) => {
      let [headEmail, tailEmail] = matchedDataString.split("@");
      headEmail = headEmail.slice(0, 1) + configJSONChat.starText.repeat(headEmail.length - 1);
      return headEmail + "@" + tailEmail;
    }

    const matchedZoomFunction = (matchedDataString: string) => {
      return matchedDataString.slice(0, 5) + configJSONChat.starText.repeat(matchedDataString.length - 7) + matchedDataString.slice(matchedDataString.length - 2)
    }

    const matchedFunction = (matchedDataString: string) => {
      return matchedDataString.slice(0, 2) + configJSONChat.starText.repeat(matchedDataString.length - 4) + matchedDataString.slice(matchedDataString.length - 2)
    }

    const totalMask = (matchedDataString: string) => {
      return configJSONChat.starText.repeat(matchedDataString.length)
    }

    const termsMasking = (inputDataString: string) => {
      let sanitizedDataString = inputDataString;
      configJSONChat.secureKeywords.map((secureKeyword: string) => {
        if (sanitizedDataString.toLowerCase().includes(secureKeyword.toLowerCase())) {
          const regex = new RegExp(secureKeyword, "gi");
          sanitizedDataString = maskedLoop(sanitizedDataString, regex, totalMask);

        }
      })
      return sanitizedDataString;
    }

    const maskUnWantedTextRegex = (inputDataString: string) => {
      let sanitizedDataString: string = inputDataString;
      sanitizedDataString = termsMasking(sanitizedDataString);
      sanitizedDataString = sanitizedDataString.split(" ").map(word => {

        let count = 0;

        if (word.search(configJSONChat.specialCharRegex) !== -1) {
          count++;
        }
        if (word.search(configJSONChat.digitsRegex) !== -1) {
          count++;
        }
        if (word.search(configJSONChat.alphaRegex) !== -1) {
          count++;
        }

        return count > 1 ? totalMask(word) : word
      }).join(" ");
      sanitizedDataString = maskedLoop(sanitizedDataString, configJSONChat.paymentCardRegex, totalMask);
      sanitizedDataString = maskedLoop(sanitizedDataString, configJSONChat.nondisclosureRegex, totalMask);
      sanitizedDataString = maskedLoop(sanitizedDataString, configJSONChat.emailNameRegex, emailMatchFumction);
      sanitizedDataString = maskedLoop(sanitizedDataString, configJSONChat.zoomLinkRegex, matchedZoomFunction);
      sanitizedDataString = maskedLoop(sanitizedDataString, configJSONChat.googleMeetlinkRegex, matchedZoomFunction);
      sanitizedDataString = maskedLoop(sanitizedDataString, configJSONChat.instagramRegex, matchedZoomFunction);
      sanitizedDataString = maskedLoop(sanitizedDataString, configJSONChat.twitterLinkRegex, matchedZoomFunction);
      sanitizedDataString = maskedLoop(sanitizedDataString, configJSONChat.linkdinLinkRegex, matchedZoomFunction);
      sanitizedDataString = maskedLoop(sanitizedDataString, configJSONChat.paypalRegex, matchedZoomFunction);
      sanitizedDataString = maskedLoop(sanitizedDataString, configJSONChat.stripePaymentRegex, matchedZoomFunction);
      sanitizedDataString = maskedLoop(sanitizedDataString, configJSONChat.phoneNumberRegex, matchFunction);
      sanitizedDataString = maskedLoop(sanitizedDataString, configJSONChat.IAFCRegex, matchedFunction);
      sanitizedDataString = maskedLoop(sanitizedDataString, configJSONChat.bankAccontRegex, matchedFunction);
      sanitizedDataString = maskedLoop(sanitizedDataString, configJSONChat.pancardRegex, matchedFunction);
      return sanitizedDataString;
    }

    const maskedLoop = (inputDataString: string, regexp: RegExp, maskedFuntion: (substring: string, ...args: string[]) => string) => {
      const matches = Array.from(inputDataString.matchAll(regexp));
      let maskedData = inputDataString;
      for (const match of matches) {
        if (!!match && !!match[0] && match.index !== undefined) {
          maskedData = maskedData.slice(0, match.index) + match[0].replace(regexp, maskedFuntion) + maskedData.slice(match.index + match[0].length);
        }
      }

      return maskedData;
    }
    return maskUnWantedTextRegex(inputDataString)

  }

  scrollToLastMessageInChat = () => {
      if (this.lastMessageRefOne && this.chatHistoryConRefOne) {
        this.chatHistoryConRefOne.scrollTop = this.chatHistoryConRefOne.scrollHeight - 400;
      }
  }

   findIndexOfWait = (data:any) => {
    for (let index = 0; index < data?.length; index++) {
      const item = data[index];
      
      if (item.milestone_status === "approved") {
        if (index + 1 < data.length) {
          if (!data[index + 1].activate) {
            return index + 1; 
          }
        }
      }
    }
    
    return 0;
  };
  formatToLocaleString = (data: Response) => {
    const rate_types = data?.attributes?.rate_type;
    if (rate_types && rate_types.includes("hourly")) {
      return "pr/hr";
    }
    return "";
  }
  getTokenData = async () => {
    const token = await getToken();
     let userInfo= await getStorageData("userInfo")
     let UserType=JSON.parse(userInfo)
    this.setState({ token: token,userType:UserType?.data?.attributes?.role_name})
};
  handleNavigation = (path:string) =>{
    if(path === "ContractPage"){
      const tabValue = this.state.isContractEnd?1:2
      setStorageData("contractTab",tabValue)
    }
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }


  handleRemainingMilestone = () => {
    this.setState({ accordingToCondiitonData: this.state.mileStoneData, showUpcoming: true })
  }
  handleAccordionToggle = (index: number) => {
    const newExpanded = this.state.expanded.map((item: boolean, idx: number) =>
      idx === index ? !item : item
    );
    this.setState( 
       { expanded: newExpanded })
  };
  escapeRemainingMilestone = () => {
    const remainingMilestone = this.state.mileStoneData.length - 2;
    const newConditionData = [
      ...this.state.mileStoneData.slice(0, 2), 
      { remaining_milestones: Math.max(remainingMilestone, 0) }
    ];
    
    this.setState(prevState => ({
        accordingToCondiitonData: newConditionData,
        showUpcoming: false,
        expanded: [...prevState.expanded.slice(0, 2), ...new Array(Math.max(remainingMilestone, 0)).fill(false)]
    }));
}
  getDataContract = async (id:number) => {
    const headers = {
        "Content-Type": "application/json",
        token: this.state.token,
    };

    const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
  
      this.getDataContractId = getValidationsMsg.messageId
   
    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
       `bx_block_cfdesignersidecontractmanagement/designers_contracts/designer_active_contract_details/${id}`
      )
    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );
    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }


  //All view milestone function

  handleOpenViewAllMilestone=()=>{

    this.setState({isViewAllMilestone:true,accordingToCondiitonData:this.state.mileStoneData,
      expanded:new Array(this.state.mileStoneData.length).fill(false),
      showUpcoming:false,
    });
  }
  handleCloseViewAllMilestone=()=>{
    const remainingMilestone=this.state.mileStoneData.length-2;
    this.setState({isViewAllMilestone:false,
      accordingToCondiitonData:
      [...this.state.mileStoneData.slice(0,2), { remaining_milestones: remainingMilestone>0?remainingMilestone:0 }],
      expanded:new Array(this.state.mileStoneData.length).fill(false)
    })
  }

  //add milestone function
handleMileStoneName=(e:React.ChangeEvent<HTMLInputElement>)=>{
  const value = e.target.value.trim(); 
  const isExceedingLimit = value.length > 50;

  if (!isExceedingLimit) {
    this.setState({ milestoneName: e.target.value });
  }
  this.setState({ isMilestoneNameLimit: isExceedingLimit });
    
}
handleMileStoneNameDescription=(e:React.ChangeEvent<HTMLTextAreaElement>)=>{


  const value = e.target.value.trim(); 
  const isExceedingLimit = value.length > 500;

  if (!isExceedingLimit) {
    this.setState({ milestoneDescription: e.target.value });
  }

  this.setState({ isWordLimitDescription: isExceedingLimit });
  
    
}

handleRaiseDisputeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  const value = e.target.value.trim(); 
  const isExceedingLimit = value.length > 200;

  if (!isExceedingLimit) {
    this.setState({ raiseDisputeDescription: e.target.value });
  }

  this.setState({ raiseDisputeDescriptionError: isExceedingLimit });
};
handleReasonDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  const value = e.target.value.trim(); 
  const isExceedingLimit = value.length > 1000;

  if (!isExceedingLimit) {
    this.setState({ reasonEndContractDescription: e.target.value });
  }

  this.setState({ isWordLimitReasonDescritpion: isExceedingLimit });
};
handleFeedBackRating = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  const value = e.target.value.trim(); 
  const isExceedingLimit = value.length > 1000;

  if (!isExceedingLimit) {
    this.setState({ feedBack:  e.target.value });
  }

  this.setState({ feedbackError: isExceedingLimit });
};
handleSubmitDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  const value = e.target.value.trim(); 
  const isExceedingLimit = value.length > 1000;

  if (!isExceedingLimit) {
    this.setState({ submitDescritpion:  e.target.value });
  }

  this.setState({ isWordLimitSubmitDescritpion: isExceedingLimit });
};

handleMileStoneNameAmount=(e:React.ChangeEvent<HTMLInputElement>)=>{
  const value = e.target.value;
    const numericValue = parseFloat(value);
    if (numericValue <= 9999999) {
      this.setState({milestoneAmount:numericValue})
    } else {
      this.setState({milestoneAmount:0})

    }
    
}
showMileStoneNameError = () =>{
  return this.state.isMilestoneNameLimit ? "Reached Limit of 50 char" : ""
}
showDescriptionErrorEndContractfeedback = () =>{
  return this.state.feedbackError ? "Reached Limit of 1000 char" : ""
}
showDescriptionErrorRaiseDispute = () =>{
  return this.state.raiseDisputeDescriptionError ? "Reached Limit of 200 char" : ""
}

showDescriptionErrorEndContract = () =>{
  return this.state.isWordLimitReasonDescritpion ? "Reached Limit of 1000 char" : ""
}

showDescriptionError = () =>{
  return this.state.isWordLimitSubmitDescritpion ? "Reached Limit of 1000 char" : ""
}
showMilestoneDescriptionError = () =>{
  return this.state.isWordLimitDescription ? "Reached Limit of 500 char" : ""
}
  handleAddMileStoneClose=()=>{
    this.setState({addMileStone:false})
    if(this.state.editPopUp){
      this.setState({editPopUp:false})
    }
    this.resetStateForAddMilestone();
  }
  handleAddMileStone=()=>{
    this.setState({addMileStone:true, addOrEditMilestoneTitle: "Request new milestone", addOrEditMilestoneButtonTitle: "Request milestone", milestoneDueDate: dayjs(this.state.mileStoneData[this.state.mileStoneData.length - 1].due_date, "DD/MM/YY", true)})
  }
  handleMenuIcon=()=>{
    this.setState({menuOpen:!this.state.menuOpen})
  }
  handlePropagation = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  }
  
  openCalender = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      openCalender: true
    });
    this.handlePropagation(event);
  }
  

closeCalender=()=>{
  this.setState({
    openCalender: false
  });
  }
  handleDateChange = (date: Dayjs | null) => {
    this.setState({ milestoneDueDate: date });
};
  showDeliverableError = (select:boolean) =>{
    return select? "Reached Limit of 150 char" : ""
  }
  handleAddDeliverable = () => {
    this.setState({ milestoneDeliverables:
       [...this.state.milestoneDeliverables,
        {id:this.state.milestoneDeliverables.length,
          deliverable:"",
          deliverableError:false,
          prevAdd:false,
          deliverable_type: "BxBlockDashboard::Deliverable"
       }] });
  };
  handleDeleteDeliverable = (id:number|string) => {
    const updatedData = this.state.milestoneDeliverables.filter((item:{
      id:number|string;
      deliverable:string;
      deliverableError:boolean;
      prevAdd:boolean
  },index) => {
     return item.id!==id;
    });
let deleteData=[];
    const deletDataFind= this.state.milestoneDeliverables.find((item:{
      id:number|string;
      deliverable:string;
      deliverableError:boolean;
      prevAdd:boolean
  },index) => {
     return item.id==id && item.prevAdd;
    });
if(deletDataFind){
  deleteData.push(deletDataFind.id);
  deleteData.push(deletDataFind.deliverable_type);
}

    this.setState({ milestoneDeliverables: updatedData,deleteDataState:[...this.state.deleteDataState,deleteData] });
  };
  handleDeliverableChange = (id: number|string, e: any) => {
    const { value } = e.target;
  
    const updatedData = this.state.milestoneDeliverables.map(
      (milestone: { 
        id:number|string;
        deliverable: string; 
        deliverableError: boolean; 
        prevAdd:boolean
        deliverable_type:string;
      },index) => {
        if (milestone.id == id) {
          return {
          ...milestone,
            deliverable: value,
            deliverableError: value.trim().length > 150, 
          };
        }
        return milestone;
      }
    );
  
    this.setState({ milestoneDeliverables: updatedData });
  };
  isFeedbackVaild=()=>{
    if(this.state.feedbackError){
      return true;
    }
    return false;
  }
  isSubmitFormValidRaiseDispute=()=>{
    if(this.state.raiseDisputeDescriptionError ||!this.state.raiseDisputeDescription  ){
      return true;
    }
    return false;
  }
  isSubmitFormValidRaesonEndContarct=()=>{
    if(this.state.isWordLimitReasonDescritpion ||!this.state.reasonEndContractDescription  ){
      return true;
    }
    return false;
  }
  isSubmitFormValid=()=>{
    if(this.state.files.length>0 || this.state.linkList.length>0 ){
      return false;
    }
    return true;
  }
  handleStarClick=(index:number)=>{
    this.setState({ ratingCount: index + 1 });
  }
  isFormValid = () => {
    const { milestoneName, milestoneDescription, milestoneDueDate, milestoneDeliverables, milestoneAmount } = this.state;
    if (this.state.isWordLimitDescription||this.state.isMilestoneNameLimit||
      !milestoneName || !milestoneDescription || !milestoneAmount ||milestoneAmount<=0 || !milestoneDueDate ||
      milestoneDeliverables.some((item: {
        deliverable:string;
        deliverableError:boolean
      }) => !item.deliverable || item.deliverableError)
    ) {
      return false;
    }   
    return true;
  };

  resetStateForAddMilestone=()=>{
    this.setState({
      milestoneName:"",
      milestoneDescription:"",
      milestoneDeliverables:[
        {id:0,
          deliverable:"",
          deliverableError:false,
          prevAdd:false,
          deliverable_type: "BxBlockDashboard::Deliverable"
        }],
      milestoneAmount:null,
      editPopUp:false,
      milestoneDueDate:dayjs(this.convertDateFormat(this.state.mileStoneData[this.state.mileStoneData.length-1].due_date)),
      isMilestoneNameLimit:false,
      deleteDataState:[],
      openCalender:false,
      isWordLimitDescription:false
    })
  }

// implement api for add milestone

handleAddMilestoneApi = () => {
  const headers = {
    "Content-Type": "application/json",
    token:this.state.token
  };
  const deliverablesData=this.state.milestoneDeliverables.map((item)=>{
    return item.deliverable
  })
  const httpBody = {
    "data" : {
        "attributes" : {
            "contract_id" : this.state.activeID,
            "milestone_details" : {
                "name" : this.state.milestoneName,
                "description" : this.state.milestoneDescription,
                "deliverables" : deliverablesData,
                "amount": this.state.milestoneAmount ,
                "due_date" : this.state.milestoneDueDate
            }
        }
    }
}
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.postAddMilestoneID = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "/bx_block_cfdesignersidecontractmanagement/client_contracts/add_milestone"
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

handleToastClose=()=>{
  this.setState({toastOpen:false})
}


//for submit work pop up


handleSubmitPopUpOpen=(editPartAttribute:any)=>{
  this.setState({submitWorkOpen:true,active_contract_milestone:editPartAttribute})
}
handleSubmitWork=()=>{
  
  const headers = {
    token:this.state.token
  };

  let submitFormData=new FormData();
  submitFormData.append("work_submission[contract_id]", String(this.state.activeID));
  submitFormData.append("work_submission[submission_links]", this.state.linkList.join(','));
  submitFormData.append("work_submission[message]",this.state.submitDescritpion );
  submitFormData.append("work_submission[contract_milestone_id]", this.state.active_contract_milestone.id);
  submitFormData.append("work_submission[contract_milestone_type]", this.state.active_contract_milestone.milestone_type);
  {
    this.state.files?.length>0 &&
    this.state.files.forEach((item:any)=>{
      submitFormData.append("work_submission[files][]", item.data,item?.filename);
    })
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.submitWorkId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "bx_block_cfdesignersidecontractmanagement/designer_work_submissions/submit_work/"
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    submitFormData
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;

}
handleSubmitPopUpClose=()=>{
  this.setState({submitWorkOpen:false})
  this.resetSubmitFormState();
}

resetSubmitFormState=()=>{
  this.setState({
    linkList:[],
    files:[],
    submitDescritpion:"",
    toastError:""
  })
}

validFileFormat = (fileData: File) => {
  const validFileTypes = [
    "image/jpeg",
    "image/png",
    "image/jpg",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ];
  if (!validFileTypes.includes(fileData.type)) {
    this.setState({
      toastError: "Please select a valid file (jpg, png, pdf, doc, docx).",
   
    });
    return false;
  }
  return true
}

handleResourceFiles = async (event: React.ChangeEvent<HTMLInputElement>) => {
  if(this.state.files.length >= 5)
    return
  const maxSizes = {
    image: 25 * 1024 * 1024
  };

  if (!event.target.files) return;
  const tempFile = event.target.files[0];
  if (!this.validFileFormat(tempFile)) {
    this.setState({ inputKey: Date.now() });
    return;
  }
  const maxFileSize = maxSizes.image;
  if (tempFile.size > maxFileSize) {
    this.setState({
  
      toastError: `File size exceeds 25MB. Please upload a smaller file.`,
      inputKey: Date.now()
    });
    return;
  }


  const fileObject = {
    filename: tempFile.name,
    data: tempFile
  };

  this.setState((prevState: any) => ({
    files: [...prevState.files, fileObject],
    toastError: "",
    inputKey: Date.now()
  }));
};




updateLink = (data: string) => {
  this.setState({
    files_or_links: data
  });
}

handlelinkData = (event: any) => {
  const keyData = event.keyCode ? event.keyCode : event.which

  if (keyData === 13 && this.isUrlValid(this.state.files_or_links)) {
    this.setState({
      linkList: [...this.state.linkList, this.state.files_or_links],
      files_or_links: "",
    toastError:""
    })
  }
}
isUrlValid = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    this.setState({
  
      toastError: "Please enter valid link",
 
    })
    return false;
  }
}
handleDeleteLink = (id:number) =>{
  let newValue = this.state.linkList.filter((_,index)=>{
    return index !== id
  })

  this.setState({linkList:newValue})
}
openImageInNewTab(base64Data:File) {
  const fileURL = URL.createObjectURL(base64Data);
  window.open(fileURL, "_blank");
  setTimeout(() => URL.revokeObjectURL(fileURL), 5000);
}

handleDeleteFile = (id:number) =>{
  let newValue = this.state.files.filter((_,index)=>{
    return index !== id
  })
  this.setState({files:newValue})
}

isFileAdded = () => {
  return this.state.files.length > 0 ? true : false;
}

//for edit milestone

 convertDateFormat=(dateString:string) =>{
  let [day, month, year] = dateString.split('/');
  year = year.length === 2 ? `20${year}` : year;
  return `${month}/${day}/${year}`;
}

  openEditMilestonePopUp = (data: any) => {
    const dataMilestone = data.deliverables
    .filter((item: { id: number | string; deliverable: string }) => item.deliverable) // Filter valid items
    .map((item: { id: number | string; deliverable: string }) => ({
      ...item,
      deliverableError: false,
      prevAdd: true,
    }));
    this.setState({
      addMileStone: true,
      editAmountCheck:data.activate,
      editPopUp: true,
      milestoneType:data.milestone_type	,
      milestoneId: data.id,
      milestoneName: data.name,
      initialMilestoneName: data.name,
      milestoneDescription: data.description,
      initialMilestoneDescription: data.description,
      milestoneDeliverables:dataMilestone,
      initialMilestoneDeliverables:dataMilestone,
      milestoneAmount: data.amount,
      initialMilestoneAmount: data.amount,
      milestoneDueDate:dayjs(this.convertDateFormat(data?.due_date)),
      initialMilestoneDueDate: dayjs(this.convertDateFormat(data?.due_date)),
      isMilestoneNameLimit: false,
      openCalender: false,
      isWordLimitDescription: false,
      addOrEditMilestoneTitle: "Request edit in milestone",
      addOrEditMilestoneButtonTitle: "Request Edits"
    })
  }

  hasMilestoneChanged = () => {
    const changed = {
        milestoneName: this.state.milestoneName !== this.state.initialMilestoneName,
        milestoneDescription: this.state.milestoneDescription !== this.state.initialMilestoneDescription,
        milestoneDeliverables: JSON.stringify(this.state.milestoneDeliverables) !== JSON.stringify(this.state.initialMilestoneDeliverables),
        milestoneAmount: this.state.milestoneAmount !== this.state.initialMilestoneAmount,
        milestoneDueDate: !dayjs(this.state.milestoneDueDate).isSame(dayjs(this.state.initialMilestoneDueDate), 'day'),
    };
    return Object.values(changed).some(value => value === true);
};


  handleEditMilestone = () => {
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token
    };
    const data = this.state.milestoneDeliverables.map((item: any) => {
      if (item.prevAdd) {
        return {
          id: item.id,
          deliverable: item.deliverable,
          deliverable_type:item.deliverable_type
        }
      }
      else {
        return {
          new_deliverable: item.deliverable,
        }

      }

    })

    const httpBody = {
      "data": {
        "attributes": {
          "contract_id":this.state.activeID,
          "milestone_type": this.state.milestoneType,
          "milestone_id": this.state.milestoneId,
      "milestone_edits": {
        "after_activate_name": this.state.milestoneName,
        "after_activate_description": this.state.milestoneDescription,
        "after_activate_amount": this.state.milestoneAmount,
        "after_activate_due_date": (this.state.milestoneDueDate as Dayjs).format("YYYY-MM-DD"),
        "deliverables": data,
        "deleted_deliverables":this.state.deleteDataState
      },
    }
  }}


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editMilestoneId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_cfdesignersidecontractmanagement/client_contracts/edit_milestone`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleApiForAddEditMilestone = () => {
    this.handleAddMileStoneClose();
    if (this.state.editPopUp) {
      this.handleEditMilestone()
    }
    else {
      this.handleAddMilestoneApi()
    }
  }



   openisViewSubmession=(data:any, index: number)=>{

this.setState({isViewSubmessionPopUp:true,active_contract_milestone:data, activeIndex: index})
this.getSubmissionViewData(data);

  }

  closeisViewSubmession=()=>{
    this.setState({isViewSubmessionPopUp:false,isViewSubmessionPopUpAll:false})
      }
   
      handleisViewSubmessionMilestonePopUp=()=>{
        this.setState({
          isViewSubmessionMilestonePopUp:!this.state.isViewSubmessionMilestonePopUp
        })
      }
    
      getSubmissionViewData = async (data:any) => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
    
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
      
          this.viewSubmissionId = getValidationsMsg.messageId
       
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
           `bx_block_cfdesignersidecontractmanagement/designer_work_submissions/get_submissions_based_on_milestone/?work_submission[contract_id]=${this.state.activeID}&work_submission[contract_milestone_id]=${data.id}&work_submission[contract_milestone_type]=${data.milestone_type}`
          )
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }
    

      downloadImage = async (item: any) => {
        const response = await fetch(config.baseURL + item.url);
        const blob = await response.blob();
        const link = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        link.href = objectUrl;
        link.download = item.file_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(objectUrl);
      };
      
      getResourceLink = async () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
    
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
      
          this.resourceLinkId = getValidationsMsg.messageId
       
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
           `bx_block_cfdesignersidecontractmanagement/contract_resources/${this.state.activeID}`
          )
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }

      getAllSubmessionFn = async () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
    
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
      
          this.allSubmessionId = getValidationsMsg.messageId
       
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_cfdesignersidecontractmanagement/designer_work_submissions/view_submissions/?work_submission[contract_id]=${this.state.activeID}`
          )
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }
      getNotificationLink = async (page:number) => {
        this.setState({page:page})
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
    
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
      
          this.notificationLinkId = getValidationsMsg.messageId
       
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_notifications/notifications/contract_notifications?contract_id=${this.state.activeID}&page=${page}&per_page=5`
          )
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }
      getViewAllNotification = async (id:number) => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
    
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
      
          this.notificationViewId = getValidationsMsg.messageId
       
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
           `bx_block_notifications/notifications/${id}`
          )
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }
       
      getAllMilestone = async () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
    
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
      
          this.viewAllMilestoneId = getValidationsMsg.messageId
       
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
           `bx_block_cfdesignersidecontractmanagement/designers_contracts/list_milestones?id=${this.state.activeID}`
          )
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }
       
      handleCloseRatingPopUp = () => {
        this.setState({ giveRatingPopUp: false,reasonEndContractDescription: '', feedBack: '', ratingCount: 0 })
      }

      contractEndFn = () => {
        const allApproved = this.state.mileStoneData.every(milestone => milestone.milestone_status === "approved")
        const headers = {
          "Content-Type": "application/json",
          "token":this.state.token
        };
    
        const httpBody ={
          "contract_end": {
              "status": allApproved ? "contract_end_succesfully":"contract_mid_ended",
              "contract_id": this.state.activeID,
              "reason": this.state.reasonEndContractDescription,
              "client_feedback":this.state.feedBack,
              "client_rating": this.state.ratingCount
          }
      };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.createContractEnd = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          "bx_block_cfdesignersidecontractmanagement/designers_contracts/create_contract_end"
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      };
      handleRaiseDisputeFn = () => {
        const headers = {
          "Content-Type": "application/json",
          token:this.state.token
        };
        const httpBody = {
          "raise_dispute": {
              "contract_id": this.state.activeID,
              "issue": this.state.raiseDisputeDescription
          }
      }
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.raiseDisputeId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          "bx_block_cfdisputemanagement/raise_disputes"
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      };



      getAllListOfDispute= async () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
    
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
      
          this.raiseDisputeAllId = getValidationsMsg.messageId
       
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
           `bx_block_cfdesignersidecontractmanagement/designers_contracts/get_dispute_list?contract_end[contract_id]=${this.state.activeID}`
          )
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }
    
    
      getProposalData= async (contractData:{
        contracts:{
          data:{
          attributes:{
            proposal_id:number
          }
        }
      }
      }) => {
        console.log(contractData,"contractDatacontractData");
        
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
    
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
      
          this.viewProposalId = getValidationsMsg.messageId
       
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
           `/bx_block_joblisting/proposals/${contractData?.contracts?.data?.attributes?.proposal_id}`
          )
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }
      getWorkPostData= async (contractData:{
        contracts:{
          data:{
          attributes:{
            work_opportunity_id:number
          }
        }
      }
      }) => {
        
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
    
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
      
          this.viewWrokPostId = getValidationsMsg.messageId
       
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
           `bx_block_landingpage2/work_opportunities/${contractData?.contracts?.data?.attributes?.work_opportunity_id}`
          )
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }

      putResolveRaiseDispute=(id:number)=>{
        const headers = {
          "Content-Type": "application/json",
          token: this.state.token,
      };
  
      const getValidationsMsg = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );
    
        this.resolveRaiseDisputeId = getValidationsMsg.messageId
     
      getValidationsMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
         `/bx_block_cfdisputemanagement/raise_disputes/resolve_dispute?id=${id}`
        )
      getValidationsMsg.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
      );
      getValidationsMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          'PUT'
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }
    
    getRateTypeLabelProposal = (data: any) => {
        const rate_types = data?.attributes?.rate_type;
        if (rate_types && rate_types.includes("hourly")) {
            return "pr/hr";
        }
        return "";
    }

    showRequestedChangesModal = (responseJson: {data: [{change_request: string}]}) => {
      if(responseJson.data && responseJson.data.length > 0 )
      this.setState({
        openRequestChangeModal: true,
        changeRequestString: responseJson.data[0].change_request
      })
    }

    handleCloseRequestedChangeShowModal = () => {
      this.setState({
        openRequestChangeModal: false,
      })
    }

  getRequestedChangesFromClient = (contractId: string, milestoneId: number, milestone: MileStoneDataType, index: number) => {

    this.setState({
      activeMilestoneData: milestone,
      activeIndex: index + 1
    })
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const getRequestedChangeFromClient = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRequestedChangesApiId = getRequestedChangeFromClient.messageId

    getRequestedChangeFromClient.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_cfdesignersidecontractmanagement/designers_contracts/get_request_changes/?milestone_type=BxBlockDashboard::ContractMilestone&milestone_id=${contractId}&id=${milestoneId}`
    )
    getRequestedChangeFromClient.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getRequestedChangeFromClient.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getRequestedChangeFromClient.id, getRequestedChangeFromClient);
  }
  // Customizable Area End
}