import React, { Component } from 'react';
import {
    Box, Dialog, Divider, Typography, Collapse,
} from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Deliverable, Milestone, WorkSubmissionsResponse } from '../ContractDetailsController.web';
import { styled } from '@material-ui/styles';
import dayjs from "dayjs";
const config = require("../../../../framework/src/config.js")
interface ViweSubmissionProps {
    open: boolean;
    handleClose: () => void;
    mileston: Milestone;
    index: number;
    contractId: string;
    workSubmissionForMilestone: WorkSubmissionsResponse;
    downloadImage: (item: { url: string, file_name: string }) => void;
}

interface StateProp {
    isExpanded: boolean;
    textField: string;
}


const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paperWidthSm": {
        borderRadius: "10px",
        minWidth: "70vw",
        overflowY: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            width: "0px",
        },
    },
    "@media (max-width: 500px)": {
        "& .MuiDialog-paperWidthSm": {
            minWidth: "500px",
            maxWidth: "400px",
        },
    },
});



export default class ViweSubmissionModal extends Component<ViweSubmissionProps, StateProp> {
    constructor(props: ViweSubmissionProps) {
        super(props);
        this.state = {
            isExpanded: false,
            textField: ""
        };
    }

    handleAccordion = () => {
        this.setState((prevState) => ({
            isExpanded: !prevState.isExpanded
        }));
    };

    dividerComponent = (title: string) => {
        return (<TableRow style={{ padding: "0px 15px" }}>
            <TableCell colSpan={3} sx={{ padding: "0px", border: "0px !important" }}>
                <Typography style={{ paddingBottom: "4px", color: "#545454", fontSize: "14px", fontWeight: "400", fontFamily: "inter", fontStyle: "italic" }}>{title}</Typography>
                <Divider style={{ height: "1px", background: "#494949", width: "100%", marginBottom: "15px" }} />
            </TableCell>
        </TableRow>)
    }

    removeExtension = (filename: string) => {
        return filename.substring(0, filename.lastIndexOf('.'))
    }

    renderSubmissionRow = (
        files: {
            file_type: string;
            file_name: string;
            url: string;
        }[],
        submission_links: string[],
        submission_time: string,
        downloadImage: (item: { url: string, file_name: string }) => void, startIndex: number) => {
        return <>
            {
                files.map((file, index) => <TableRow key={index} sx={{ border: "0px !important" }}>
                    <TableCell component="th" scope="row" sx={{ width: "50%", border: "0px !important", padding: "0px 40px 15px 0px" }}>
                        <Box sx={{ display: "flex", alignItems: "center", fontFamily: "inter", color: "#545454", fontSize: "16px", fontWeight: "600" }}>
                            <span>{startIndex + index}.</span>
                            <Box style={{ padding: "15px 20px", background: "#2F2F2F", borderRadius: "8px", margin: "0px 10px", flex: 1 }}>
                                <span>{this.removeExtension(file.file_name)}</span>
                            </Box>
                            <svg
                                data-test-id="download-btn"
                                onClick={() => downloadImage(file)}
                                style={{
                                    cursor: "pointer"
                                }}
                                width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 10L7.64645 10.3536L8 10.7071L8.35355 10.3536L8 10ZM8.5 1C8.5 0.723858 8.27614 0.5 8 0.5C7.72386 0.5 7.5 0.723858 7.5 1L8.5 1ZM2.64645 5.35355L7.64645 10.3536L8.35355 9.64645L3.35355 4.64645L2.64645 5.35355ZM8.35355 10.3536L13.3536 5.35355L12.6464 4.64645L7.64645 9.64645L8.35355 10.3536ZM8.5 10L8.5 1L7.5 1L7.5 10L8.5 10Z" fill="#FFF2E3" />
                                <path d="M1 11.9961L1 12.9961C1 14.1007 1.89543 14.9961 3 14.9961L13 14.9961C14.1046 14.9961 15 14.1007 15 12.9961V11.9961" stroke="#FFF2E3" />
                            </svg>
                        </Box>
                    </TableCell>
                    <TableCell align="left" sx={{ width: "25%", border: "0px !important", color: "#545454", fontSize: "14px", fontWeight: "400", padding: "0px 40px 15px 16px" }}>{file.file_type}</TableCell>
                    <TableCell align="left" sx={{ width: "25%", border: "0px !important", color: "#545454", fontSize: "14px", fontWeight: "400", padding: "0px 40px 15px 16px" }}>{submission_time}</TableCell>
                </TableRow>)
            }
            {submission_links.map((link, index) => (
                <TableRow key={index} sx={{ border: "0px !important" }}>
                    <TableCell component="th" scope="row" sx={{ width: "50%", border: "0px !important", padding: "0px 40px 15px 0px" }}>
                        <Box sx={{ display: "flex", alignItems: "center", fontFamily: "inter", color: "#545454", fontSize: "16px", fontWeight: "600" }}>
                            <span>{startIndex + files.length + index}.</span>
                            <Box style={{ padding: "15px 5px", margin: "0px 10px", flex: 1 }}>
                                <a href={link} target="_blank" rel="noopener noreferrer" style={{ color: "#528ADF", wordBreak: "break-all" }}>
                                    {link}
                                </a>
                            </Box>
                        </Box>
                    </TableCell>
                    <TableCell align="left" sx={{ width: "25%", border: "0px !important", color: "#545454", fontSize: "14px", fontWeight: "400", padding: "0px 40px 15px 16px" }}>Link</TableCell>
                    <TableCell align="left" sx={{ width: "25%", border: "0px !important", color: "#545454", fontSize: "14px", fontWeight: "400", padding: "0px 40px 15px 16px" }}>{submission_time}</TableCell>
                </TableRow>
            ))}
        </>
    }

    render() {
        const { open, handleClose, mileston, index, workSubmissionForMilestone: { data }, downloadImage } = this.props;
        const { isExpanded } = this.state;
        let count = 0
        return (
            <StyledDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} data-test-id="close-modal">
                <Box style={{ border: "1px solid #5F5F5F", background: "#222222", borderRadius: "10px" }}>
                    <Box>
                        <Box style={{ display: "flex", justifyContent: "start", padding: "24px 80px", fontFamily: "inter", alignItems: "center" }}>
                            <svg
                                onClick={handleClose}
                                style={{
                                    cursor: "pointer",
                                }} width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 1L2 9.5L11 18" stroke="#FFF2E3" stroke-width="2" stroke-linecap="round" />
                            </svg>
                            &nbsp;
                            &nbsp;
                            <span style={{ color: "#FFF2E3", fontSize: "20px", fontWeight: 600 }}>
                                View submission
                            </span>
                        </Box>
                        <Box style={{ background: "transparent", borderRadius: "10px", border: "1px solid #494949", margin: "24px 80px" }}>
                            <Box style={{ display: "flex", justifyContent: "space-between", padding: "10px 20px" }}>
                                <span style={{ display: "flex", alignItems: "center", cursor: "pointer" }} data-test-id="open-accordian" onClick={this.handleAccordion}>
                                    <span style={{
                                        height: "30px",
                                        width: "30px",
                                        boxShadow: "-4px 4px 4px 0px #00000040",
                                        borderRadius: "50%",
                                        background: "#FFF2E3",
                                        color: "#2F2F2F",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontFamily: "inter"
                                    }}>
                                        {index}
                                    </span>
                                    <span style={{ color: "#FFF2E3", fontSize: "16px", fontWeight: 600, paddingLeft: "10px", paddingRight: "5px", fontFamily: "inter", }}>
                                        {mileston.name}
                                    </span>
                                    <span>
                                        {isExpanded ? (
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <circle cx="10" cy="10" r="10" transform="rotate(90 10 10)" fill="#333333" />
                                                <path d="M15 11L10 6L5 11" stroke="#FFF2E3" strokeWidth="1.56" strokeLinecap="round" />
                                            </svg>
                                        ) : (
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <circle cx="10" cy="10" r="10" transform="rotate(-90 10 10)" fill="#333333" />
                                                <path d="M5 9L10 14L15 9" stroke="#FFF2E3" strokeWidth="1.56" strokeLinecap="round" />
                                            </svg>
                                        )}
                                    </span>
                                </span>

                                <span style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span style={{ display: "flex", justifyContent: "start", alignItems: "center", padding: "0px 25px" }}>
                                        <svg style={{ paddingRight: "10px" }} width="22" height="24" viewBox="0 0 22 24" fill="none">
                                            <rect x="1" y="4.66797" width="19.9127" height="18.3333" rx="2" stroke="#FFF2E3" />
                                            <path d="M1 8.66797C1 6.78235 1 5.83954 1.58579 5.25376C2.17157 4.66797 3.11438 4.66797 5 4.66797H16.9127C18.7983 4.66797 19.7411 4.66797 20.3269 5.25376C20.9127 5.83954 20.9127 6.78235 20.9127 8.66797V9.55686H1V8.66797Z" fill="#FFF2E3" />
                                        </svg>
                                        <span style={{ color: "#FFF2E3", fontSize: "16px", fontWeight: 600, fontFamily: "inter", }} data-test-id="date-text">
                                            {dayjs(mileston.due_date).format('DD/MM/YY')}
                                        </span>
                                    </span>
                                    <span style={{ color: "#FFF2E3", fontSize: "16px", fontWeight: 600, fontFamily: "inter", }} data-test-id="amount-text">
                                        ₹ {new Intl.NumberFormat('en-US').format(mileston.amount)}
                                    </span>
                                </span>
                            </Box>
                            <Divider style={{ width: "100%", height: "1px", background: "#494949" }} />
                            <Typography style={{
                                fontFamily: "inter",
                                fontSize: "14px",
                                fontWeight: 400,
                                color: "#FFF2E3",
                                padding: "20px 15px"
                            }}>
                                {mileston.description}
                            </Typography>

                            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                                <Box style={{ padding: "15px", paddingTop: "0px" }}>
                                    <Typography style={{ color: "#7D7D7D", fontSize: "12px", fontWeight: 400, fontFamily: "inter" }}>
                                        Deliverables:
                                    </Typography>
                                    {mileston.deliverables.map((item: Deliverable, index: number) => {
                                        if (item.deliverable) {
                                            count += 1
                                            return (
                                                <Typography key={item.id} style={{ paddingTop: "10px", fontSize: "12px", fontWeight: 400, color: "#FFF2E3", fontFamily: "inter", }}>
                                                    {count}. {item.deliverable}
                                                </Typography>
                                            )
                                        }
                                    })}
                                </Box>
                            </Collapse>
                        </Box>
                    </Box>
                    <Divider style={{ height: "1px", width: "100%", marginTop: "20px", background: "#494949" }} />
                    <Box style={{
                        padding: "24px 80px"
                    }}>
                        <TableContainer>
                            <Table aria-label="caption table"
                                style={{
                                    padding: "24px 40px"
                                }}>
                                <TableHead>
                                    <TableRow sx={{ border: "none" }}>
                                        <TableCell sx={{ width: "50%", border: "0px !important", fontSize: "16px", fontFamily: "inter", fontWeight: "600", color: "#FFF2E3", paddingLeft: "0px" }}>File name</TableCell>
                                        <TableCell sx={{ width: "25%", border: "0px !important", fontSize: "16px", fontFamily: "inter", fontWeight: "600", color: "#FFF2E3" }} align="left">File Type</TableCell>
                                        <TableCell sx={{ width: "25%", border: "0px !important", fontSize: "16px", fontFamily: "inter", fontWeight: "600", color: "#FFF2E3" }} align="left">Date uploaded</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.length > 1 && this.dividerComponent("Previous submission")
                                    }
                                    {
                                        data.slice(1).reverse().map((submission, index) => (
                                            <>
                                                {
                                                    this.renderSubmissionRow(submission.attributes.files, submission.attributes.submission_links, submission.attributes.submission_time, downloadImage, 1)
                                                }

                                            </>
                                        ))
                                    }
                                    {
                                        data.length > 1 && this.dividerComponent("After requested edits")
                                    }
                                    {
                                        data.length > 0 &&this.renderSubmissionRow(data[0].attributes.files, data[0].attributes.submission_links, data[0].attributes.submission_time, downloadImage, data.slice(1).reduce((acc, submission) => acc + submission.attributes.files.length + submission.attributes.submission_links.length, 1))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </StyledDialog>
        );
    }
}
