import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../../framework/src/Utilities";
interface AddressAttributes {
  address: string;
  state: string;
  city: string;
  pincode: string;
  mark_default: boolean;
}

interface Address {
  id: string;
  type: string;
  attributes: AddressAttributes;
}
interface Meta {
  message: string;
  address_count: number;
  page: number;
}

interface AddressListResponse {
  data: Address[];
  meta: Meta;
}
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  isLocation:boolean
  updateLocation: () => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  timer: number;
  otpError:string
  canResend: boolean;
  otpNumber:string
  otpPopUp:boolean
  pincodeError:string
  foundYearError:string
  otpToken:string
  addressMsgError:string
  editLocationId:string
  selectedLocation: string,
  isOpenDialog:boolean
  contactDialog:boolean
  companyPopUp:boolean
  dialogType:string
  address:string
  removeModel:boolean
  isDefaultAddress:number
  addressList:AddressListResponse
  city:string
  pincode:string
  locationState:string
  removeLocationId:string
  open:boolean
  action:string
  message:string
  maskedEmail:string
  emailId:string
  emailError:string
  userId:string
  userIdError:string
  userNameError:string
  userName:string
  companyName:string
  foundYear:string
  companyId:string
  companyDetail:string
  companyNameEdit:string
  foundYearEdit:string
  companyDetailEdit:string
  companyDetailError:string
  companyNameError:string
  userDetail:{
    data:{
      type: string,
      attributes: {
        full_name:string
        first_name: string,
        last_name: string | null,
        email: string,
        activated: boolean,
        user_name: string,
        background_color: string,
        city: string,
        country: string,
        profile_image: any,
        role_id: number,
        role_name: string,
      },
    }
  }
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LocationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getLocationApiCallId :string = ""
  removeLocationApiCallId: string = ""
  setDefaultLocationApiCallId:string = ""
  addLocationApiCallId:string=""
  updateUserApiCallId:string = ""
  updateCompanyDetailApiCallId:string=""
  resendOtpApiCallId:string=""
  getLocationDetailCallId:string=""
  timerInterval: NodeJS.Timeout | null = null;
  confirmOtpApiCallId:string=""
  logOutApiCallId:string=""
  getCompanyDetailApiCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      companyId:"",
      companyNameError:"",
      companyName:"",
      foundYear:"",
      companyDetail:"",
      companyNameEdit:"",
      foundYearEdit:"",
      companyDetailError:"",
      companyDetailEdit:"",
      otpPopUp:false,
      otpError:"",
      timer: 30, 
      canResend: false,
      otpNumber:"",
      otpToken:"",
      userIdError:"",
      userNameError:"",
      emailId:"",
      emailError:"",
      userId:"",
      userName:"",
      maskedEmail:"",
      addressMsgError:"",
      editLocationId:"",
      pincodeError:"",
      foundYearError:"",
      selectedLocation: "",
      message:"",
      action:"",
      open:false,
      isOpenDialog: false,
      contactDialog: false,
      companyPopUp:false,
      address: "",
      dialogType: "",
      removeModel: false,
      isDefaultAddress: 0,
      city: "",
      pincode: "",
      locationState: "",
      removeLocationId:"",
      addressList: {
        "data": [{
          "id": "",
          "type": "",
          "attributes": {
            "address": "",
            "state": "",
            "city": "",
            "pincode": "",
            "mark_default": false,
          }
        }],
        "meta": {
          "message": "List of addresses",
          "address_count": 0,
          "page": 1
        }
      },
      userDetail: {
        data :{
          type: "",
          attributes: {
            full_name:"",
            first_name: "",
            last_name: "",
            email: "",
            activated: true,
            user_name: "",
            background_color: "",
            city: "",
            country: "",
            profile_image: "",
            role_id: 0,
            role_name: "",
          },
        }
      }
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId1 = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson1 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    switch (apiRequestCallId1) {
      case this.getLocationApiCallId:
        if (!responseJson1.error) {
          this.setState({ addressList: responseJson1 });
        }
        break;
    
      case this.removeLocationApiCallId:
        if (!responseJson1.error) {
          this.setState({
            removeModel: false,
            open: true,
            action: "success",
            message: "Location removed successfully",
          });
          this.handleGetLocation();
        }
        break;
    
      case this.setDefaultLocationApiCallId:
        if (!responseJson1.error) {
          this.handleGetLocation();
        }
        break;
    
      case this.addLocationApiCallId:
        this.handleAddLocationApi(responseJson1)
        break;
    
      case this.confirmOtpApiCallId:
        this.confirmOtpApi(responseJson1)
        break;
    
      case this.logOutApiCallId:
        removeStorageData("userInfo");
        removeStorageData("saveUserInfo");
        removeStorageData("authToken");
        removeStorageData("userProfile");
        removeStorageData("PublicId");
        removeStorageData("isPublicProfile");
        this.handleNavigation("EmailAccountLoginBlock");
        break;
    
      case this.resendOtpApiCallId:
        if (!responseJson1.errors) {
          this.setState({ otpToken: responseJson1.meta.token });
        }
        break;
    
      case this.getLocationDetailCallId:
        if (!responseJson1.error) {
          this.setState({
            selectedLocation: responseJson1.data.attributes.city,
            address: responseJson1.data.attributes.address,
            pincode: responseJson1.data.attributes.pincode,
            locationState: responseJson1.data.attributes.state,
          });
        }
        break;
    
      case this.updateUserApiCallId:
        this.updateUserApi(responseJson1)
        break;
      
      case this.getCompanyDetailApiCallId:
        this.getCompanyDetailResponse(responseJson1)
        break;

      case this.updateCompanyDetailApiCallId:
        this.updateCompanyDetailResponse(responseJson1)
        break;
    
      default:
        break;
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    let userInfo = await getStorageData("userInfo");
    let storeData = JSON.parse(userInfo);
    if (userInfo) {
      this.setState({
        userDetail:storeData
      })
    }
    this.props.isLocation ? this.handleGetLocation() : this.getCompanyDetail()
    this.setEditData()
  }
  handleAddLocationApi = (responseJson1:any) => {
    if (!responseJson1.errors) {
      this.handleGetLocation();
      this.setState({
        isOpenDialog: false,
        selectedLocation: "",
        address: "",
        pincode: "",
        locationState: "",
        pincodeError: "",
        addressMsgError: "",
        open: true,
        action: "success",
        message: responseJson1.meta.message === "Address updated" ? "Location successfully updated" : "Location successfully added"
      });
    }
  }
  updateUserApi = async (responseJson1:any) =>{
    if (!responseJson1.error) {
      if (responseJson1.meta.is_new_email === false) {
        this.setState({
          contactDialog: false,
          open: true,
          action: "success",
          message: "Contact info updated successfully",
          userDetail: responseJson1
        });
        let existingData = await getStorageData('userInfo'); 
        const parsedData = existingData ? JSON.parse(existingData) : { type: '', attributes: {} };
        parsedData.data = responseJson1.data
        await setStorageData("userInfo", JSON.stringify(parsedData));
        this.props.updateLocation()
      } else {
        this.setState({ otpPopUp: true });
        this.startTimer();
      }
      this.setState({
        otpToken: responseJson1.meta.token,
        contactDialog: false,
      });
    } else {
      this.setState({
        contactDialog: false,
        open: true,
        action: "danger",
        message: responseJson1.error[0],
      });
    }
  }
  confirmOtpApi = (responseJson1:any) =>{
    if (!responseJson1.error) {
      this.setState({ otpPopUp: false });
      this.handleLogOutApiCall();
    } else {
      this.setState({
        otpError: responseJson1.error,
      });
    }
  }
  updateCompanyDetailResponse = (responseJson1:any)=>{
    if (!responseJson1.error) {
      this.getCompanyDetail()
      this.setState({
          companyPopUp: false,
          open: true,
          action: "success",
          message: "Company detail updated successfully",
      })
    } else {
      this.setState({
        companyPopUp: false,
        open: true,
        action: "danger",
        message: "Company detail not updated",
    })
    }
  }
  getCompanyDetailResponse = (responseJson1:any) =>{
    if (!responseJson1.error) {
      this.setState({ 
        companyName:responseJson1.data.attributes.company_name,
        companyDetail:responseJson1.data.attributes.about_company,
        foundYear:responseJson1.data.attributes.found_year,
        companyId:responseJson1.data.id
      });
    }
  }
  handleLogOutApiCall= async ()=> {
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.logOutApiEndPoint
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.validationApiMethodType
    );
    this.logOutApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  startTimer = () => {
    this.setState({ timer: 30, canResend: false });

    this.timerInterval = setInterval(() => {
      this.setState((prevState):any => {
        if (prevState.timer <= 1) {
          clearInterval(this.timerInterval!);
          return { timer: 0, canResend: true };
        }
        return { timer: prevState.timer - 1 };
      });
    }, 1000);
  };
  handleNavigation = async (path:string) => {
    const navigateMessage = new Message(getName(MessageEnum.NavigationMessage));
    navigateMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navigateMessage.addData(getName(MessageEnum.NavigationTargetMessage), path);
    this.send(navigateMessage);
  }
  handleResendClick = () => {
    if (this.state.canResend) {
      this.startTimer();
    }
    this.setState({
      otpError:""
    })
    this.handleResendOtp()
  };
  handleCancelConfirmOtp = () => {
    this.setState({otpPopUp:false,otpError:"",otpNumber:""})
  }
  handleConfirmOtp = async () =>{
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const body = {
      "data": {
            "token": this.state.otpToken,
            "otp_code": +this.state.otpNumber,
        "email": this.state.emailId,
        "user_name": this.state.userId,
        "full_name": this.state.userName
        }
    }
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.validateOtpApiEndPoint
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    this.confirmOtpApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
}
handleResendOtp = async () =>{
  const tokens = await getStorageData("userInfo");   
  let newToken = JSON.parse(tokens)
  const { meta: { token } } = newToken; 
  const headers = {
    "Content-Type": "application/json",
    token: token,
  };
  const body = {
    "user": {
        "email": this.state.emailId
    }
  }
  const getValidationsMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.resendOtpApiEndPoint
  )
  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );
  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  this.resendOtpApiCallId = getValidationsMsg.messageId
  runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
}
  setEditData = () => {
    this.setState({
      maskedEmail : this.maskEmail(this.state.userDetail.data.attributes.email),
      emailId:  this.state.userDetail.data.attributes.email,
      userId: this.state.userDetail.data.attributes.user_name,
      userName: this.state.userDetail.data.attributes.full_name
    })
  }
  maskEmail = (email: string) => {
    const [localPart, domain] = email.split("@");
    const maskedLocal = localPart[0] + "*".repeat(localPart.length - 2)+localPart[localPart.length-1];
    return `${maskedLocal}@${domain}`;
};

  handleChangeAddress = (value: string) => {
    let error=""
    if (value.length < 12 && value.length !==0) {
      error= "Address must be at least 12 characters long"
    } else if (value.length > 100) {
      error= "Address cannot exceed 100 characters"
    } else if(value.length !==0 && !/^[A-Za-z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>\/?]+(?: [A-Za-z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>\/?]+)*$/.test(value)){
      error= "Address should not contain whitespace"
    }
    this.setState({
      addressMsgError:error,
      address: value,})
  };

  handleChangeUserName = (value:string)=>{
    let error = '';
    if (value.length < 2 && value.length !==0) {
      error = 'name must be at least 2 characters';
    } else if (value.length > 50) {
      error = 'name must be at most 50 characters';
    }else if (!/^[A-Za-z]+(?: [A-Za-z]+)*$/.test(value) && value !== ''){
      error = 'name should not contain any special characters, whitespace or numbers';
    }
    this.setState({
      userName: value,
      userNameError: error,
    });
  }
  handleChangeUserId = (value: string) => {
    let error = '';
    if (value.length < 2 && value.length !==0) {
      error = 'Username must be at least 2 characters';
    } else if (value.length > 20) {
      error = 'Username must be at most 20 characters';
    }
    this.setState({
      userId: value,
      userIdError: error,
    });
  };

  handleChangeCompanyName = (value:string)=>{
    let error = "";
    if (value.length < 2 && value.length !==0) {
      error = 'Company name must be at least 2 characters';
    } else if (value.length > 50) {
      error = 'Company name must be at most 50 characters';
    } else if(value.length !==0 && !/^[A-Za-z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>\/?]+(?: [A-Za-z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>\/?]+)*$/.test(value)){
      error = "Company name should not contain whitespace"
    }
    this.setState({
      companyNameEdit: value,
      companyNameError: error,
    });
  }

  handleCompanyDetail = (value:string) =>{
    let error = "";
    if (value.length > 500) {
      error = 'About company must be at most 500 characters';
    } else if(value.length !==0 && !/^[A-Za-z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>\/?]+(?: [A-Za-z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>\/?]+)*$/.test(value)){
      error = "About company should not contain whitespace"
    }
    this.setState({
      companyDetailEdit: value,
      companyDetailError: error,
    });
  }

  handleChangeOtp = (value:any) =>{
    if(value.length < 5){
    this.setState({otpNumber:value})
  }
  }

  handleChangeEmail = (value: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (value && !emailRegex.test(value)) {
      this.setState({ emailError: "Invalid email address" });
    } else {
      this.setState({ emailError: "" });
    }
    this.setState({ emailId: value });
  };

  handleAddLocation = () =>{
    this.setState({isOpenDialog:!this.state.isOpenDialog,selectedLocation:"",
      address:"",
      pincode:"",
      pincodeError:"",
      locationState:"",
      addressMsgError:""})
  }
  handleContactDialog = () =>{
    this.setState({contactDialog:!this.state.contactDialog})
  }

  handleCompanyDialog = () =>{
    this.setState({companyPopUp:!this.state.companyPopUp,
      foundYearError:"",
      companyDetailError:"",
      companyNameError:"",
    })
  }

  handleContactDialogOpen = () =>{
    this.setState({contactDialog:!this.state.contactDialog})
    this.setEditData()
  }

  handleCompanyDialogOpen = () =>{
    this.setState({
      companyPopUp:!this.state.companyPopUp,
      companyNameEdit:this.state.companyName,
      foundYearEdit:this.state.foundYear,
      companyDetailEdit:this.state.companyDetail,
    })
  }

  isButtonDisabled = (): boolean => {
    const { address, selectedLocation, pincode, pincodeError, addressMsgError } = this.state;
    return (
      address !== "" &&
      selectedLocation !== "" &&
      pincode !== "" &&
      pincodeError === "" &&
      addressMsgError === ""? true : false
    );
    
  };
  saveButtonDisabled = (): boolean => {
    const { userId,userName,emailId,emailError,userIdError,userNameError } = this.state;
    return (
      userId !== "" &&
      userName !== "" &&
      emailId !== "" &&
      emailError === "" &&
      userIdError === "" &&
      userNameError === ""? true : false
    );
    
  };
  companySaveButtonDisabled = (): boolean => {
    const { foundYearEdit,companyNameEdit,foundYearError,companyNameError } = this.state;
    return (
      companyNameEdit !== "" &&
      foundYearEdit !== "" &&
      foundYearError === "" &&
      companyNameError === ""? true : false
    );
    
  };
  handleLocation = async (id:string) =>{
      const tokens = await getStorageData("userInfo");   
      let newToken = JSON.parse(tokens)
      const { meta: { token } } = newToken; 
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      const body = {
        "address": {
          "address": this.state.address,
          "city": this.state.selectedLocation,
          "state": this.state.locationState,
          "pincode": this.state.pincode
      }
      }
      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        id ? `${configJSON.setDefaultLocationApiEndPoint}${id}`:"/bx_block_settings/addresses"
      )
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        id ? configJSON.putApiMethod :configJSON.exampleAPiMethod
      );
      this.addLocationApiCallId = getValidationsMsg.messageId
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleSaveInfo = async () =>{
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const body = {
      "user": {
        "user_name": this.state.userId,
        "full_name": this.state.userName,
        "email": this.state.emailId
    }
    }
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateUserApiEndPoint
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    this.updateUserApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
}
handleSaveCompanyDetail = async () =>{
  const tokens = await getStorageData("userInfo");   
  let newToken = JSON.parse(tokens)
  const { meta: { token } } = newToken; 
  const headers = {
    "Content-Type": "application/json",
    token: token,
  };
  const body = {
    "company": {
        "company_name": this.state.companyNameEdit,
        "about_company": this.state.companyDetailEdit,
        "found_year": this.state.foundYearEdit
    }
  }
  const getValidationsMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.updateCompanyDetailApiEndPoint}${this.state.companyId}/update_company_details`
  )
  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );
  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.putApiMethod
  );
  this.updateCompanyDetailApiCallId = getValidationsMsg.messageId
  runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
}
  handleAddLocationPopUp = (type:string) =>{
    this.setState({isOpenDialog:!this.state.isOpenDialog,dialogType:type,
      selectedLocation:"",
      address:"",
      pincode:"",
      pincodeError:"",
      locationState:"",
      addressMsgError:"",
    })
  }
  handleEditDialog = (id:string)=>{
    this.getLocationById(id)
    this.setState({isOpenDialog:!this.state.isOpenDialog,dialogType:"edit",editLocationId:id})
    
  }
  getLocationById = async (id:string) =>{
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.setDefaultLocationApiEndPoint}${id}`
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.validationApiMethodType
    );
    this.getLocationDetailCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getCompanyDetail = async () =>{
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCompanyDetailApiEndPoint}`
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.validationApiMethodType
    );
    this.getCompanyDetailApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleRemoveModel = (id:string) =>{
    this.setState({removeModel:!this.state.removeModel,removeLocationId:id})
  }
  handleClose = () => {
    this.setState({ open: false });
  }
  handleRemoveAddress = async () =>{
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.setDefaultLocationApiEndPoint}${this.state.removeLocationId}`
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.deleteApiMethod
    );
    this.removeLocationApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleRemoveModelClose = () =>{
    this.setState({removeModel:!this.state.removeModel})
  }

  handleGetLocation= async ()=> {
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLocationApiEndPoint
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.validationApiMethodType
    );
    this.getLocationApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleSetDefaultLocation= async(id:string) =>{
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.setDefaultLocationApiEndPoint}${id}/update_mark_default`
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.putApiMethod
    );
    this.setDefaultLocationApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^[a-zA-Z\s]*$/.test(value) || value === "") {
      event.target.value = value;
    } else {
      event.preventDefault();
      event.target.value = value.replace(/[^a-zA-Z\s]/g, '');
    }
  };
  handleChangeLocationText = (data: string) => {
    this.setState({ selectedLocation: data.trim()})
  };
  handleChangeLocation = (data: string) => {
    const [selectedLocation, locationState] = data.split(',');
    this.setState({ selectedLocation, locationState });
  };
  handlePincode = (data:string) =>{
    if (data.length !== 6 && data.length !==0) {
      this.setState({pincodeError:"Pincode must be exactly 6 digits."})
    }else{
      this.setState({pincodeError:""})
    }
    this.setState({pincode:data})
  }
  handleFoundYear = (data:string)=>{
    const currentYear = new Date().getFullYear();
    if (parseInt(data, 10) > currentYear) {
      this.setState({ foundYearError: `Year cannot be greater than ${currentYear}.` });
    }else if(data !== "" && parseInt(data, 10) < 1900){
      this.setState({ foundYearError: `Year cannot be lower than 1900.` });
    }else{
      this.setState({foundYearError:""})
    }
    this.setState({foundYearEdit:data})
  }
  // Customizable Area End
}
