// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const config = require("../../../framework/src/config");
import dayjs from "dayjs";
import { getToken } from "../../../components/src/Base64Conversion";
import { setStorageData, getStorageData, removeStorageData } from "../../../framework/src/Utilities";
export interface Props {
    navigation: any;
    id: string;
    tabNo:number
}

interface Deliverable {
    id: string;
    type: string;
    attributes: {
      deliverable: string;
    };
  }
  
 export  interface Milestone {
    id: string;
    type: string;
    attributes: {
      id: number;
      contract_id: number;
      name: string;
      description: string;
      overall_budget:number;
      amount: number;
      due_date: string;
      client_approval: boolean;
      deliverables: {
        data: Deliverable[];
      };
      edits: any;
    };
  }
  
  interface ContractStatus {
    id: number;
    receiver_id: number;
    status: string;
    contract_id: number;
    created_at: string;
    updated_at: string;
  }
  
  interface Milestones {
    data: Milestone[];
  }
  
  interface Contract {
   attributes:{ id: number;
    proposal_id: number;
    start_date:string;
    account_id: number;
    terms_and_condition_accept: boolean;
    project_timeline_type:string;
    decline_contract: boolean;
    project_timeline: number;
    overall_budget: number;
    contract_overall_budget:number;
    created_at: string;
    updated_at: string;
    contract_status: ContractStatus;
    milestones: Milestones;
    contract_sent_on_date: string;
    take_action_until: string;
    edit_request_n_date: string;
    awaing_action_till: string;}
  }
  

interface S {
    loading: boolean;
    workOppertunityData: any;
    openOppertunityProposal: boolean;
    dataListForMap: any;
    token: string
    sharedProposalPopUp:boolean
    proposalData: any;
    mileStoneData:Contract|null;
    offerReceivedId: number;
    accordianPop: boolean;
    acceptOfferPopUp:boolean;
    declineOfferPopUp:boolean;
    termsAndConditionCheck:boolean;
    dataForPopUp:any;
    workOppertunityDataId:number;
    sendProposalId:number
    expanded:any [];
    editRequest:boolean;
    discardPopUp:boolean;
    editMileStone:boolean;
    specificMileStoneData:any;
    afterEditMilestone:any;
    editMileStoneId:number;
    currentIndex:number|null;
    openCalender:boolean;
    editAfterPopUpData:any;
    accordianMilestoneData:any
    contractId:number;
    open: boolean,
    message: string;
    deadline:string;
    errorMessage:string;
    action: string,
    tab:number;
    projectTimelineType:string;
    projectTimelineNumber:number;
    overallBudget :number;
    overallBudgetArr:number[];
    isWordLimitDescription:boolean
    isWordLimitMilestone:boolean
    activatedStatus:string;
    status:string;
    startDate:string;
    endDate:string;
    request_sent_date	:string;
}


interface SS {
    id: string;
}

export const configJSON = require("./config");

export default class OfferReceivedController extends BlockComponent<Props, S, SS> {
    offerReceivedApiID: string = "";
    offerReceivedDetailsAPIid: string = "";
    sendProposalDetailsId:string="";
    workOpportunitiesId:string="";
    acceptOfferId:string="";
    declineOfferId:string="";
    editMileStoneId:string="";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);


        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionRequestMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
          accordianMilestoneData:[],
          request_sent_date	:"",
          status:"",
          startDate:"",
          endDate:"",
          overallBudgetArr:[0],
          overallBudget:0,
          deadline:"",
          projectTimelineType:"",
          projectTimelineNumber:0,
          contractId:0,
          errorMessage: "",
          open: false,
          message: "",
          action: "",
            loading: false,
            accordianPop: false,
            workOppertunityData: [],
            openOppertunityProposal: false,
            dataListForMap: [],
            token: "",
            sharedProposalPopUp:false,
            proposalData: [],
            offerReceivedId: 0,
            acceptOfferPopUp:false,
            dataForPopUp:[],
            openCalender:false,
            declineOfferPopUp:false,
            termsAndConditionCheck:false,
            mileStoneData:null,
            workOppertunityDataId:0,
            expanded: [], 
            sendProposalId:0,
            discardPopUp:false,
            editRequest:false,
            editMileStone:false,
            specificMileStoneData:[],
            afterEditMilestone:[],
            editMileStoneId:0,
            currentIndex:null,
            editAfterPopUpData:[],
            tab:0,
            isWordLimitDescription:false,
            isWordLimitMilestone:false,
            activatedStatus:""

        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }

    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
          if(apiRequestCallId==this.editMileStoneId){
              this.handleTab(1);
            }  
            if(apiRequestCallId==this.declineOfferId){
              this.setState(() => ({
                ...this.state,
                open: true,
                declineOfferPopUp:false,
                action: "success",
                message: responseJson?.message,
                title: ""
              }), () => {
                setTimeout(() => {
              this.getDataList(this.state.tab)

                }, 2000);
              });
            }
            if(apiRequestCallId==this.acceptOfferId){
              this.setState(() => ({
                ...this.state,
                open: true,
                acceptOfferPopUp:false,
                action: "success",
                message: responseJson?.message,
                termsAndConditionCheck:false,
                title: ""
              }), () => {
                setTimeout(() => {
                  this.getDataList(this.state.tab)


                }, 2000);
              });
            }
            if(apiRequestCallId==this.workOpportunitiesId){
              
                this.setState({ workOppertunityData: responseJson?.data })
            }
            if(apiRequestCallId==this.sendProposalDetailsId){
                this.setState({ proposalData: responseJson?.data })
            }
            if (apiRequestCallId == this.offerReceivedDetailsAPIid) {
              this.setState({ specificMileStoneData: [] })
              if(this.props.tabNo==0){

                if(  this.state.tab==0){
                  let offerProposalId = responseJson.data.attributes.proposal_id;
                  this.setState({
                    sendProposalId: offerProposalId
                  })
                  this.getSendProposalDetailsApi(offerProposalId);
                  this.setState({ mileStoneData: responseJson?.data ,contractId:responseJson?.data?.id,projectTimelineType:responseJson?.data?.attributes?.project_timeline_type,projectTimelineNumber:responseJson?.data?.attributes?.project_timeline})
                  let milestones = responseJson?.data?.attributes?.milestones?.data || [];
      const expandedState = milestones.map((milestone:Milestone) => ({
        id: milestone?.attributes?.id,
        expanded: false ,
        isEditAnyThing:false
      }));
      let overallProject=0;
      const overallBudgetArr= milestones.map((milestone:Milestone) => {
    
        overallProject+=Number(milestone?.attributes?.amount);
       return Number(milestone?.attributes?.amount)
    
      });
      const accordianMilestoneData = new Array(milestones.length).fill(false);
        this.setState({ expanded: expandedState ,
          startDate:milestones[0]?.attributes.due_date,
          endDate:milestones[milestones.length - 1]?.attributes?.due_date,
          specificMileStoneData:responseJson?.data?.attributes?.milestones?.data ,
          afterEditMilestone:responseJson?.data?.attributes?.milestones?.data ,
          accordianMilestoneData,
          overallBudgetArr:overallBudgetArr,
          overallBudget:overallProject
        })
    this.handleCalculation(milestones[0]?.attributes.due_date,milestones[milestones.length - 1]?.attributes?.due_date,  this.state.projectTimelineType)

                }
                else{
                  if(this.state.tab==1){
                    let offerProposalId = responseJson.contract.data.attributes.proposal_id;
                    this.setState({
                      sendProposalId: offerProposalId
                    })
                    this.getSendProposalDetailsApi(offerProposalId);
                    this.setState({status:responseJson?.status,request_sent_date	:responseJson?.request_sent_date	})
                    const transformedData = responseJson?.contract?.data?.attributes?.milestones?.data.map((milestone:any) => {
                      if (milestone.attributes?.edits) {
                      const edits = milestone.attributes.edits.data;
	return {
			"id": edits.id,
			"type": edits.type,
			"attributes": {
				"id": edits.attributes.id,
				"name": edits.attributes.name,
				"description": edits.attributes.description,
				"amount": edits.attributes.amount,
				"due_date": edits.attributes.due_date,
				"contract_milestone_id": edits.attributes.contract_milestone_id,
				"deliverables": edits.attributes.edit_deliverables 
			}
		};
                    }
                    else{
                      return {};
                    }
                  })
                  const expandedState = responseJson?.contract?.data?.attributes?.milestones?.data.map((milestone:Milestone) => ({
                    id: milestone?.attributes?.id,
                    expanded: true ,
                  
                    isEditAnyThing:milestone.attributes?.edits?true:false,
                  }));
        const accordianMilestoneData = new Array(transformedData.length).fill(false);

            this.setState({afterEditMilestone:transformedData,expanded:expandedState, mileStoneData: responseJson?.contract?.data ,contractId:responseJson?.data?.id,
              deadline:responseJson?.deadline,
              accordianMilestoneData,
              specificMileStoneData:responseJson?.contract?.data?.attributes?.milestones?.data ,


            })
                  }
                  else{

                    let offerProposalId = responseJson.contract.data.attributes.proposal_id;
                    this.setState({
                      sendProposalId: offerProposalId
                    })
                    this.getSendProposalDetailsApi(offerProposalId);
                    this.setState({ mileStoneData: responseJson?.contract?.data ,contractId:responseJson?.data?.id,})
                    let milestones = responseJson?.contract?.data?.attributes?.milestones?.data || [];
        const expandedState = milestones.map((milestone:Milestone) => ({
          id: milestone?.attributes?.id,
          expanded: false ,
        
          isEditAnyThing:false,
        }));
      
        
        const accordianMilestoneData = new Array(milestones.length).fill(false);
                    this.setState({ expanded: expandedState ,
                      specificMileStoneData:responseJson?.contract?.data?.attributes?.milestones?.data ,
                      afterEditMilestone:responseJson?.contract?.data?.attributes?.milestones?.data ,
                      deadline:responseJson?.deadline,
                      accordianMilestoneData
                  })
                  }
                  
                }
              }
              else{
    
                let offerProposalId = responseJson.contract.data.attributes.proposal_id;
                this.setState({
                  sendProposalId: offerProposalId
                })
                this.getSendProposalDetailsApi(offerProposalId);
                  this.setState({ mileStoneData: responseJson?.contract?.data ,contractId:responseJson?.contract?.data?.id,activatedStatus:responseJson?.day_count})
                  let milestones = responseJson?.contract?.data?.attributes?.milestones?.data  || [];
      const expandedState = milestones.map((milestone:Milestone) => ({
        id: milestone?.attributes?.id,
        expanded: false ,
        isEditAnyThing:false
      }));
      let overallProject=0;
      const overallBudgetArr= milestones.map((milestone:Milestone) => {
    
        overallProject+=Number(milestone?.attributes?.amount);
       return Number(milestone?.attributes?.amount)
    
      });
      const accordianMilestoneData = new Array(milestones.length).fill(false);
        this.setState({ expanded: expandedState ,
          overallBudgetArr:overallBudgetArr,
          overallBudget:overallProject,
          specificMileStoneData:responseJson?.contract?.data?.attributes?.milestones?.data ,
          afterEditMilestone:responseJson?.contract?.data?.attributes?.milestones?.data ,
          deadline:responseJson?.deadline,
          accordianMilestoneData
        })
              }
                
            }
            if (apiRequestCallId == this.offerReceivedApiID) {
                this.setState({ loading: false })
                if (!responseJson.error) {
                    this.setState({ dataListForMap: responseJson?.data })
                    if (responseJson && responseJson?.data[0]?.id) {
                        let offerSpecificId = responseJson?.data[0]?.id;
                        let offerProposalId = responseJson.data[0].attributes.contract.data[0].attributes.proposal_id;
                        this.setState({
                            offerReceivedId: offerSpecificId, workOppertunityDataId: offerSpecificId,
                            sendProposalId: offerProposalId
                        })
                        this.setState({ dataForPopUp: responseJson.data[0] })
                        this.getDetailsApi(offerSpecificId);
                        this.getWorkOpportunityDetailsApi(offerSpecificId);
                    }
                }
                else{
                  this.setState({dataListForMap:[]})
                }

            }
        }

    }
    handleTab=(id:number)=>{
this.setState({tab:id})
this.getDataList(id);
   this.handlediscardPopUpMain()
    }
    handleClose=()=>{
      this.setState({open:false})
    }
    isFormValid = () => {
        const { afterEditMilestone } = this.state;
        const specificMilestone = afterEditMilestone.find(
          (milestone:Milestone) => milestone?.attributes?.id === this.state.editMileStoneId
        );
    
        if (!specificMilestone) return false;
    
        const { name, description, amount, deliverables, due_date } = specificMilestone.attributes;
        if (this.state.isWordLimitDescription||this.state.isWordLimitMilestone||
          !name || !description || !amount ||amount<=0 || !due_date ||
          deliverables.data.some((item: {
            attributes: {
              deliverable: string
            }
          }) => !item.attributes.deliverable)
        ) {
          return false;
        }   
    
        return true;
      };
openMileStonePopUp=(id:number,index:number)=>{
    const data =this.state.afterEditMilestone.find((item:Milestone)=>{
        return item.attributes.id==id;
    })
    this.setState({editMileStone:true,editMileStoneId:id,editAfterPopUpData:data,currentIndex:index})
}

sendEditRequestHandle = () => {
    return this.state.expanded.every((item) => item.isEditAnyThing === false);
  }
  
  closeMileStonePoPuP = (id: number) => {
    this.setState({
      isWordLimitDescription: false,
      isWordLimitMilestone: false,
      openCalender: false
    });
    //@ts-ignore
    this.setState((prevState: any) => {
        const { specificMileStoneData, expanded, afterEditMilestone, editAfterPopUpData } = this.state;
        const afterEditMilestoneCurrent = afterEditMilestone.find((item: Milestone) => item.attributes.id === id);
        const milestoneInSpecific = specificMileStoneData.find((item: Milestone) => item.attributes.id === id);

        const hasChanged = JSON.stringify(afterEditMilestoneCurrent) !== JSON.stringify(editAfterPopUpData);
        const matchesSpecific = JSON.stringify(editAfterPopUpData) === JSON.stringify(milestoneInSpecific);

        if (hasChanged) {
            if (matchesSpecific) {
                // Update afterEditMilestone with data from specificMileStoneData if it matches
                const updatedAfterEditMilestone = afterEditMilestone.map((item: Milestone) =>
                    item.attributes.id === id ? milestoneInSpecific : item
                );

                const updatedExpanded = expanded.map((milestone: any) =>
                    milestone.id === id ? { ...milestone, isEditAnyThing: false } : milestone
                );

                return {
                    expanded: updatedExpanded,
                    afterEditMilestone: updatedAfterEditMilestone,
                    editMileStone: false
                };
            } else {
                // Update afterEditMilestone with editAfterPopUpData if there's a change but no match
                const updatedAfterEditMilestone = afterEditMilestone.map((item: Milestone) =>
                    item.attributes.id === id ? editAfterPopUpData : item
                );

                return {
                    afterEditMilestone: updatedAfterEditMilestone,
                    editMileStone: false
                };
            }
        } else if (!matchesSpecific) {
            // Mark milestone as edited if there's no change in afterEditMilestone but no match with specificMileStoneData
            const updatedExpanded = expanded.map((milestone: any) =>
                milestone.id === id ? { ...milestone, isEditAnyThing: true } : milestone
            );

            return {
                expanded: updatedExpanded,
                editMileStone: false
            };
        } else {
            // Default case: reset afterEditMilestone to specificMileStoneData if there's no change and it matches
            const updatedAfterEditMilestone = afterEditMilestone.map((item: Milestone) =>
                item.attributes.id === id ? milestoneInSpecific : item
            );

            const updatedExpanded = expanded.map((milestone: any) =>
                milestone.id === id ? { ...milestone, isEditAnyThing: false } : milestone
            );

            return {
                expanded: updatedExpanded,
                afterEditMilestone: updatedAfterEditMilestone,
                editMileStone: false
            };
        }
    });
};

handleAmount=(milestone:Milestone)=>{
    let amount=milestone?.attributes?.amount;
    let updatedArray = [...this.state.overallBudgetArr];

if(this.state.currentIndex!==null){
  updatedArray[this.state.currentIndex] = Number(amount);
  let overallProject=0;
  const overallBudgetArr= updatedArray.map((item:number) => {
    overallProject+=Number(item);
  });
  this.setState({overallBudgetArr:updatedArray,overallBudget:overallProject})
}
}
editUpdate=(id:number)=>{
  const { afterEditMilestone, specificMileStoneData } = this.state;

        const milestoneInAfterEdit = afterEditMilestone.find((item:Milestone) => {return item.attributes.id === id});
        const milestoneInSpecific = specificMileStoneData.find((item:Milestone) => {return  item.attributes.id === id});
       this. handleAmount(milestoneInAfterEdit)
this.handleCalculation(this.state.mileStoneData?.attributes?.start_date||'',this.state.afterEditMilestone[this.state.afterEditMilestone.length-1]?.attributes?.due_date,this.state.projectTimelineType)
  
if (JSON.stringify(milestoneInAfterEdit) !== JSON.stringify(milestoneInSpecific)) {
    const updatedMilestones = this.state.expanded.map((milestone: any) => {
      if (milestone.id === id) {
        return {
          ...milestone,
                        isEditAnyThing: true
        };
      }
      return milestone;
    });
    this.setState({
      expanded: updatedMilestones,
                editMileStone: false
    });
        } 
        else{
          const updatedMilestones = this.state.expanded.map((milestone: any) => {
            if (milestone.id === id) {
                return {
                    ...milestone,
                    isEditAnyThing: false
                };
            }
            return milestone;
        });

        this.setState({
            expanded: updatedMilestones,
            editMileStone: false
        });
        }
}
showNameError = () =>{
  return this.state.isWordLimitMilestone ? "Reached Limit of 50 char" : ""
}
showDescriptionError = () =>{
  return this.state.isWordLimitDescription ? "Reached Limit of 500 char" : ""
}
showDeliverableError = (select:boolean) =>{
  return select? "Reached Limit of 150 char" : ""
}

handleInputChange = (e: any, field: string, id: number) => {
  const value = e.target.value;

  let updatedValue = value;

  if (field === 'amount') {
    const numericValue = parseFloat(value);

    if (numericValue <= 9999999) {
      updatedValue = numericValue; 
    } else {
      updatedValue = 0; 
    }
  }
  if(field=='description'){
    const inputValue = updatedValue.trim(" ");
                             
                               
    if (inputValue.length >500) {
          this.setState({  isWordLimitDescription: true  })
      }
     else{
        this.setState({ isWordLimitDescription: false })
      }
  }
if(field=='name'){
  const inputValue = updatedValue.trim(" ");
  if (inputValue.length >50) {
        this.setState({  isWordLimitMilestone: true  })
    }
   else{
      this.setState({ isWordLimitMilestone: false })
    }
  
}
  const updatedData = this.state.afterEditMilestone.map((milestone: Milestone) => {
    if (milestone.attributes.id === id) {
      return {
        ...milestone,
        attributes: {
          ...milestone.attributes,
          [field]: updatedValue,
        },
      };
    }
    return milestone;
  });

  this.setState({ afterEditMilestone: updatedData });

};

formatDateShort = (dateString: any | undefined) => {
  if (dateString) {
    const date = dayjs.isDayjs(dateString) ? dateString.toDate() : new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: '2-digit' };
    return date.toLocaleDateString('en-GB', options); 
  }
}

 formatDateTime=(input: any) =>{
  if(input){

    const [datePart, timePart, period] = input.split(" ");
    const [year, month, day] = datePart.split("/").map(Number);
    let [hours, minutes] = timePart.split(":").map(Number);
  
    if (period.toLowerCase() === "pm" && hours !== 12) {
      hours += 12;
    } else if (period.toLowerCase() === "am" && hours === 12) {
      hours = 0;
    }
  
    const date = new Date(year, month - 1, day, hours, minutes);
  
    const formattedDay = date.getDate().toString().padStart(2, '0');
    const formattedMonth = (date.getMonth() + 1).toString().padStart(2, '0');
    const formattedYear = date.getFullYear().toString().slice(-2);
  
    let formattedHours = date.getHours() % 12 || 12; 
    const formattedMinutes = date.getMinutes().toString().padStart(2, '0');
    const formattedPeriod = date.getHours() >= 12 ? 'p.m ' : 'a.m ';
  
    return `${formattedDay}/${formattedMonth}/${formattedYear}, ${formattedHours}:${formattedMinutes} ${formattedPeriod} IST`;
  }
}
  handleDateChange = (date:any, id:number) => {
    const updatedData = this.state.afterEditMilestone.map((milestone:Milestone) => {
      if (milestone.attributes.id === id) {
        return {
          ...milestone,
          attributes: {
            ...milestone.attributes,
            due_date:  date.format("DD/MM/YYYY"),
          },
        };
      }
      return milestone;
    });
  
    this.setState({ afterEditMilestone: updatedData });
  };
  handleDeleteDeliverable = (index:number, id:number) => {
    const updatedData = this.state.afterEditMilestone.map((milestone:Milestone) => {
      if (milestone.attributes.id === id) {
        const updatedDeliverables = milestone.attributes.deliverables.data.filter(
          (item, i) => i !== index
        );
        return {
          ...milestone,
          attributes: {
            ...milestone.attributes,
            deliverables: {
              data: updatedDeliverables,
            },
          },
        };
      }
      return milestone;
    });

    this.setState({ afterEditMilestone: updatedData });
  };

  handleAddDeliverable = (id:number) => {
    const updatedData = this.state.afterEditMilestone.map((milestone:Milestone) => {
      if (milestone.attributes.id === id) {
        return {
          ...milestone,
          attributes: {
            ...milestone.attributes,
            deliverables: {data:[
              ...milestone.attributes.deliverables.data,
              { attributes: { deliverable: '' } },
            ]},
          },
        };
      }
      return milestone;
    });

    this.setState({ afterEditMilestone: updatedData });
  };

  handleDeliverableChange = (index: number, e: any, id: number) => {
    const { value } = e.target;
  
    const updatedData = this.state.afterEditMilestone.map((milestone: Milestone) => {
      if (milestone.attributes.id === id) {
        const updatedDeliverables = milestone.attributes.deliverables.data.map((deliverable, i) =>
          i === index
            ? {
                ...deliverable,
                attributes: {
                  ...deliverable.attributes,
                  deliverable: value,
                  deliverableError: value.trim().length > 150,
                },
              }
            : deliverable
        );
  
        return {
          ...milestone,
          attributes: {
            ...milestone.attributes,
            deliverables: {
              data: updatedDeliverables,
            },
          },
        };
      }
      return milestone;
    });
  
    this.setState({ afterEditMilestone: updatedData });
  };
  
  
handlePropagation = (event: any) => {
    event.stopPropagation();
  }
  openCalender = (event: any) => {
    this.setState({
      openCalender: true
    });
    this.handlePropagation(event);
  }
  closeCalender=()=>{
    this.setState({
      openCalender: false
    });
    }
handleeditRequest=()=>{
  const accordianMilestoneData = new Array(this.state.specificMileStoneData.length).fill(true);

    const milestones = this.state.mileStoneData?.attributes?.milestones?.data || [];
    const expandedState = milestones.map((milestone:Milestone) => ({
      id: milestone?.attributes?.id,
      expanded: false ,
      isEditAnyThing:false
    }));
    this.setState({ expanded: expandedState ,accordianMilestoneData});
    this.setState({editRequest:true})
}
     handleChange = () => {
        this.setState({termsAndConditionCheck:!this.state.termsAndConditionCheck})
      };

    getTokenData = async () => {
        const token = await getToken();
        this.setState({ token: token })
    };

    async componentDidMount() {
        await this.getTokenData();
        this.getDataList(this.state.tab);
        this.setFromLocalStorage()
    }

    async componentWillUnmount() {
      removeStorageData("designerProposalTab")
    }

  setFromLocalStorage = async () => {
    const designerProposalTab = await getStorageData("designerProposalTab")
    const final = JSON.parse(designerProposalTab) as { designerProposalTab: string }
    if (final.designerProposalTab === "new") {
      this.setState({ tab: 0 })
      this.getDataList(0);
   this.handlediscardPopUpMain()
    }
    else if (final.designerProposalTab === "ongoing") {
      this.setState({ tab: 1 })
      this.getDataList(1);
   this.handlediscardPopUpMain()
    }
  }

acceptPopClose=()=>{
    this.setState({acceptOfferPopUp:false});

}
handlediscardPopUp=()=>{
    this.setState({discardPopUp:true})
}
handlediscardPopUpMain=()=>{
  const accordianMilestoneData = new Array(this.state?.specificMileStoneData?.length).fill(false);

    const expandedState = this.state.expanded.map((milestone:Milestone) => ({
        id: milestone?.attributes?.id,
        expanded: false ,
        isEditAnyThing:false
      }));
    this.setState({discardPopUp:false,editRequest:false,afterEditMilestone:this.state.specificMileStoneData,expanded:expandedState,accordianMilestoneData})
}
handlediscardPopUpClose=()=>{
    this.setState({discardPopUp:false})
}

handleAccordionToggle = (index: number) => {
  this.setState((prevState: any) => {
      const newExpanded = prevState.accordianMilestoneData.map((item: boolean, idx: number) =>
          idx === index ? !item : item
      );

      return { accordianMilestoneData: newExpanded };
  });
};

acceptPopOpen=()=>{
    this.setState({acceptOfferPopUp:true});
  
}
declinePopClose=()=>{
    this.setState({declineOfferPopUp:false})
}
declinePopOpen=()=>{
    this.setState({declineOfferPopUp:true})
  
}
handleEndPointForListDetails=(id:number)=>{
  if(this.props.tabNo==0){

    if(this.state.tab==0){
      return `/bx_block_joblisting/proposals/${id}/contract_details`;
    }
    else if(this.state.tab==1){
      return `bx_block_landingpage2/work_opportunities/ongoing_work_opportunity_contract_detail?id=${id}`
    }
    else if(this.state.tab==2){
      return `/bx_block_cfproposalmanagement/contract_offers/decline_work_opportunity_contract_detail?id=${id}`;
    }
  }
  else{
    return `/bx_block_cfproposalmanagement/contract_offers/activate_contract_detail?id=${id}`
  }
}

handleEndPointForList=(id:number)=>{
  if(this.props.tabNo==0){

    if(id==0){
      return 'bx_block_joblisting/proposals/work_opportunities_with_contracts';
    }
    else if(id==1){
      return 'bx_block_landingpage2/work_opportunities/ongoing_work_opportunity_contract_list'
    }
    else if(id==2){
      return 'bx_block_cfproposalmanagement/contract_offers/decline_work_opportunity_contract_list'
    }
  }
  else{
    return '/bx_block_cfproposalmanagement/contract_offers/activate_contract_list'
  }
}
    getDataList = async (id:number) => {
        let endPoint=this.handleEndPointForList(id);
        this.setState({ loading: true })
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.offerReceivedApiID = getValidationsMsg.messageId
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint)
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
    getDetailsApi = async (id: number) => {
        let endPoint=
       this.handleEndPointForListDetails(id);
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.offerReceivedDetailsAPIid = getValidationsMsg.messageId
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint)
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
    getSendProposalDetailsApi = async (id: number) => {
        let endPoint=
        `/bx_block_joblisting/proposals/${id}`;
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.sendProposalDetailsId = getValidationsMsg.messageId
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint)
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
    getWorkOpportunityDetailsApi = async (id: number) => {
        let endPoint=
        `/bx_block_landingpage2/work_opportunities/${id}`;
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const getWorkOpportunityDetailsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.workOpportunitiesId = getWorkOpportunityDetailsMsg.messageId
        getWorkOpportunityDetailsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint)
        getWorkOpportunityDetailsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getWorkOpportunityDetailsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getWorkOpportunityDetailsMsg.id, getWorkOpportunityDetailsMsg);
    }

    handleNavigateFind =(data:any) =>{
        let localdata = {
          account_id: data.account_id,
          user_role : "client"
        }
            setStorageData("PublicId",JSON.stringify(localdata));
          
        const messageNavigate= new Message(getName(MessageEnum.NavigationMessage));
    messageNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "PublicUserProfile");
    messageNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messageNavigate);
      }
    handleAbutWorkOpen = () => {
        this.setState({ accordianPop: !this.state.accordianPop })

    }
    sharedProposalPopUpFn = () => {
        this.setState({ sharedProposalPopUp: !this.state.sharedProposalPopUp })

    }
    getRateTypeLabelProposal = (data: any) => {
        const rate_types = data?.attributes?.project_rate_type;
        if (rate_types && rate_types.includes("hourly")) {
            return "pr/hr";
        }
        return "";
    }
    putAcceptOffer = async () => {
      const header = {
        "Content-Type": "application/json",
        token: this.state.token,
      };
      const body = {
        "data": {
            "attributes": {
                "work_opportunity_id": this.state.workOppertunityDataId,
                "contract_id": this.state.contractId
            }
        }
    }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.acceptOfferId = requestMessage.messageId
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_dashboard/contracts/accept_contract`
      );
  
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
  
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    };
  
    putDeclineOffer = async () => {
      const header = {
        "Content-Type": "application/json",
        token: this.state.token,
      };
      const body = {
        "data": {
            "attributes": {
                "work_opportunity_id": this.state.workOppertunityDataId,
                "contract_id": this.state.contractId

            }
        }
    }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.declineOfferId = requestMessage.messageId
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_dashboard/contracts/decline_contract`
      );
  
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
  
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    };
     transformData = (afterEditMilestone: any[]) => {
      return afterEditMilestone.map((milestoneObj: any) => {
        const { attributes } = milestoneObj;
        const expandedMilestone = this.state.expanded.find((state: any) => state.id === attributes?.id);
if(expandedMilestone && expandedMilestone.isEditAnyThing){

  return {
    milestone: {
      id: attributes.id,
      name: attributes.name,
      description: attributes.description,
      amount: attributes.amount,
      due_date: attributes.due_date,
    },
    deliverables: attributes.deliverables.data.map((deliverableObj: any) => {
      const deliverableData: any = {
        deliverable: deliverableObj.attributes.deliverable,
      };
      
      if (deliverableObj.id) {
        deliverableData.id = deliverableObj.id;
      }
      
      return deliverableData;
    }),
  };
}
      });
    };
    putEditMilestoneOffer = async () => {
      const transformedData =this. transformData(this.state.afterEditMilestone);
      const header = {
        "Content-Type": "application/json",
        token: this.state.token,
      };
      const body = {
                "contract_id": this.state.contractId,
          "edited_start_date": dayjs(this.state.afterEditMilestone[0]?.attributes?.due_date).format("DD-MM-YYYY"),
          "milestone_data":
          transformedData
    }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.editMileStoneId = requestMessage.messageId
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_cfdesignersidecontractmanagement/designers_contracts/request_edit_milestone`
      );
  
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
  
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    };
    handleCalculation = (startDate:string,endDate:string,type:string) => {
      const parsedStartDate = new Date(startDate);
        const parsedEndDate = new Date(endDate);
        if (isNaN(parsedStartDate.getTime()) || isNaN(parsedEndDate.getTime())) {
            return;
        }
        const differenceInMilliseconds = parsedEndDate.getTime() - parsedStartDate.getTime();
        if (differenceInMilliseconds < 0) {
            return;
        }
        const daysDifference = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
      if(type?.includes("weeks")){
      
        const daysDifference = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        const weeksDifference = Math.round(daysDifference / 7);
      this.setState({projectTimelineNumber:weeksDifference==0?1:weeksDifference})
      }
      if(type?.includes("months")){
        const monthsDifference = Math.round(daysDifference / 30.44);
        this.setState({projectTimelineNumber:monthsDifference==0?1:monthsDifference})
      }
    
  }
  navigateContractDetail = (id: number) => {
      this.handleNavigation("ActiveContractPage", id)
  }
  handleNavigation(path:string,id:number) {
    this.props.navigation.navigate(path,{id:id})
  }
}


// Customizable Area End
