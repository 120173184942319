export function getTabIndex(params:string) {
    switch (params) {
      case "Portfolio":
      case "Opportunities":
        return 0;
      case "Services":
      case "DK Contracts":
        return 1
      case "Inspirations":
        return 2
      case "Work Experience":
        return 3
      default:
        return 0
    }
  }

export function handleDateFormate(dateString: string | null) {
  let day, month, year;
  if (dateString) {
    if (dateString.includes('-')) {
      [year, month, day] = dateString.split('-');
    } else if (dateString.includes('/')) {
      [day, month, year] = dateString.split('/');
    } else {
      return dateString
    }
    return `${day}/${month}/${year.slice(-2)}`;
  }
}

export function formatDate(isoDate: string): string {
  const date = new Date(isoDate);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);
  const time = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  return `${day}/${month}/${year}, ${time}`;
};

export const formatWalletAmount = (amount: number | string): number => {
  const num = typeof amount === "number" ? amount : parseFloat(amount);
  if (isNaN(num)) return 0;
  return Math.round(num * 100) / 100;
};

export const handleColor = (key:string) => {  
  if(key=="submission_pending"||key=="Ended mid contract"){
    return {
      color:"rgba(255, 120, 120, 1)"
    }
  }
  else if(key=="pending_approval_on_submission"){
    return {
      color:"rgba(89, 189, 211, 1)"
    }
  }
  else if(key=="awaiting_milestone_activation"){
    return {
      color:"#b9c96d"
    }
  }
  else if(key=="Ended Successfully" || key === "approved"){
    return {
      color:"rgba(97, 202, 120, 1)"
    }
  }
}

export const handleconditionBasedFunction = (condition: boolean, fun1: Function, fun2: Function) => {
  if (condition) {
    fun1();
  } else {
    fun2();
  }
}
export const callAfterMilliSec = (callback: () => void, milisec: number) => {
  setTimeout(callback, milisec);
}
