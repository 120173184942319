import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Divider,
  styled,
  Checkbox,
  withStyles,
  Step,
  StepLabel,
  StepContent,
  Dialog,
  DialogContent
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ToastMSG from '../../../components/src/ToastMSG.web';
import AddIcon from '@material-ui/icons/Add';
import StarIcon from '@material-ui/icons/Star';
import dayjs, { Dayjs } from "dayjs";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { CalenderIcon, DownloadIcon } from "../../../components/src/ProposalComponent";
import { StepperStyledComponentWithNumber } from '../../cfproposalmanagement/src/UnderReviewProposal.web'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {avatar} from "../../catalogue/src/assets"
import { IOSSwitch } from "../../../components/src/SmallComponent.web";
import {
  deleteIcon,
  leftArrowIcon
} from '../../dashboard/src/assets';
const config = require("../../../framework/src/config.js")
const customStyle = require('../../dashboard/src/CustomStyles.js');

// Customizable Area End

import CfdesignersidecontractmanagementController, {
  Props,
  configJSON,
} from "./CfdesignersidecontractmanagementController";

export default class Cfdesignersidecontractmanagement extends CfdesignersidecontractmanagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderPhoto = (mainPhoto: string, backgroundColor: string) => {
    let content;
  
    if (mainPhoto) {
        content = (
            <img style={{ width: "100px", height: "100px", borderRadius: "50%" }} src={`${config.baseURL}${mainPhoto}`} />
        );
    } else if (backgroundColor) {
        content = (
            <Box style={{ width: "100px", height: "100px", borderRadius: "50%", backgroundColor: backgroundColor }}></Box>
        );
    } else {
        content = (
            <img src={avatar} style={{ width: "100px", height: "100px", borderRadius: "50%" }} alt="avatar" />
        );
    }
    return <>{content}</>;
  }

  ErrorMsgDisplay = () => {
    return (
      <>{this.state.BudgetErrorMsg && (
        <Typography style={{ color: 'red', marginTop: '10px', fontSize: "12px", fontFamily: "Inter" }}>
          {this.state.BudgetErrorMsg}</Typography>
      )}
      </>
    )
  }

  savedMilestone = () => {
    return (
      this.state.savedSwitch &&
      <StepperStyledComponentWithNumber activeStep={0} orientation="vertical" className="about-under-review-second-box-stepper-media">
        {this.state.savedMilestone.map((label, index) => {
          const isExpanded = this.state.mileStoneExpandData.expandedTerms[index]
          const checkExapanded = isExpanded ? true : false
          return (
            <Step className={index !== this.state.savedMilestone.length - 1 ? 'step-with-line' : ''}
              key={index} expanded={checkExapanded}>
              <StepLabel >
                <Box
                  style={{ background: "transparent", color: "white", fontFamily: "inter", width: '100%' }}
                >
                  <Box
                    data-test-id="handleAccordionToggle"

                    style={OfferReceivedWebStyle.accordianSummaryStyle}
                  >
                    <Box style={{ display: "flex", alignItems: "center", width: '100%', justifyContent: 'space-between', marginBottom: '10px', marginTop: '-17px' }}>
                      <Box style={{ display: "flex", gap: '6px', alignItems: 'center', flex: 1 }}>
                        <Typography style={{ lineBreak: 'anywhere' }}>
                          {label.name}
                        </Typography>
                        <Box style={{ display: 'flex', flexWrap: 'nowrap', gap: '15px' }}
                        >
                          {checkExapanded
                            ?
                            <Box style={OfferReceivedWebStyle.downloadIconStyle} >
                              <ExpandLessIcon data-test-id="expandIconLess-saved" onClick={() => this.toggleTerm(index)} style={{ color: "#fff2e3", fontSize: "25px", cursor: 'pointer' }} />
                            </Box>
                            :
                            <Box style={OfferReceivedWebStyle.downloadIconStyle} >
                              <ExpandMoreIcon data-test-id="expandIcon-saved" onClick={() => this.toggleTerm(index)} style={{ color: "#fff2e3", fontSize: "25px", cursor: 'pointer' }} />
                            </Box>
                          }
                        </Box>
                      </Box>
                      <Box style={{ display: 'flex', gap: '20px', marginLeft: '10px' }}>
                        <CustomBox className='date-container' style={{ width: '150px' }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              data-test-id='date-picker-saved'
                              className="start-date"
                              open={this.state.openCalenderSaved[index]}
                              maxDate={this.handleMaxDateSaved(index)}
                              minDate={this.handleMinDateSaved(index)}
                              onClose={() => this.manageCalenderSaved(index)}
                              value={dayjs(label.due_date)}
                              disablePast={true}
                              onChange={(val: Dayjs | null) => this.handleDateSaved(val, index)}
                              onAccept={() => this.manageCalenderSaved(index)}
                              slotProps={{
                                openPickerIcon: {
                                  onClick: () => this.manageCalenderSaved(index)
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </CustomBox>
                        <Typography style={{ marginTop: '17px' }}>
                          &#8377; {label.amount}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box style={{ marginTop: '10px' }}>
                    <Typography style={{ ...webStyle.font14White400, lineBreak: 'anywhere' }}>
                      {label.description}
                    </Typography>
                  </Box>
                </Box>
              </StepLabel>
              <StepContent>
                {checkExapanded &&
                  <Box style={{ paddingInline: 0, paddingBottom: 0, flexDirection: 'column', marginTop: '10px' }}>
                    <Box style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '10px' }}>
                      <Typography className="deliveerable-style">
                        Deliverables:
                      </Typography>
                      {label.deliverables.map((item, index) => {
                        return <Typography style={{ ...webStyle.font14White400, lineBreak: 'anywhere' }}>
                          {index + 1}.{" "}{item.deliverable}
                        </Typography>
                      })
                      }
                    </Box>
                  </Box>}
              </StepContent>
            </Step>
          )
        }
        )}
      </StepperStyledComponentWithNumber>
    )
  }

  savedError = () => {
    return (!this.checkAllDatesForError() &&
      <Box>
        <Typography style={webStyle.font14Red}>Please make sure the start date and milestone due dates are changed before sending the offer</Typography>
      </Box>
    )
  }

  opportunityCard = () => {
    return (
      <Typography style={webStyle.opportunity_card_price_duration}>Rs. {this.state.workOpportunity.rate_amount.toLocaleString('en-IN')} {" "} {this.state.workOpportunity.rate_type == "hourly" ? "pr/hr" : "Overall"}</Typography>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <MainWrapper>
          <Box
            style={webStyle.newResponseCon}
            data-testid="outside">
            <NavigationMenu {...this.props} activeTab="my work" />
            <Box style={{ width: "100%", backgroundColor: "#111111", display: "flex", gap: "3rem", boxSizing: 'border-box' }}>
              <MainOuterWrap>
                <Box data-testid="allProposals"
                  style={webStyle.allPropsCon}
                >
                  <img src={leftArrowIcon} data-test-id="goback" onClick={this.goBack} style={{ cursor: 'pointer' }} alt="left-icon" />
                  <Typography style={{ ...webStyle.title, marginLeft: '15px' }}>Create contract offer</Typography>
                </Box>
                <Box style={webStyle.contentOuterWrap}>
                  <Box style={webStyle.timelineOuterDiv}>
                    <Box style={{ padding: '15px' }}>
                      <Box style={webStyle.header}>
                        <span>Timeline</span>
                        <span>End date</span>
                        <span>Total Budget</span>
                      </Box>

                      <Box style={webStyle.estimateOuter}>
                        <Box style={webStyle.estimateBoxMain}>
                          <Typography style={webStyle.boxTitleGray}>Initial estimate</Typography>
                          <EstimateBox>
                            <Box className="estimate">
                              <span>{this.state.workOpportunity.project_timeline} {this.state.workOpportunity.project_timeline_type}</span>
                              <span style={{ textAlign: 'center' }}>{this.formatDateShort(this.state.initialState.end_date)}</span>
                              <span style={{ textAlign: 'right' }}>&#8377; {this.state.initialState.total_budget.toLocaleString('en-IN')}</span>
                            </Box>
                          </EstimateBox>
                        </Box>

                        <Box style={webStyle.actualBoxMain}>
                          <Typography style={webStyle.boxTitleWhite}>Actual</Typography>
                          <ActualBox>
                            <Box className="actual">
                              <span>{this.actualWeekCount()}</span>
                              <span style={{ textAlign: 'center' }}>{this.actualEndDate()}</span>
                              <span style={{ textAlign: 'right' }}>₹ {this.actualTotalCount()}</span>
                            </Box>
                          </ActualBox>
                        </Box>
                      </Box>

                      {!this.state.savedSwitch ?
                        <Box style={webStyle.timelineOuter}>
                          {this.state.milestoneList.map((label, index) => {
                            return (
                              <>
                                <Box style={webStyle.timelineItem}>
                                  <ActualListLine style={webStyle.circle}>
                                    <Typography className={index < this.state.milestoneList.length - 1 ? "line" : ''}>{index + 1}</Typography>
                                  </ActualListLine>
                                  <span style={webStyle.font14White}>{this.formatDateShort(label.dueDate)}</span>
                                  <span style={webStyle.amountText}>{label.amount} &#8377;</span>
                                </Box>
                              </>
                            )
                          })}
                        </Box>
                        :
                        <Box style={webStyle.timelineOuter}>
                          {this.state.savedMilestone.map((label, index) => {
                            return (
                              <>
                                <Box style={webStyle.timelineItem}>
                                  <ActualListLine style={webStyle.circle}>
                                    <Typography className={index < this.state.savedMilestone.length - 1 ? "line" : ''}>{index + 1}</Typography>
                                  </ActualListLine>
                                  <span style={webStyle.font14White}>{this.formatDateShort(label.due_date)}</span>
                                  <span style={webStyle.amountText}>{label.amount} &#8377;</span>
                                </Box>
                              </>
                            )
                          })}
                        </Box>
                      }
                    </Box>
                  </Box>
                  <FormOuterDiv>
                    {this.state.offerType &&
                      <>
                        <Box style={webStyle.formOuterDiv}>
                          <Box display="flex" alignItems='center' gridGap={20}>
                            <Typography style={webStyle.font16White}>Use saved contract</Typography>
                            <IOSSwitch data-test-id="switch" checked={this.state.savedSwitch} onChange={this.savedSwichChange} />
                          </Box>
                        </Box>
                        <Box style={webStyle.divider} />
                      </>
                    }
                    <Box style={webStyle.formOuterDiv}>
                      <div style={webStyle.milestoneHeader}>
                        <h3 style={webStyle.font20White}>Add Milestone</h3>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                          <span style={webStyle.font14White}>Start date:</span>{"   "}
                          <CustomBox className='date-container' style={{ width: '150px' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                data-test-id='date-picker'
                                className="start-date"
                                open={this.state.openCalenderStart}
                                maxDate={this.handleMaxDateStart()}
                                minDate={this.handleCurrentDateStart()}
                                onClose={() => this.manageCalenderStart()}
                                value={this.state.startDate}
                                disablePast={true}
                                onChange={(val: Dayjs | null) => this.handleDateStart(val)}
                                onAccept={() => this.manageCalenderStart()}
                                slotProps={{
                                  openPickerIcon: {
                                    onClick: this.manageCalenderStart
                                  }
                                }}
                              />
                            </LocalizationProvider>
                          </CustomBox>
                        </div>
                      </div>

                      {!this.state.savedSwitch &&
                        <StepperStyledComponentWithNumber activeStep={0} orientation="vertical" className="about-under-review-second-box-stepper-media">
                          {this.state.milestoneList.map((label, index) => {
                            const isExpanded = this.state.mileStoneExpandData.expandedTerms[index]
                            const checkExapanded = isExpanded ? true : false
                            return (
                              <Step className="step-with-line" key={index} expanded={checkExapanded}>
                                <StepLabel >
                                  <Box
                                    style={{ background: "transparent", color: "white", fontFamily: "inter", width: '100%' }}
                                  >
                                    <Box
                                      data-test-id="handleAccordionToggle"

                                      style={OfferReceivedWebStyle.accordianSummaryStyle}
                                    >
                                      <Box style={{ display: "flex", alignItems: "center", width: '100%', justifyContent: 'space-between', marginBottom: '10px' }}>
                                        <Box style={{ display: "flex", gap: '6px', alignItems: 'center', flex: 1 }}>
                                          <Typography style={{ lineBreak: 'anywhere' }}>
                                            {label.title}
                                          </Typography>
                                          <Box style={{ display: 'flex', flexWrap: 'nowrap', gap: '15px' }}>
                                            {checkExapanded
                                              ?
                                              <Box
                                                style={OfferReceivedWebStyle.downloadIconStyle} >
                                                <ExpandLessIcon data-test-id="expandIconLess"
                                                  onClick={() => this.toggleTerm(index)}
                                                  style={{ color: "#fff2e3", fontSize: "25px", cursor: 'pointer' }} />
                                              </Box>
                                              :
                                              <Box style={OfferReceivedWebStyle.downloadIconStyle} >
                                                <ExpandMoreIcon onClick={() => this.toggleTerm(index)}
                                                  data-test-id="expandIcon"
                                                  style={{ color: "#fff2e3", fontSize: "25px", cursor: 'pointer' }} />
                                              </Box>
                                            }
                                            <Box style={OfferReceivedWebStyle.downloadIconStyle}
                                              data-test-id="editRequestDataTestId"
                                              onClick={() => this.MileStonePoPuP(index)}
                                            >
                                              {DownloadIcon()}

                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box style={{ display: 'flex', gap: '20px', marginLeft: '10px' }}>
                                          <Box style={{ display: 'flex', gap: '10px' }}>

                                            {CalenderIcon()}
                                            <Typography>
                                              {this.formatDateShort(label.dueDate)}
                                            </Typography>
                                          </Box>
                                          <Typography>&#8377; {label.amount}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box style={{ marginTop: '10px' }}>
                                      <Typography style={{ ...webStyle.font14White400, lineBreak: 'anywhere' }}>{label.description}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </StepLabel>
                                <StepContent>
                                  {checkExapanded &&
                                    <Box style={{ paddingInline: 0, paddingBottom: 0, flexDirection: 'column', marginTop: '10px' }}>
                                      <Box style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '10px' }}>
                                        <Typography className="deliveerable-style">Deliverables:</Typography>
                                        {label.deliverables.map((item, index) => {
                                          return <Typography style={{ ...webStyle.font14White400, lineBreak: 'anywhere' }}>
                                            {index + 1}.{" "}{item}
                                          </Typography>
                                        })
                                        }
                                      </Box>
                                    </Box>}
                                </StepContent>
                              </Step>
                            )
                          }
                          )}
                          <Step className="step-with-line">
                            <StepLabel >
                              <Box style={webStyle.formItem}>
                                <CustomBox className='formGroup' style={{ marginTop: '0' }}>
                                  <Typography style={webStyle.font16White}>Milestone name *</Typography>
                                  <CustomTextField
                                    className='project-title main-text'
                                    type='text'
                                    placeholder="Milestone name"
                                    data-test-id='input-data'
                                    value={this.state.title}
                                    inputProps={{
                                      maxLength: 50
                                    }}
                                    onChange={(event) => this.handleTitleChange(event.target.value)}
                                  />
                                  <Typography className='limit-title'>
                                    {this.state.nameCountCharacter}/50
                                  </Typography>
                                </CustomBox>
                                <CustomBox className='formGroup'>
                                  <Typography data-test-id="description-label" style={webStyle.font16White}>Description *</Typography>
                                  <CustomTextField
                                    className='description-title main-text'
                                    placeholder='Description'
                                    data-test-id='deliverablesElm'
                                    inputProps={{
                                      maxLength: 500,
                                      style: {
                                        scrollbarWidth: 'none',
                                      }
                                    }}
                                    style={{ overflow: 'hidden' }}
                                    multiline={true}
                                    rows={5}
                                    value={this.state.description}
                                    onChange={(event) => this.handleDescriptionChange(event.target.value)}
                                  />
                                  <Typography className='limit-title'>
                                    {this.state.descCountCharacter}/500
                                  </Typography>
                                </CustomBox>
                                <CustomBox className='formGroup'>
                                  <Typography style={webStyle.font16White}>Deliverables *</Typography>
                                  {this.state.deliverableList.map((item, index) =>
                                    <>
                                      <Box style={webStyle.deliverableOuter}>
                                        <CustomBox className='default-text deliverable-title' style={webStyle.deliverableBox}>
                                          {index + 1}.
                                          <CustomTextField
                                            type='text'
                                            className='search-field default-text rateData normal-style'
                                            placeholder='Add deliverable'
                                            data-test-id='input-data'
                                            value={item}
                                            inputProps={{
                                              maxLength: 150
                                            }}
                                            onChange={(event) => this.handleQuestionData(event.target.value, index)}
                                          />
                                          <img data-test-id='delete-ques' onClick={() => this.removeQuestion(index)} className='pointer-cursor' src={deleteIcon} alt='delete question' />
                                        </CustomBox>
                                        <button style={webStyle.cancelButton} onClick={this.addDeliverable} data-testId="clost-btn">
                                          <AddIcon fontSize="small" />
                                        </button>
                                      </Box>
                                      <Typography className='limit-title-deliverable'>
                                        {this.state.deliverableCountList[index]}/150
                                      </Typography>
                                    </>
                                  )}
                                </CustomBox>

                                <CustomBox className='formGroup'>
                                  <Typography style={webStyle.font16White}>Amount *</Typography>
                                  <CustomBox className='default-text deliverable-title' style={webStyle.amountBox}>
                                    &#8377;
                                    <CustomTextField
                                      type='text'
                                      className='search-field default-text rateData normal-style'
                                      placeholder='0.00'
                                      data-test-id='input-data'
                                      value={this.state.amount}
                                      onChange={(event) => this.handleAmountChange(event.target.value)}
                                    />
                                  </CustomBox>
                                  {this.ErrorMsgDisplay()}
                                </CustomBox>

                                <CustomBox className='formGroup'>
                                  <Typography style={webStyle.font16White}>Due date *</Typography>
                                  <CustomBox className='hours-container'>
                                    <CustomBox className='date-container' style={{ width: '206px', paddingLeft: '15px', paddingRight: '5px' }}>
                                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                          data-test-id='date-picker'
                                          open={this.state.openCalender}
                                          minDate={this.handleCurrentDate()}
                                          onClose={() => this.manageCalender()}
                                          value={this.state.dateData}
                                          disablePast={true}
                                          onChange={(val: Dayjs | null) => this.handleDate(val)}
                                          onAccept={() => this.manageCalender()}
                                          slotProps={{
                                            openPickerIcon: {
                                              onClick: this.manageCalender
                                            }
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </CustomBox>
                                  </CustomBox>
                                </CustomBox>
                              </Box>
                            </StepLabel>
                          </Step>
                        </StepperStyledComponentWithNumber>
                      }

                      {this.savedMilestone()}

                      {!this.state.savedSwitch &&
                        <CustomBox className='formGroup' style={{ padding: '0 40px' }}>
                          <CustomButton
                            data-test-id='save-draft'
                            disabled={!this.enableAddMilestone()}
                            className={`button-elm  draft-btn default-btn medium-text`}
                            onClick={this.addMilestone}
                          >Add Milestone</CustomButton>
                        </CustomBox>
                      }
                    </Box>
                    <Box style={{ ...webStyle.divider, margin: '25px 0' }} />

                    <Box style={webStyle.formOuterDiv}>
                      {this.savedError()}
                      <CustomBox style={{ marginTop: '30px' }}>
                        <CustomBox style={{ display: 'flex', gap: '12px', alignItems: "center" }}>
                          <CustomCheckBox data-test-id='customCheckbox'
                            checked={this.state.conditionChecked1}
                            onClick={() => this.toggleCondition1()}
                            style={{ backgroundColor: this.handleCheckBg1(), padding: '0' }}
                          />
                          <span style={webStyle.font14White}>In case the designer does not accept within 72 hours or declines the contract,
                            the contract details will be saved, and the contract can then be taken up
                            with another designer.</span>
                        </CustomBox>
                      </CustomBox>

                      <CustomBox style={{ marginTop: '15px' }}>
                        <CustomBox style={{ display: 'flex', gap: '12px', alignItems: "center" }}>
                          <CustomCheckBox data-test-id='customCheckbox'
                            checked={this.state.conditionChecked2}
                            onClick={() => this.toggleCondition2()}
                            style={{ backgroundColor: this.handleCheckBg2(), padding: '0' }}
                          />
                          <span style={webStyle.font14White}>I agree to all terms and conditions
                            with another designer.</span>
                        </CustomBox>
                      </CustomBox>

                      <CustomButton data-test-id='create-work' style={{ width: '194px', marginTop: '45px' }}
                        disabled={!this.enablePostWorkOpportunity()}
                        className={`button-elm ${this.enablebuttonText()} medium-text`}
                        onClick={this.Contract}
                      >Offer contract</CustomButton>
                    </Box>
                  </FormOuterDiv>
                  <Box style={webStyle.rightOuterDiv}>
                    <Box style={webStyle.opportunity_card_main_container} >
                      <Box style={{ padding: '22px 20px' }}>
                        <Box style={webStyle.opportunity_card_title_box}>
                          <Typography style={webStyle.opportunity_card_title}>{this.state.workOpportunity.title}</Typography>
                          <Box style={webStyle.opportunity_card_price_duration_box}>
                            {this.opportunityCard()}
                            <Typography style={webStyle.opportunity_card_duration_color}>{this.state.workOpportunity.project_timeline}{" "}{this.state.workOpportunity.project_timeline_type}</Typography>
                          </Box>
                          <Typography style={webStyle.opportunity_card__description}>{this.state.workOpportunity.description}</Typography>
                        </Box>
                        <Box>
                          <Box style={webStyle.opportunity_card_person_detain_box}>
                            <Box >
                              <Typography style={webStyle.opportunity_card_hour}>{this.state.workOpportunity.posted_at}</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <CardBox data-test-id="navigate">
                        <Box style={{ display: "flex", alignItems: "center", gap: "30px", padding: "2rem 1rem" }}>
                          <Box>
                            {this.renderPhoto(this.state.proposal.profile_photo, this.state.proposal.background_color)}
                          </Box>
                          <Box><Typography style={{
                            fontFamily: "Inter",
                            fontSize: "20px",
                            fontWeight: 700,
                            lineHeight: "24.2px",
                            color: "#FFF2E3",
                            textTransform: "capitalize",
                            width: "100%",
                            wordBreak: "break-all"
                          }}>{this.state.proposal.user_name}</Typography>
                            <Typography style={{
                              fontFamily: "Inter",
                              fontSize: "16px",
                              fontWeight: 500,
                              lineHeight: "19.36px",
                              color: "#FFF2E3",
                              textTransform: "capitalize",
                              paddingTop: "0.5rem"
                            }}>{this.state.proposal.location}</Typography></Box>
                        </Box>
                        <Box style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", padding: "0px 0.5rem" }}>
                          <Box>
                            <Typography style={{
                              fontFamily: "Inter",
                              fontWeight: 400,
                              lineHeight: "16.94px",
                              color: "#7D7D7D",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              textAlign: "center"
                            }}>Views</Typography>
                            <Typography style={{
                              fontFamily: "Inter",
                              fontSize: "16px",
                              lineHeight: "19.36px",
                              color: "#FFF2E3",
                              textTransform: "capitalize",
                              fontWeight: 500,
                              paddingTop: "0.5rem",
                              textAlign: "center"
                            }}>{this.state.proposal.view_count}</Typography>
                          </Box>
                          <Box style={{ borderLeft: "1px solid grey", paddingLeft: "1rem" }}>
                            <Typography style={{
                              fontFamily: "Inter",
                              fontWeight: 400,
                              lineHeight: "16.94px",
                              color: "#7D7D7D",
                              fontSize: "14px",
                              textTransform: "capitalize",
                              textAlign: "center"
                            }}>Likes</Typography>
                            <Typography style={{
                              fontFamily: "Inter",
                              fontSize: "16px",
                              lineHeight: "19.36px",
                              color: "#FFF2E3",
                              textTransform: "capitalize",
                              fontWeight: 500,
                              paddingTop: "0.5rem",
                              textAlign: "center"
                            }}>{this.state.proposal.likes_count}</Typography>
                          </Box>
                          <Box style={{ borderRight: "1px solid grey", borderLeft: "1px solid grey", padding: "0px 10px" }}>
                            <Typography style={{
                              fontFamily: "Inter",
                              fontWeight: 400,
                              textTransform: "capitalize",
                              lineHeight: "16.94px",
                              fontSize: "14px",
                              color: "#7D7D7D",
                              textAlign: "center"
                            }}>Submitted rate</Typography>
                            <Typography style={{
                              fontFamily: "Inter",
                              paddingTop: "0.5rem",
                              fontSize: "16px",
                              lineHeight: "19.36px",
                              color: "#FFF2E3",
                              fontWeight: 500,
                              textAlign: "center"
                            }}>{this.state.proposal.service_rate.toLocaleString('en-IN')} {"pr/hr"}</Typography>
                          </Box>
                          <Box>
                            <Typography style={{
                              fontFamily: "Inter",
                              fontWeight: 400,
                              textAlign: "center",
                              lineHeight: "16.94px",
                              color: "#7D7D7D",
                              fontSize: "14px",
                              textTransform: "capitalize",
                            }}>Ratings</Typography>
                            <Box style={{
                              display: "flex", alignItems: "center", justifyContent: "center", marginTop: "5px",
                              height: "26px", width: "60px", background: "green", borderRadius: "3px", gap: "2px"
                            }}>
                              <Typography style={{
                                fontSize: "16px",
                                textTransform: "capitalize",
                                fontWeight: 500,
                                lineHeight: "19.36px",
                                fontFamily: "Inter",
                                color: "#FFF2E3",
                                textAlign: "center",
                              }}>{this.state.proposal.rating}</Typography>
                              <Box>
                                <StarIcon style={{ color: "white", width: "14px", height: "13px" }} />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box style={{ borderTop: "0.97px solid #545454", marginTop: "20px" }}>
                          <Box style={{ padding: "1rem" }}>
                            <Typography style={{
                              fontFamily: "Inter",
                              textTransform: "capitalize",
                              fontWeight: 400,
                              lineHeight: "13.31px",
                              fontSize: "11px",
                              color: "#66625C",
                              textAlign: "left",
                            }}>{this.state.proposal.received_time_ago}</Typography>
                          </Box>
                          <Box style={{
                            padding: "1rem"
                          }}>
                            <Typography style={{
                              lineHeight: "16.94px",
                              fontFamily: "Inter",
                              fontWeight: 700,
                              color: "#FFF2E3",
                              fontSize: "14px",
                              textTransform: "capitalize",
                              textAlign: "left",
                            }}>Pitch</Typography>
                          </Box>
                          <Box style={{ padding: "1rem", height: "6rem" }}>
                            <Typography style={{
                              fontFamily: "Inter",
                              textAlign: "left",
                              fontSize: "14px",
                              fontWeight: 400,
                              textTransform: "capitalize",
                              overflow: "hidden",
                              lineHeight: "16.94px",
                              display: "-webkit-box",
                              WebkitLineClamp: 5,
                              color: "#FFF2E3",
                              WebkitBoxOrient: "vertical",
                              textOverflow: "ellipsis",
                              wordWrap: "break-word"

                            }}>{this.state.proposal.give_your_pitch}</Typography>
                          </Box>
                        </Box>
                      </CardBox>
                    </Box>
                  </Box>
                </Box>
              </MainOuterWrap>
              <DioalogComponent
                open={this.state.editMileStone}
                onClose={() => this.MileStonePoPuP()
                }
                fullWidth={true}
                data-test-id="close-onclick"
                style={{
                  scrollbarWidth: 'none'
                }}
                maxWidth="md"
              >
                <DialogContent
                >
                  <Typography className="popUpHeading" style={{ textAlign: 'unset', borderBottom: 'unset', paddingInline: '10%', width: '90%' }}>
                    {'Edit milestone'}
                  </Typography>
                  <Divider style={{
                    backgroundColor: "rgba(84, 84, 84, 1)", width: '100%',
                    marginBlock: '10px'
                  }} />
                  <Box style={OfferReceivedWebStyle.editMileStoneBox}>
                    <CustomBox className='formGroup'>
                      <Typography style={webStyle.font16White}>Milestone name</Typography>
                      <CustomTextField
                        className='project-title-new main-text'
                        type='text'
                        name="title"
                        placeholder="Milestone name"
                        data-test-id='input-data'
                        inputProps={{
                          maxLength: 50
                        }}
                        value={this.state.editMileStoneAllData.title}
                        onChange={(event) => this.handleTitleEditChange(event.target.name, event.target.value)}
                      />
                      <Typography className='limit-title'>
                        {this.state.editnameCountCharacter}/50
                      </Typography>
                    </CustomBox>
                  </Box>
                  <Box style={OfferReceivedWebStyle.editMileStoneBox}>
                    <CustomBox className='formGroup'>
                      <Typography style={webStyle.font16White}>Description</Typography>
                      <CustomTextField
                        className='description-title-new main-text'
                        placeholder='Description'
                        name='description'
                        data-test-id='deliverablesElm'
                        inputProps={{
                          maxLength: 500,
                          style: {
                            scrollbarWidth: 'none',
                          }
                        }}
                        style={{ overflow: 'hidden' }}
                        multiline={true}
                        rows={5}
                        value={this.state.editMileStoneAllData.description}
                        onChange={(event) => this.handleTitleEditChange(event.target.name, event.target.value)}
                      />
                      <Typography className='limit-title'>
                        {this.state.editdescCountCharacter}/500
                      </Typography>
                    </CustomBox>
                  </Box>
                  <Box style={OfferReceivedWebStyle.editMileStoneBox}>
                    <CustomBox className='formGroup'>
                      <Typography style={webStyle.font16White}>Deliverables</Typography>
                      {this.state.editMileStoneAllData.deliverables.map((item, index) =>
                        <>
                          <Box style={webStyle.deliverableOuter}>
                            <CustomBox className='default-text deliverable-title-new' style={webStyle.deliverableBox}>
                              {index + 1}.
                              <CustomTextField
                                type='text'
                                className='search-field default-text rateData normal-style'
                                placeholder='Add deliverable'
                                data-test-id='input-data'
                                value={item}
                                inputProps={{
                                  maxLength: 150
                                }}
                                onChange={(event) => this.handleQuestionEditData(event.target.value, index)}
                              />
                              <img data-test-id='delete-ques' onClick={() => this.removeQuestionEdit(index)} className='pointer-cursor' src={deleteIcon} alt='delete question' />
                            </CustomBox>
                            <button style={webStyle.cancelButton} onClick={this.addDeliverableEdit} data-testId="clost-btn">
                              <AddIcon fontSize="small" />
                            </button>
                          </Box>
                          <Typography className='limit-title-deliverable'>
                            {this.state.editdeliverableCountList[index]}/150
                          </Typography>
                        </>
                      )}
                    </CustomBox>
                  </Box>
                  <Box style={OfferReceivedWebStyle.editMileStoneBox}>

                    <CustomBox className='formGroup'>
                      <Typography style={webStyle.font16White}>Amount</Typography>
                      <CustomBox className='default-text deliverable-title-new' style={webStyle.amountBox}>
                        &#8377;
                        <CustomTextField
                          type='text'
                          className='search-field default-text rateData normal-style'
                          placeholder='0.00'
                          data-test-id='input-data'
                          name="amount"
                          value={this.state.editMileStoneAllData.amount}
                          onChange={(event) => this.handleTitleEditChange(event.target.name, event.target.value)}
                        />
                      </CustomBox>
                    </CustomBox>
                  </Box>
                  <Box style={OfferReceivedWebStyle.editMileStoneBox}>
                    <CustomBox className='formGroup'>
                      <Typography style={webStyle.font16White}>Due date</Typography>
                      <CustomBox className='hours-container-new'>
                        <CustomBox className='date-container' style={{ width: '206px', paddingLeft: '15px', paddingRight: '5px' }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              data-test-id='date-picker'
                              open={this.state.openCalenderEdit}
                              minDate={this.handleMinDateEdit()}
                              maxDate={this.handleMaxDateEdit()}
                              onClose={() => this.manageCalenderEdit()}
                              value={this.state.editMileStoneAllData.dueDate}
                              disablePast={true}
                              onChange={(val: Dayjs | null) => this.handleDateEdit(val)}
                              onAccept={() => this.manageCalenderEdit()}
                              slotProps={{
                                openPickerIcon: {
                                  onClick: this.manageCalenderEdit
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </CustomBox>
                      </CustomBox>
                    </CustomBox>
                  </Box>
                  <Divider style={{
                    backgroundColor: "rgba(84, 84, 84, 1)", width: '100%',
                    marginBlock: '20px'
                  }} />
                  <Box style={{
                    display: 'flex', justifyContent: 'space-between', width: '392px',
                    paddingBlock: '15px',
                    paddingInline: '60px'

                  }}>

                    <Button className="btn-cancel"
                      data-test-id="closeMileStonePoPuP"
                      onClick={
                        () => this.MileStonePoPuP()
                      }
                    >
                      Cancel
                    </Button>
                    <Button className="btn-send-offer"
                      data-test-id="editUpdate"
                      disabled={!this.isFormValid()}
                      onClick={() => {
                        this.editUpdate()
                      }}
                      style={{
                        background:
                          "rgba(255, 242, 227, 1)"
                      }}
                    >
                      {"Edit milestone"}
                    </Button>
                  </Box>
                </DialogContent>
              </DioalogComponent>
              <ToastMSG data-test-id="toast" open={this.state.open} close={() => this.handleClose()} message={this.state.message} action={this.state.action} />
            </Box>
          </Box>
        </MainWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle: Record<string, React.CSSProperties> = {
  newResponseCon: {
    width: "100%",
    backgroundColor: "#111111",
    minHeight: '100vh'
  },
  allPropsCon: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    alignSelf: "flex-start"
  },
  contentOuterWrap: {
    display:'flex',
    gap: '28px',
    justifyContent: 'center'
  },
  title: {
    fontFamily: "Inter, sans-serif",
    fontSize: '36px',
    fontWeight: 700,
    lineHeight: '43.57px',
    color: '#FFF2E3'
  },

  timelineOuterDiv: {
    margin: '35px 0',
    backgroundColor: '#111111',
    borderRadius: '12px',
    width: '25%',
    flex: "0 0 25%",
    color: '#fff',
    fontFamily: 'Inter, sans-serif',
    border: '1px solid #535353',
    height: 'max-content'
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '10px 0',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: "14.52px",
    color: "#8C8C8C",
    marginLeft: '20px',
  },
  estimateBoxMain: {
    backgroundColor: '#222222',
    borderRadius: '8px',
  },
  estimateOuter:{
    marginTop: '15px',
  },
  boxTitleGray: {
    fontFamily: 'Inter',
    fontSize: '8px',
    fontWeight: 500,
    lineHeight: '9.68px',
    color: "#8C8C8C",
    borderBottom: '0.5px solid #535353',
    padding: '10px 10px 4px'
  },
  boxTitleWhite: {
    fontFamily: 'Inter',
    fontSize: '8px',
    fontWeight: 500,
    lineHeight: '9.68px',
    color: "#FFF2E3",
    borderBottom: '0.5px solid #FFF2E3',
    padding: '10px 10px 4px'
  },
  actualBoxMain: {
    background: `linear-gradient(90.83deg, #3FA7E1 -0.01%, #7699EF 31.13%, #AE9DF2 51.64%, #E8A5CA 72.11%, #F1B2C6 99.99%)`,
    borderRadius: '8px',
  },
  timelineOuter: {
    padding: '20px 20px 10px'
  },
  timelineItem: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    height: '0%',
    position: 'relative',
    flexWrap: 'wrap',
    marginTop: '20px'
  },
  font14White: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16.94px',
    color: '#FFF2E3'
  },
  font14Red: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16.94px',
    color: '#FF0000'
  },
  font14White400: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16.94px',
    color: '#FFF2E3'
  },
  font16White: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.36px',
    color: '#FFF2E3'
  },
  font20White: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24.2px',
    color: '#FFF2E3'
  },
  circle: {
    backgroundColor: '#fff',
    color: '#111',
    borderRadius: '50%',
    width: '25px',
    height: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
    minWidth: '25px',
    zIndex: 2
  },
  dividerLeftBox: {
    width: '2px',
    height: '30px',
    backgroundColor: '#fff',
    marginLeft: '12px',
  },
  amountText: {
    marginLeft: 'auto',
    fontSize: '14px',
    padding: '8px 16px',
    backgroundColor: '#222222',
    borderRadius: '20px',
    color: '#8C8C8C',
    fontWeight: 600,
    lineHeight: '16.94px'
  },
  formItem: {
    marginBottom: '16px',
  },
  milestoneHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 700,
    marginBottom: '30px',
  },
  divider: {
    height: '1px',
    backgroundColor: '#333',
  },
  cancelButton: {
    background: "#545454",
    color: "#FFF2E3",
    borderRadius: '50%',
    border: 'none',
    cursor: 'pointer',  
    padding: '8px',
    marginLeft: '15px'
  },
  deliverableOuter:{
    display: 'flex',
    alignItems: 'center'
  },
  deliverableBox: {
    display: 'flex', 
    alignItems: 'center', 
    paddingLeft: '15px',
    paddingRight: '10px', 
    borderRight: '1px solid #2f2f2f', 
    color: '#fff2e3',
    gap: '18px'
  },
  amountBox: {
    display: 'flex', 
    alignItems: 'center', 
    width: '197px', 
    paddingLeft: '15px', 
    paddingRight: '10px', 
    borderRight: '1px solid #2f2f2f', 
    color: '#fff2e3', 
    gap: '18px'
  },
  formOuterDiv: {
    padding: '24px 50px',
  },
  rightOuterDiv: {
    margin: '35px 0',
    width: '23%',
    flex: '0 0 23%'
  },
  opportunity_card_main_container: {
    borderRadius: 13,
    gap: 24,
    border: "1px solid #3E3E3E",
    maxWidth: '372px',
    width: '100%',
  },
  opportunity_card_title_box: {
    borderColor: '#3E3E3E',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: "Inter , sans-serif",
    alignItems: "stretch",
    gap: 14,
    justifyContent: "space-between",
  },
  opportunity_card_title: {
    fontSize: "20px",
    fontFamily: "Inter,sans-serif",
    color: "#FFF2E3",
    fontWeight: 600,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: 1.5,
    maxHeight: "3em",
  },
  opportunity_card_price_duration_box: {
    display: 'flex'
  },
  opportunity_card_price_duration: {
    padding: "6px 16px 6px 16px",
    fontSize: "14px",
    borderRadius: "24px",
    backgroundColor: "#1D1D1D",
    fontFamily: "Inter,sans-serif",
    color: "#1CBAE0",
    fontWeight: 600,
  },
  opportunity_card_duration_color: {
    padding: "6px 16px 6px 16px",
    fontSize: "14px",
    borderRadius: "24px",
    backgroundColor: "#1D1D1D",
    fontFamily: "Inter,sans-serif",
    color: "#FFB9C7",
    marginLeft: 20,
    fontWeight: 600,
  },
  opportunity_card__description: {
    fontSize: "12px",
    height: 50,
    fontFamily: "Inter,sans-serif",
    fontWeight: 400,
    color: "#FFF2E3",
    flexGrow: 1,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: 1.5,
    maxHeight: "3em",
  },
  opportunity_card_person_detain_box: {
    display: "flex",
    justifyContent: 'space-between',
    alignItems: "baseline",
    marginTop: 10,
  },
  opportunity_card_hour: {
    color: "#66625C",
    fontSize: "11px",
    fontWeight: 400,
    fontFamily: "Inter,sans-serif",
  },
}

const OfferReceivedWebStyle = {
  accordianIconStyle: {
    backgroundColor: "rgba(51, 51, 51, 1)",
    borderRadius: "50%",
    padding: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  editMileStoneBox:{
    width:'90%',
    gap:'4px',
    display:'flex' as 'flex',
    marginBottom:'37px',
     paddingInline: '10%',
    flexDirection:'column' as 'column'
  },
  accordianSummaryStyle: {
    fontSize: "20px",
    fontWeight: 700,
    margin:0
    , width: '100%' as '100%',
    borderBottom: "1px solid rgba(84, 84, 84, 1)",
    lineHeight: "24.2px",
    textAlign: "left" as 'left',
    color: "#fff2e3",
    paddingLeft: "0px" as '0px',
  },
  downloadIconStyle: {
    borderRadius: '50%',
    background: "rgba(84, 84, 84, 1)",
    width: '30px', height: '30px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  txtfield: {
    width: "100%%",
    height: "49px",
    padding:'10px 4px',
    overflow: "hidden",
    borderRadius: "11.97px",
    color: '#fff',
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    backgroundColor: '#313131',
  },
  textFieldStyle: {
    width: '80%',
    margin: "0px 5px",
    background: 'transparent',
    border: 'none',
    color: "rgba(255, 242, 227, 1)",

    outline: "none",
    fontFamily: 'Inter',
  fontSize: '18.62px',
  fontWeight: 400,
  lineHeight: '22.53px',
  },
}

const FormOuterDiv  = styled(Box)({
  maxWidth: '900px',
    width: '100%',
    backgroundColor: '#171717',
    margin: '35px 0',
    borderRadius: '10px',
    "@media (max-width:1280px)": {
      padding: '24px 15px',
  },
})

const MainOuterWrap = styled(Box)({
  margin: '170px auto',
  backgroundColor: '#111111',
  width: 'min(100%,1745px)',
  "@media (max-width:1775px)": {
    margin: '170px 35px',
  },
  "@media (max-width:1280px)": {
    margin: '170px 35px',
  },
})

const CardBox = styled(Box)({
  width: "100%",
  margin: "18px 0",
  border: "1.15px solid #6D6D6D",
  borderRadius: "11.54px",
  overflow: 'hidden',
  "@media (max-width:960px)": {
      height: "533px",
  },
  "@media (min-width:720px)": {

  },
});

const DioalogComponent = styled(Dialog)({
  '& .MuiPaper-root':{
    backgroundColor:'unset',
  },
  '& .MuiDialog-paperWidthSm': {
    maxWidth: '478px',
  },
  '& .MuiPickersLayout-contentWrapper': {
    background: 'rgba(34, 34, 34, 1) '

  },
  '& .MuiDialogContent-root': {
    paddingInline: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'rgba(34, 34, 34, 1)'
  }
})

const MainWrapper = styled(Box)(({ theme }) => ({
  "& .find_work_main_container": {
    display: "flex",
    width: '100%',
    backgroundColor: "#111111"
  },
}))

const EstimateBox = styled(Box)({
  "& .estimate": {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '10px 12px',
    color: '#FFF2E3',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '19.36px',
   "& span": {
      position: 'relative',
      flex: 1
    },
    "& span:not(:last-child)::after": {
      content: '""',
      position: "absolute",
      right: '0',
      top: '0',
      width: '1px',
      backgroundColor: '#FFF2E3',
      height: '100%'
    },
    "@media (max-width:1280px)": {
      padding: '24px 15px',
    },
  },
});

const ActualBox = styled(Box)({
  "& .actual": {
  display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '10px 12px',
    color: '#FFF2E3',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '19.36px',
    "& span": {
      position: 'relative',
      flex: 1
    },
    "& span:not(:last-child)::after": {
      content: '""',
      position: "absolute",
      right: '0',
      top: '0',
      width: '1px',
      backgroundColor: '#FFF2E3',
      height: '100%'
    },
  }

})

const ActualListLine = styled(Box)({
  "& .line": {
    "&::after": {
      content: '""',
      position: "absolute",
      width: '2px',
      background: '#fff',
      height: '100%',
      top: '25px',
      left: "12px",
    },
  },
});

const CustomBox = withStyles((theme) => ({
  root: {
    '&.formGroup': {
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      marginTop: '28px'
    },
    '& .project-title, .description-title, .deliverable-title': {
      height: '50px',
      border: 'none',
      backgroundColor: '#222',
      borderRadius: '11.97px',
      paddingLeft: '27px',
      paddingRight: '27px',
      fontFamily: 'Inter, sans-serif',
      fontSize: '15px',
      lineHeight: '18.15px',
    },
    '& .project-title-new, .description-title-new, .deliverable-title-new': {
      height: '50px',
      border: 'none',
      backgroundColor: '#2F2F2F',
      borderRadius: '11.97px',
      paddingLeft: '27px',
      paddingRight: '27px',
      fontFamily: 'Inter, sans-serif',
      fontSize: '15px',
      lineHeight: '18.15px',
    },
    '& .main-text': {
      fontFamily: 'Inter, sans-serif',
      fontSize: '15px',
      lineHeight: '18.15px',
      color: '#fff2e3'
    },
    '& .limit-title': {
      maxWidth: '952px',
      color: '#fff2e3',
      textAlign: 'end',
      marginTop: '-20px'
    },
    '& .limit-title-deliverable': {
      maxWidth: '315px',
      color: '#fff2e3',
      textAlign: 'end',
      marginTop: '-20px'
    },
    '& .description-title': {
      padding: '27px !important',
      outline: 'none',
      maxWidth: '952px',
      height: '130px',
      resize: 'none',
    },
    '& .description-title-new': {
      padding: '27px !important',
      height: '130px',
      resize: 'none',
      outline: 'none',
    },
    '& .deliverable-title': {
      maxWidth: '295px'
    },
    '& .deliverable-title-new': {
      maxWidth: '295px'
    },
    '& .description-title:focus': {
      outline: 'none',
    },
    '& .description-title-new:focus': {
      outline: 'none',
    },
    '& .default-text': {
      fontFamily: 'Inter, sans-serif',
      fontSize: '16px',
      lineHeight: '19.36px'
    },
    '& .search-field': {
      width: '100%',
      backgroundColor: 'transparent',
      border: 'none',
      height: '49px',
      color: '#fff2e3',
    },
    '& .rateData::placeholder': {
      color: '#595959',
    },
    '& .normal-style input::placeholder': {
      fontStyle: 'normal !important',
    },
    '& .hours-container': {
      display: 'flex',
      alignItems: 'center',
      height: '50px',
      backgroundColor: '#222',
      borderRadius: '10px',
      width: '223px',
      marginTop: '10px'
    },
    '& .hours-container-new': {
      display: 'flex',
      alignItems: 'center',
      height: '50px',
      backgroundColor: '#2F2F2F',
      borderRadius: '10px',
      width: '223px',
      marginTop: '10px'
    },
    '& .date-container fieldset': {
      outline: 'none',
      border: 'none',
    },
    '& .date-container input': {
      color: '#fff2e3',
    },
    '& .date-container button': {
      color: '#fff2e3',
    },
    '& .date-container input::placeholder': {
      color: '#686868',
    },
    '& .draft-btn': {
            width: '175px',
        },
    '& .medium-text': {
            fontFamily: 'Inter-Medium, sans-serif !important',
        },
        '& .pointer-cursor': {
          cursor: 'pointer',
      },
  }
}))(Box);

const CustomTextField = withStyles({
  root: {
      ...customStyle.customStyle.inputField
  }
})(Input);

const CustomButton = withStyles({
  root: {
      ...customStyle.customStyle.buttonField
  }
})(Button);

const CustomCheckBox = withStyles({
  root: {
      color: '#d9d9d9',
      width: '20px',
      height: '20px',
      borderRadius: '2.3px',
      border: 'none'
  },
  checked: {
      '&.Mui-checked': {
          color: '#111111'
      }
  },

})(Checkbox);


const theme = createTheme({
  palette: {
      primary: {
          main: '#fff2e3',
      }
  },
  components: {
      MuiPaper: {
          styleOverrides: {
              root: {
                  backgroundColor: '#222',
                  color: '#fff2e3'
              }
          }
      },
      MuiTypography: {
          styleOverrides: {
              root: {
                  fontFamily: 'Inter,sans-serif !important',
                  color: '#fff2e3 !important',
              }
          }
      },
      MuiIconButton: {
          styleOverrides: {
              root: {
                  color: '#fff2e3 !important'
              }
          }
      },
      MuiButtonBase: {
          styleOverrides: {
              root: {
                  fontFamily: 'Inter,sans-serif !important',
                  color: '#7d7d7d !important',
                  '&.Mui-selected': {
                      color: '#222 !important',
                      backgroundColor: '#fff2e3 !important'
                  },
                  '&.MuiPickersDay-today': {
                      border: '1px solid #fff2e3 !important'
                  }
              }
          }
      },
      MuiDialogContent: {
          styleOverrides: {
              root: {
                  paddingTop: 0,
                  paddingBottom: 0,
                  backgroundColor: "transparent",
                  overflowY: 'auto',
                  boxShadow: "none",
                  overflowX: "hidden",
                  scrollbarWidth: 'none',
                  msOverflowStyle: 'none',
                  '&::-webkit-scrollbar': {
                      width: '0',
                      height: '0',
                  }
              }
          }
      }
  },
});
// Customizable Area End
