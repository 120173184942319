import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";

// Customizable Area Start
import { backgroundImage, userLogo } from "../assets";
import { getStorageData,setStorageData,removeStorageData } from "framework/src/Utilities";
import storage from "../../../../framework/src/StorageProvider.web";
import { createRef, RefObject } from "react";

let tokenData = "";
let loggedInUserId = "";

export interface ReplyComment {
  data: ReplyCommentData[]
  meta: ReplyCommentMeta
}

export interface ReplyCommentData {
  id: string
  type: string
  attributes: ReplyCommentDataAttributes
}

export interface ReplyCommentDataAttributes {
  id: number
  account_id: number
  commentable_id: number
  commentable_type: string
  comment: string
  updated_at: string
  created_at: string
  is_delete: boolean
  profile_photo: any
  background_color_code: string
  has_reply: boolean
  account_user_name: string
  account_full_name: string
  user_role: string
  reply_count: number
  commented_time_ago: string
}

export interface ReplyCommentMeta {
  success: boolean
  message: string
}

export interface CommentRoot {
  data: CommentData[]
  meta: CommentRootMeta
}

export interface CommentData {
  type: string
  id: string
  attributes: CommentDataAttributes
}

export interface CommentDataAttributes {
  id: number
  account_id: number
  commentable_id: number
  commentable_type: string
  comment: string
  created_at: string
  updated_at: string
  is_delete: boolean
  profile_photo: string | null | undefined
  background_color_code: string | null | undefined
  has_reply: boolean
  account_user_name: string
  account_full_name: string
  reply_count: number
  user_role: string
  commented_time_ago: string
  commentReplyData: ReplyCommentData[]
}

export interface CommentRootMeta {
  page: number
  message: string
  comments_count: number
}

export interface ForumPost {
  meta: ForumPostMeta
  data: ForumPostData[]
}

export interface ForumPostData {
  type: string
  id: string
  attributes: ForumPostDataAttributes
}

export interface Video {
  video_url: string
  video_thumbnail: string
}

export interface ForumPostDataAttributes {
  post_description: string
  id: number
  who_can_view: string
  created_at: string
  updated_at: string
  images: string[]
  is_like: boolean
  like_id: number
  videos: Video[]
  create_time: string
  user_details: ForumPostDataAttributesUserDetails
  likes: number
  views: number
  media: {type: string, src: string, videoUrl: string}[]
  comments: number
  commentData: CommentData[]
  commentPage: number
  commentTotalPage: number
}

export interface ForumPostDataAttributesUserDetails {
  name: string
  email: string
  profile_id: number
  id: number
  user_location: any
  profile_photo: string
  full_name: string
  background_color: any
  profile_cover_image: string
  role: string
}

export interface ForumPostMeta {
  forum_posts_count: number
  message: string
  page: number
}

export interface ProfileDetails {
  id: number;
  country: string;
  address: string;
  city: string;
  postal_code: string;
  account_id: number;
  photo: string | undefined;
  background_color: string;
  name: string;
  website_link: string;
  about: string;
  expertise_category: [];
  skills: [];
  languages: [];
  min_per_hour: number;
  max_per_hour: number;
  dribble: string;
  instagram: string;
  linkedin: string;
  member_since: string;
  followers_count: number;
  following_count: number;
  work_experience: {
    company_name: string;
    role:string;
    duration: string;
    total_years_worked:string ;
  }[];
  profile_cover_image: string | undefined;
  company_details:any
 
}


// Customizable Area End

export const configJSON = require("../config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: any;
  lastName: any;
  email: any;
  currentCountryCode: any;
  data: any[];
  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  expertiseTempData:any[];
  skillsTempData:any[];
  profileImageData:any;
  selectedFile: File | null;
  combineImg:string | null | unknown;
  saveImage:string | '' |unknown;
  saveProfileImage:string |'' |unknown
  UserInfoData:any;
  userName: string;
  userRole:string;
  activeTab: number;
  userActivity: boolean
  showAll: { [key: string]: boolean }
  openVideo: boolean
  selectedVideoSrc: string
  commentData: CommentData[]
  commentPerPage: number
  commentPage: number
  commentTotalPage: number
  pageOfPost: number
  perPageOfPost: number
  totalPostofForum: number
  forumData: ForumPostData[]
  commentTextArray: { [key: string]: string };
  open: boolean
  message: string
  action: string
  commentOpenArray: { [key: string]: boolean };
  showReply: string | number
  selectedCommentforReply: string | number
  replyText: string
  offViewApiCall: boolean
  viewReplyId: string
  forumIdModal: boolean
  shareDialog: boolean
  shareSelectedProjectId: string|number
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  labelFirstName: string;
  lastName: string;
  btnTextSaveChanges: string;

  apiCallMessageUpdateProfileRequestId: any;
  
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  userProfileImageGetApiCallId:string = "";
  userProfileFollowApiCallId:string=""
  profileViewApiCallId:string=""
  userProfileUnFollowApiCallId:string = ""
  deleteProfileImageGetApiCallId:string = "";
  updateImageAPICallId:string = "";
  userAttr: any;
  baseUrlStirng:string = configJSON.baseURL.baseURL;
  updateChildState: null | Function = null;
  bodyContainerRef: RefObject<HTMLDivElement> = createRef();
  getPostForumApiCallId: string = "";
  getPostForumScrollApiCallId: string = "";
  postViewForumApiCallId: string = "";
  postComentForumApiCallId: string = ""
  getCommentForumApiCallId: string = ""
  getCommentForumScrollApiCallId: string = ""
  dislikeForumApiCallId: string = ""
  replyCommentApiCallId: string = ""
  apiGetReplyCallId: string = ""
  shareSendInAppNotificationApiCallId: string = ""

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [ 
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),     
      getName(MessageEnum.SessionSaveMessage),      
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
   
    this.state = {
      activeTab: 0,
      firstName: "",
      lastName: "",
      email: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      profileImageData:  {
      
            profile_details: {
                id: 0,
                country: "",
                address: "",
                city: "",
                postal_code: "",
                account_id: 0,
                photo: "",
                background_color: "",
                name: "",
                website_link: "",
                about: "",
                expertise_category: [],
                skills: [],
                languages: [],
                min_per_hour: 0,
                max_per_hour: 0,
                dribble: "",
                instagram: "",
                linkedin: "",
                member_since: "",
                followers_count: 0,
                following_count: 0,
                work_experience: [{
                  company_name: '',
                role:'',
                duration: '',
                total_years_worked:'' 
                }],
                profile_cover_image: "",
                company_details:"",
                professional_experience: {
                  latest_record: [{
                  professional_experiences_count: 0,
                    position: "",
                    company_name: "",
                    ending_in_year: "",
                    starting_from_year: "",
                    calculate_years: ""
                  }],
                }
            },
            educations: [
                {
                    id: 0,
                    qualification: "",
                    year_from: "",
                    year_to: "",
                    description: ""
                }
            ],
            profile_views: 0,
            profile_likes: 0 ,
            is_followed : false      
    },

    selectedFile:null,
    combineImg:null,
    saveImage: '',
    saveProfileImage:'',
      UserInfoData:"",
      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      expertiseTempData:["Graphic Design","UX Design","UI design","UI design","UI design","UI design","UI design"],
      skillsTempData:["Illustrator","Photoshop","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's"],
     
        userName: "",
        userRole:'',
      userActivity: false,
      showAll: {},
      openVideo: false,
      selectedVideoSrc: '',
      commentData: [],
      commentPage: 1,
      commentPerPage: 5,
      commentTotalPage: 0,
      pageOfPost: 1,
      perPageOfPost: 4,
      totalPostofForum: 0,
      forumData: [],
      commentTextArray: {},
      action: '',
      message: '',
      open: false,
      commentOpenArray: {},
      showReply: '',
      replyText: '',
      offViewApiCall: false,
      selectedCommentforReply: '',
      viewReplyId: '',
      forumIdModal: false,
      shareDialog: false,
      shareSelectedProjectId: ''
    };
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    this.userProfile(message)
 if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );

      if (requesterId === this.uniqueSessionRequesterId) {
        const sessionToken = message.getData(
          getName(MessageEnum.SessionResponseToken)
        );
        this.authToken = sessionToken;

        this.getUserProfile();
      }
    }
   

    // Customizable Area End
  }



  getUserProfile() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      token: this.authToken,
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  // Customizable Area Start
  handleViewAllWorkExperienceAtPublicProfile = () => {
    setStorageData("subTab", "Professional Experience")
    if(this.updateChildState) this.updateChildState(3,"Work Experience");
    if(this.bodyContainerRef.current) {
      this.bodyContainerRef.current.scrollIntoView({behavior: "smooth",block:"start"})
    }
    this.setState({
      userActivity: false
    })
  }

  updateState = (updateState: Function) => {
    this.updateChildState = updateState;
  }
  userProfile = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const profileImageJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.getPostForumApiCallId:
          this.handleForumGetPost(profileImageJson);
          break;

        case this.getPostForumScrollApiCallId:
          this.handleForumGetPostScroll(profileImageJson);
          break;

          case this.postViewForumApiCallId:
            this.handleLikeAndGetPost(profileImageJson);
            break;

            case this.postComentForumApiCallId:
              this.handleCommentResponse(profileImageJson);
              break;

              case this.getCommentForumApiCallId:
                this.handleSetCommnetData(profileImageJson);
                break;
    
              case this.getCommentForumScrollApiCallId:
                this.handleSetCommnetDataScroll(profileImageJson);
                break;

                case this.dislikeForumApiCallId:
            this.handleLikeAndGetPost(profileImageJson);
            break;

          case this.replyCommentApiCallId:
            this.handleLikeAndGetPost(profileImageJson);
            break;

          case this.apiGetReplyCallId:
            this.handleReplyGetCommentData(profileImageJson);
            break;

        default:
          break;
      }
      if (apiRequestCallId === this.userProfileImageGetApiCallId) {
        this.setState({
          profileImageData: profileImageJson.profile_data,
        })
      }
      if(apiRequestCallId===this.userProfileFollowApiCallId){
        if(!profileImageJson.error){
          this.setState(prevState => ({
            profileImageData: {
              ...prevState.profileImageData,
              is_followed: !prevState.profileImageData.is_followed
            }
          }));
        }
      }
      if(apiRequestCallId === this.userProfileUnFollowApiCallId){
        if(!profileImageJson.error){
          this.setState(prevState => ({
            profileImageData: {
              ...prevState.profileImageData,
              is_followed: !prevState.profileImageData.is_followed
            }
          }));
        }
      }
    }
  }

  handleLikeAndGetPost = (responseJson: { meta?: { message: string } } & { message?: string }) => {
    if (
      responseJson.meta?.message === "Forum post liked successfully" ||
      responseJson.meta?.message === "Comment created." ||
      responseJson.message === "Successfully dislike forum post"
    ) {
      this.getPostForum(1);
    }
  };

  handleReplyGetCommentData = (responseJson: ReplyComment) => {
    if (responseJson.data) {
      let postId = responseJson.data[0].attributes.commentable_id
      this.setState((prevState) => ({
        forumData: prevState.forumData.map((post) =>
          Number(post.id) === Number(this.state.selectedCommentforReply)
        ? {
          ...post,
          attributes: {
            ...post.attributes,
            commentData: post.attributes.commentData.map((comment) =>
              Number(comment.id) === Number(postId)
                ? {
                    ...comment,
                    attributes:{
                      ...comment.attributes,
                    commentReplyData: responseJson.data
                    }
                  }
                : comment
            ),
          },
        }
      : post
  ),
}));
    }
  }

  handleForumGetPost = (responseJson: ForumPost) => {
    if (responseJson.data) {
      const transformedData: ForumPostData[] = responseJson.data.map((item) => ({
        ...item,
        attributes: {
          ...item.attributes,
          media: [
            ...(item.attributes.images || []).map((img) => ({ type: "image", src: img, videoUrl: '' })),
            ...(item.attributes.videos || []).map((vid) => ({ type: "video", src: vid.video_thumbnail, videoUrl: vid.video_url }))
          ],
          commentPage: 1,
          commentData: [],
          commentTotalPage: 0
        }
      }));
      this.setState({
        totalPostofForum: responseJson.meta.forum_posts_count,
        forumData: transformedData
      }
        , () => {
          if (this.state.offViewApiCall) {
            responseJson.data.forEach((item) => {
              this.postViewLike(item.attributes.id, "view")
            })
          }
        })
    } else {
      this.setState({
        totalPostofForum: 0,
        forumData: []
      })
    }
  }

  handleCommentResponse = (responseJson: { "meta": { "success": boolean, "message": string } }) => {
    if (responseJson.meta.success && responseJson.meta.message === "Comment created.") {
      this.setState({
        message: responseJson.meta.message,
        open: true,
        action: "success",
        commentOpenArray: {}
      })
      this.getPostForum(1)
    }
  }

  handleSetCommnetData = (responseJson: CommentRoot) => {
    if (responseJson.data) {
      let postId = responseJson.data[0].attributes.commentable_id
      const updatedComments = responseJson.data.map(comment => ({
        ...comment,
        attributes: {
          ...comment.attributes,
          commentReplyData: []
        }
      }));
      this.setState((prevState) => ({
        forumData: prevState.forumData.map((post) =>
          post.id === String(postId)
            ? {
              ...post,
              attributes: {
                ...post.attributes,
                commentData: updatedComments,
                commentTotalPage: responseJson.meta.comments_count
              },
            }
            : post
        ),
      }));
    }
  }

  handleSetCommnetDataScroll = (responseJson: CommentRoot) => {
    if (responseJson.data) {
      let postId = responseJson.data[0].attributes.commentable_id
      const updatedComments = responseJson.data.map(comment => ({
        ...comment,
        attributes: {
          ...comment.attributes,
          commentReplyData: []
        }
      }));

      this.setState((prevState) => ({
        forumData: prevState.forumData.map((post) =>
          post.id === String(postId)
            ? {
              ...post,
              attributes: {
                ...post.attributes,
                commentPage: post.attributes.commentPage + 1,
                commentData: [...post.attributes.commentData, ...updatedComments],
              },
            }
            : post
        ),
      }));
    }
  }

  handleForumGetPostScroll = (responseJson: ForumPost) => {
    this.setState({
      pageOfPost: this.state.pageOfPost + 1
    })
    if (responseJson.data) {
      const transformedData: ForumPostData[] = responseJson.data.map((item) => ({
        ...item,
        attributes: {
          ...item.attributes,
          media: [
            ...(item.attributes.images || []).map((img) => ({ type: "image", src: img, videoUrl: '' })),
            ...(item.attributes.videos || []).map((vid) => ({ type: "video", src: vid.video_thumbnail, videoUrl: vid.video_url }))
          ],
          commentTotalPage: 0,
          commentData: [],
          commentPage: 1
        }
      }));
      this.setState((prevState) => ({
        forumData: [...prevState.forumData, ...transformedData],
        totalPostofForum: responseJson.meta.forum_posts_count,
      }), () => {
        responseJson.data.forEach((item) => {
          this.postViewLike(item.attributes.id, "view")
        })
      })
    } else {
      this.setState({
        forumData: [],
        totalPostofForum: 0
      })
    }
  }
 
  getUserProfileImage = async () => {
    const tokens = await getStorageData("userInfo");
    const PublicId = await getStorageData("PublicId");
    const paramId = this.props.navigation.getParam("id");
    const paramRole = this.props.navigation.getParam("role");
    let accountInfo = JSON.parse(PublicId)

    this.setState({UserInfoData:JSON.parse(tokens),userRole:paramRole ? paramRole : accountInfo.user_role})
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken;   
   const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userProfileImageGetApiCallId = getValidationsMsg.messageId
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserImageProfile + `${paramId?paramId:accountInfo.account_id}`  )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile 
    );
    
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  timer: ReturnType<typeof setTimeout> | null = null;
  async componentDidMount() {  
    setStorageData("isPublicProfile",true)
    let userInfo = await getStorageData("userInfo");
    let storeData = JSON.parse(userInfo);
    if (userInfo) {
      tokenData = storeData.meta.token;
      loggedInUserId = storeData.meta.id;
      this.setState({
        UserInfoData: storeData
      })
    }
    this.initialRenderApi()
  }

  handleShareModal = (id?: number) => {
    if (id) {
      this.sendInAppNotificactionShare(id)
      this.setState({shareSelectedProjectId: id})
    }
    this.setState({shareDialog: !this.state.shareDialog})
  }

  getProjectIDForumPostContent = () => {
    const data = this.state.forumData.filter((item)=>(item.attributes.id === this.state.shareSelectedProjectId))
    const name = data.length > 0 ? data[0].attributes.post_description : '';
    return name
  }

  initialRenderApi = () => {
    this.getUserProfileImage();  
    this.getUserName();
    this.getPostForum()
    this.timer = setTimeout(() => {
      this.updateProfileView();
    }, 3000);
  }

  async componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  updateProfileView = async () => {
    const userProfile = await getStorageData("userInfo",true);
    const PublicId = await getStorageData("PublicId");
    let accountInfo = JSON.parse(PublicId)
    const body =
    {
      "account_id": accountInfo?.account_id
    }   
    const headers = {
      "Content-Type": "application/json",
      token: userProfile.meta.token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.profileViewApiCallId = getValidationsMsg.messageId
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileViewApiEndPoint  )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo 
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  imageUrl = () => {
    const profileCoverImage = this.state.profileImageData?.profile_details?.profile_cover_image;
    const imageUrl = this.state.saveImage || (profileCoverImage ? `${this.baseUrlStirng}${profileCoverImage}` : backgroundImage);
    return imageUrl;
  }
  imageProfile =() =>{
    const profilePhoto = this.state.profileImageData?.profile_details?.photo;
    const profileImageUrl = this.state.saveProfileImage || (profilePhoto ? `${this.baseUrlStirng}${profilePhoto}` : userLogo);
    return profileImageUrl
  }
  imgproUpdate = () => {
    const profileCoverImage3 =  this.state.profileImageData?.profile_details?.photo;
    const profileupdateImageUrl =  this.state.combineImg ||  (profileCoverImage3 ? `${this.baseUrlStirng}${profileCoverImage3}` : userLogo) 
    return profileupdateImageUrl
  }

  getUserName = async () => {
    let userProfileName = await getStorageData("userInfo");

    userProfileName = JSON.parse(userProfileName);
    
    userProfileName = userProfileName.data.attributes.first_name + this.handleNullvalue(userProfileName.data.attributes.last_name )
     
     if (userProfileName) {
       this.setState({ userName: userProfileName});
     };
   
  }
  handleNullvalue = (value: string | null)=>{
    if(value === null){
      return "";
    }else{
      return value;
    }
  };
  websideShow =() => {
    const websiteName =this.state.profileImageData.profile_details?.website_link ? this.state.profileImageData.profile_details.website_link:"--" 
    return websiteName
  }
  memberSince = () => {
   const memberName = this.state.profileImageData.profile_details.member_since ? this.state.profileImageData.profile_details.member_since:"--" 
    return memberName
  }
  showLoaction = () => {
    const { city, country } = this.state.profileImageData.profile_details || {};
    if (!city || !country) {
      return "Add Location";
    }
    const location = city === country ? city : `${city}, ${country}`;
    return location;
  }

  handleFollowAndUnfollow = () => {
    this.state.profileImageData.is_followed ? this.handleUnFollow() : this.handleFollow()
  }
  handleUnFollow = async () => {
    const userProfile = await getStorageData("userInfo",true);
    const PublicId = await getStorageData("PublicId");
    let accountInfo = JSON.parse(PublicId)
    const headers = {
      "Content-Type": "application/json",
      token: userProfile.meta.token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userProfileUnFollowApiCallId = getValidationsMsg.messageId
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.followUnfollowApiEndPoint+ `/${accountInfo?.account_id}` )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteUserType 
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleFollow = async () => {
    const userProfile = await getStorageData("userInfo",true);
    const PublicId = await getStorageData("PublicId");
    let accountInfo = JSON.parse(PublicId)
    const body = {
      "data": {
       "attributes": {
           "account_id":  accountInfo?.account_id
       }
      } 
   }
    const headers = {
      "Content-Type": "application/json",
      token: userProfile.meta.token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userProfileFollowApiCallId = getValidationsMsg.messageId
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.followUnfollowApiEndPoint  )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo 
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  showAboutInfo  = () => {
   const showAbout = this.state.profileImageData?.profile_details.about != "" ? this.state.profileImageData.profile_details.about:"--" 
  
   return showAbout
  }
  CompanyName = () =>{
    let compananyName = this.state.profileImageData.profile_details.company_details?.data?.attributes.company_name
    
    const showCompanyName = compananyName? compananyName: 'CompanyName'
    return showCompanyName
  }
  showIndustry = () =>{
    let Industry  = this.state.profileImageData.profile_details.company_details?.data?.attributes.industry.name
    const shouldShowIndustry = Industry? Industry:"No Information"
    return shouldShowIndustry
  }
  showDesignation = () =>{ 
    let designationName = this.state.profileImageData.profile_details.company_details?.data?.attributes.designation.name
    const shouldShowDesignation = designationName? designationName:"No Information"
    return shouldShowDesignation
  } 



  aboutRoleShow = () =>{
    const showRoles = this.state.userRole == "designer" ? "About Designer" : "About Client";
    return showRoles;
  }
  profileView = () => {
   const  showProfile = this.state.profileImageData.profile_views?this.state.profileImageData.profile_views: '-'
    return  showProfile
  }
  JobsPosted =() =>{
    const jobsPosted = this.state.profileImageData.opportunity_count ? this.state.profileImageData.opportunity_count: 0
    return jobsPosted
  }
  activeOpportunities = () =>{
    const activeOpportunities = this.state.profileImageData.open_opportunity_count ? this.state.profileImageData.open_opportunity_count: 0
    return activeOpportunities}
    
  showLike = () => {
    const likefollow = this.state.profileImageData.profile_likes?this.state.profileImageData.profile_likes: '-'
    return likefollow
  }
  avgRate = () => {
   const showAvfRate = this.state.profileImageData.profile_details.min_per_hour + this.state.profileImageData.profile_details.max_per_hour / 2 
   const AvgRates =  showAvfRate ? `₹${showAvfRate}` :'--'
   return this.state.profileImageData.profile_details?.average_rate ?? 0
  }
  showFollowing =  () => {
    const following =this.state.profileImageData.profile_details?.following_count?this.state.profileImageData.profile_details?.following_count: 0
    return following
  }
  followers = () => {
  const showFollower =this.state.profileImageData.profile_details?.followers_count?this.state.profileImageData.profile_details?.followers_count: 0
  return showFollower
}
totalWorkExp = () => {
  const workExp  =this.state.profileImageData.profile_details.work_experience
    ? this.state.profileImageData.profile_details.work_experience.length
    : 0
    return workExp
}

formatYears = (yearFrom:string|number, yearTo:string|number) => {
  return yearFrom && yearTo ? `${yearFrom} - ${yearTo}` : '';
}
  handleNavigationToChatScreen = async () => {
    storage.set("navigateUserId",JSON.stringify(this.state.profileImageData.sendbird_data))
    const message: Message =
      new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "Chat");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
    this.send(message);
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  handleManageViewActivityClose = () => {
    this.setState({
      userActivity: false
    })
  }

  handleManageViewActivityOpen = () => {
    this.setState({
      userActivity: true
    })
  }

  handleShowAll = (id: number) => {
    this.setState((prevState) => ({
      showAll: {
        ...prevState.showAll,
        [id]: true,
      }
     }));
  };

  handleModelOpen = (src: string) => {
    this.setState({ openVideo: true, selectedVideoSrc: src })
  }

  handleModelCloseOnCross = () => {
    this.setState({ openVideo: false, selectedVideoSrc: '' })
  }
  
  enableCommentBtn = (id: number) => {
    return !!this.state.commentTextArray?.[id]?.trim();
  }

  HandleNavigation = (comment: { userId: number | string, user_role: string, collection_name: string, collection_id: number | string }, userId: number | string) => {
    let path = userId == comment.userId ? "UserProfile" : "PublicUserProfile"
    let localdata = {
      user_role: comment.user_role,
      account_id: comment.userId,
      collection_id: comment.collection_id ?? 0,
      collection_name: comment.collection_name ?? ""
    }
    if (path == "PublicUserProfile") {
      setStorageData("PublicId", JSON.stringify(localdata));
    }

    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 
      path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleComment = (id: number, text: string) => {
    this.setState((prevState) => ({
      commentTextArray: {
        ...prevState.commentTextArray,
        [id]: text,
      },
    }));
  };

  handleReply = (id: string | number) => {
    return this.state.viewReplyId === String(id);
  }

  handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,id:number) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.postComment(id);
    }
  };

  handleReplyClick = (id: string | number) => {
    this.setState({
      showReply: id,
      selectedCommentforReply: ''
    });
  }

  handleReplyChange = (data: string) => {
    this.setState({
      replyText: data
    });
  }

  handleReplyAdded = async (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement> | React.KeyboardEvent<HTMLDivElement>, id: number) => {
    if (event.key === 'Enter') {
      this.addReply();
      this.setState({
        selectedCommentforReply: id,
        showReply: '',
        replyText: ''
      })
      this.setState((prevState) => {
        const { commentOpenArray } = prevState;
        const updatedShowAll = { ...commentOpenArray };
        if (id in updatedShowAll) {
          delete updatedShowAll[id];
        } else {
          updatedShowAll[id] = true;
        }
        return { commentOpenArray: updatedShowAll, showReply: "" };
      }) 
    }
  }

  handleCancelReply = () => {
    this.setState({
      replyText: '',
      showReply: ''
    });
  }

  getPostForum = async(id?:number) => {
    if (id){
      this.setState({offViewApiCall:false})
    } else {
      this.setState({offViewApiCall:true})
    }
    const PublicId = await getStorageData("PublicId");
    let storeDataNew = JSON.parse(PublicId);
    let accountId = ""
    if (PublicId) {
      accountId = storeDataNew.account_id
    } else {
      accountId = loggedInUserId
    }
    const header = {
      token: tokenData,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPostForumApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      id ? configJSON.postForumPostApiEndPoint + `?page=${1}&per_page=${this.state.perPageOfPost * this.state.pageOfPost}&account_id=${accountId}`
      : configJSON.postForumPostApiEndPoint + `?page=${this.state.pageOfPost}&per_page=${this.state.perPageOfPost}&account_id=${accountId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getPostForumScroll = async() => {
    const PublicId = await getStorageData("PublicId");
    let storeDataNew = JSON.parse(PublicId);
    let accountId = ""
    if (PublicId) {
      accountId = storeDataNew.account_id
    } else {
      accountId = loggedInUserId
    }
    const header = {
      token: this.state.UserInfoData.meta.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPostForumScrollApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postForumPostApiEndPoint + `?page=${this.state.pageOfPost + 1}&per_page=${this.state.perPageOfPost}&account_id=${accountId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  postViewLike = (id: number, type: string) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      token: tokenData
    }
    let dataView = {
      "data": {
        "attributes": {
          "viewable_type": "BxBlockPosts::ForumPost",
          "viewable_id": id
        }
      }
    }
    let dataLike = {
      "data": {
        "attributes": {
          "likeable_type": "BxBlockPosts::ForumPost",
          "likeable_id": id
        }
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postViewForumApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),type === "view" ? configJSON.postViewApiEndPoint : configJSON.postLikeApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),type === "view" ? JSON.stringify(dataView) : JSON.stringify(dataLike)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.callTypeApiValidateMobileNo);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  postComment = (id: number) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: tokenData
    }
    let data = {
      "comment": {
        "commentable_id": id,
        "comment": this.state.commentTextArray[id],
        "commentable_type": "BxBlockPosts::ForumPost"
      }
    }
    this.setState((prevState) => ({
      commentTextArray: {
        ...prevState.commentTextArray,
        [id]: '',
      },
    }));
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postComentForumApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.postCommentApiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getComment = (index: number, id: number) => {
    if (id) {
      this.setState((prevState) => {
        const { commentOpenArray, forumData } = prevState;
        const updatedShowAll = { ...commentOpenArray };
        if (id in updatedShowAll) { 
          delete updatedShowAll[id] 
        }
        else { 
          updatedShowAll[id] = true
        }
        return {
          showReply: "",
          commentOpenArray: updatedShowAll, 
          forumData: forumData.map((post) =>
            Number(post.id) === Number(id) && !(id in commentOpenArray)
              ? {
                ...post,
                attributes: {
                  ...post.attributes,
                  commentData: [...post.attributes.commentData],
                  commentTotalPage: 0,
                  commentPage: 1
                },
              }
              : post
          ),
        };
      }, () => {
        if (this.state.commentOpenArray[id]) {
          let token = tokenData;
          const header = {
            token: token,
          }
          const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
          requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getCommentDataApiEndPoint + `?id=${id}&page=${this.state.forumData[index].attributes.commentPage}&per_page=${this.state.commentPerPage}`);
          this.getCommentForumApiCallId = requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType);
          runEngine.sendMessage(requestMessage.id, requestMessage);
          return true;
        }
      });
    }
  };

  getCommentScroll = (index: number, id: number) => {
    const header = {
      token: tokenData
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCommentForumScrollApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCommentDataApiEndPoint + `?id=${id}&page=${this.state.forumData[index].attributes.commentPage + 1}&per_page=${this.state.commentPerPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getReplyList = (id: string | number, postId: number) => {
    let token = tokenData;
    if (this.state.viewReplyId == id) {
      this.setState({
        viewReplyId: '',
      })
    } else {
      this.setState({
        viewReplyId: String(id),
        selectedCommentforReply: postId
      }, () => {
        const header = {
          token: token,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetReplyCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.commentsEndPoint + `/${id}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      })
    }
  }

  addReply = () => {
    let token = tokenData;
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      token: token,
    }
    const bodyData = {
      "comment": {
        "comment": this.state.replyText,
        "commentable_id": this.state.showReply,
        "commentable_type": "BxBlockComments::Comment"
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.replyCommentApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postCommentApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  disLikeProject = (id: number) => {
    let token = tokenData;
    const header = {
      token: token,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.dislikeForumApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forumDislikeApiEndPoint + `?id=${id}`)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteUserType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  sendInAppNotificactionShare = (id: number) => {
    let token = tokenData;
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      token: token,
    }
    const bodyData = {
      "id": id 
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.shareSendInAppNotificationApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.shareNotificationApiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.callTypeApiValidateMobileNo);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;    
  }
  // Customizable Area End

}
