import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { RefObject, createRef } from "react";
import { imgBell } from "./assets";
export const config = require("../../joblisting/src/config");
import { DropDownElement } from "../../joblisting/src/JobListingController";

interface ITab {
  tabName: string;
  tabId: string;
  count: string;
}

interface ProposalData {
  id: number
  name: string
  value: string
  work_opportunity_count: number
  color?: string
}

export interface SendbirdData {
  user_id: string
  nickname: string
  profile_url: string
  require_auth_for_profile_image: boolean
  metadata: {}
  access_token: string
  created_at: number
  discovery_keys: any[]
  is_hide_me_from_friends: boolean
  is_shadow_blocked: boolean
  session_tokens: any[]
  is_online: boolean
  last_seen_at: number
  is_active: boolean
  has_ever_logged_in: boolean
  preferred_languages: any[]
  locale: string
  unread_channel_count: number
  unread_message_count: number
}

interface INotification {
  id: string;
  type: string;
  attributes: {
      id: number;
      created_by: number;
      headings: string;
      contents: string;
      navigation_id: string | null;
      app_url: string;
      is_read: boolean;
      read_at: string | null;
      created_at: string;
      updated_at: string;
      proposal_data: ProposalData;
      sendbird_data: SendbirdData|''
      profile_image: string | null
      background_colour: string | null
      user_role: string | null
      current_status: string | null
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  selectedData: any;
  token: any;
  tabsList: ITab[];
  activeTab: string;
  dezinerToken: string;
  notificationsList: INotification[];
  notificationsCount: Record<string,string>;
  isLoading: boolean;
  currentPage: number;
  isNotificationsLoading: boolean;
  totalNotificationsCount: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  markAsReadCallId: string = "";
  deleteCallId: string = "";
  getNotificationsListId: string = "";
  notificationCountId: string = "";
  viewNotificationApiCallId: string = "";
  private perPage: number = 20;
  designerCardRef: RefObject<HTMLDivElement> = createRef();
  designersProfileObserver: IntersectionObserver | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      selectedData: null,
      token: "",
      tabsList: configJSON.tabsData,
      activeTab: "all",
      dezinerToken: "",
      notificationsList: [],
      isLoading: true,
      notificationsCount: {},
      currentPage: 1,
      isNotificationsLoading: true,
      totalNotificationsCount: 1,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getTokenFromLocalStorage();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getNotifications();
    } else if (
      this.getDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      apiResponse &&
        this.setState({
          data: apiResponse.data,
        });
    } else if (
      this.markAsReadCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.getNotifications();
    } else if (
      this.deleteCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        this.showAlert("Message", configJSON.deleteMessage);
      }
      this.setState({ selectedData: null });
      this.getNotifications();
    }
    this.handleReceiveMessages(message);
    // Customizable Area End
  }

  // Customizable Area Start
  iconBellProps = {
    source: imgBell,
  };

  getNotifications() {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getDataCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPoint
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  markAsRead(id: number) {
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  deleteNotifications(id: number) {
    const deletedMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteCallId = deletedMsg.messageId;

    deletedMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deletedMsg.id, deletedMsg);
  }
  
  timeSince(date: string) {
    var seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }
  convertDate(inputFormat: string) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  }

  getResponseFromMessage = (message: Message) => { 
    let response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const requestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    return ({ requestCallId, response })
  }

  handleReceiveMessages = (message: Message) => {
    const {requestCallId} = this.getResponseFromMessage(message)
    if(requestCallId === this.getNotificationsListId){
      this.handleNotificationsList(message);
      return;
    }

    if(requestCallId === this.notificationCountId){
      this.handleNotificationsCount(message)
    }
  }

  handleNotificationsCount = (message: Message) => {
    const { response } = this.getResponseFromMessage(message);
    if (!!response) {
      this.setState({
        notificationsCount: response,
      });
    }
    this.setState({ isLoading: false })
  }

  handleNotificationsList = (message: Message) => {
    const { response } = this.getResponseFromMessage(message);

    if (!!response.notifications &&
      !!response.notifications.data &&
      Array.isArray(response.notifications.data) &&
      !isNaN(response.notification_count)
    ) {
      this.setState(prev => ({
        notificationsList: prev.currentPage === 1 ? 
        response.notifications.data : 
        [...prev.notificationsList, ...response.notifications.data],
        totalNotificationsCount: response.notification_count,
      }))
    }
    this.setState({ isLoading: false, isNotificationsLoading: false })
  }

  handleActiveTab = (tabName:string) => {
    this.setState({
      activeTab: tabName,
      currentPage: 1,
      notificationsList: [],
      isNotificationsLoading: true,
    },this.getNotificationsListData)
  }

  isActiveTab = (tabId: string) => {
    return this.state.activeTab === tabId ? "activeTab" : ""
  }

  isActiveHeading = (tabId: string) => {
    return this.state.activeTab === tabId ? "" : "inactiveHeading"
  }
  createMessage = (endPoint: string, method: string) => {
    const contactMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.dezinerToken,
    }
    contactMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    contactMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    contactMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

    return contactMessage;
  }

  getNotificationsListData = () => {
    const endPoint = `${configJSON.getNotificationEndpoint}?per_page=${this.perPage}&page=${this.state.currentPage}&filter=${this.state.activeTab}`
    const message = this.createMessage(endPoint,configJSON.getDataMethod);
    this.getNotificationsListId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  getNotificationsCount = () => {
    const message = this.createMessage(configJSON.notificationCountEndpoint,configJSON.getDataMethod);
    this.notificationCountId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  getTokenFromLocalStorage = async () => {
    let tokenDetail = await getStorageData("userInfo");
    const tokenParseData = JSON.parse(tokenDetail);
    let token: string = "";
    if (tokenParseData && tokenParseData.meta) {
      (tokenParseData.meta.token) && (token = tokenParseData.meta.token); 
      this.setState({ dezinerToken: token,}, () => { 
        this.getNotificationsCount(); 
        this.getNotificationsListData()
      })
    }
  }

  getLastDate = (date:string) => {
    const notifyDate = new Date(date);
    const todayDate = new Date();
    const yesterdayDate = new Date(Date.now() - (24*60*60*1000));

    const notifyDateString = `${notifyDate.getDate()}/${notifyDate.getMonth()}/${notifyDate.getFullYear()}`;
    const todayDateString = `${todayDate.getDate()}/${todayDate.getMonth()}/${notifyDate.getFullYear()}`;
    const yesterdayDateString = `${yesterdayDate.getDate()}/${yesterdayDate.getMonth()}/${notifyDate.getFullYear()}`;
    if(notifyDateString === todayDateString) return configJSON.todayText;
    if(notifyDateString === yesterdayDateString) return configJSON.yesterdayText;
    return configJSON.lastWeekText;
  }

  getDate = (date: string) => {
    const shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const dateObj = new Date(date);

    return `${shortMonths[dateObj.getMonth()]} ${dateObj.getDate()}`
  }

  getNotificationCount = (count: string) => {
    return this.state.notificationsCount[count] || 0;
  }

  handleDesingersScrool = () => {
    if (!!this.designersProfileObserver) {
      this.designersProfileObserver.disconnect();
    }

    const userListCallBack = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        if (this.state.notificationsList.length < this.state.totalNotificationsCount) {
          this.setState(prev => ({ currentPage: prev.currentPage + 1, isNotificationsLoading: true }), () => {
            this.getNotificationsListData();
          })
        }
      }
    }
    this.designersProfileObserver = new IntersectionObserver(userListCallBack, { threshold: 0 });
    if (this.designerCardRef.current) {
      this.designersProfileObserver.observe(this.designerCardRef.current)
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(this.state.notificationsList.length > 0 && prevState.notificationsList.length !== this.state.notificationsList.length){
      this.handleDesingersScrool();
    }
  }

  handleNavigateToLandingPage = () => {
    const message: Message =
      new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "LandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
    this.send(message);
  }

  handleNavigationToChatScreen = async (data: any) => {
    setStorageData("navigateUserId", JSON.stringify(data));
    const message: Message =
      new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "Chat");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
    this.send(message);
  }

  navigationCodeBreak2 = async (notification: INotification) => {
    let tokenDetail = await getStorageData("userInfo");
    const tokenParseData = JSON.parse(tokenDetail);

    if (notification.attributes.headings === "Archived Contract") {
      if (notification.attributes.contents.includes("Happy Voyage") || tokenParseData.data.attributes.role_name === "designer") {
        this.props.navigation.navigate("ArchiveDesignerContractPage", { id: notification.attributes.navigation_id })
      } else if (notification.attributes.contents.includes("Exciting update") || tokenParseData.data.attributes.role_name === "client") {
        this.props.navigation.navigate("ArchiveClientContractPage", { id: notification.attributes.navigation_id })
      }
    }

    if (notification.attributes.headings === "Wallet") {
      this.props.navigation.navigate("Settings2", { tab: "Wallet" })
    }
  }

  navigateCodeBreak = async (notification: INotification) => {
    let tokenDetail = await getStorageData("userInfo");
    const tokenParseData = JSON.parse(tokenDetail);
    if (
      notification.attributes.headings === "Proposal" ||
      notification.attributes.headings === "Offer Contract" ||
      notification.attributes.headings === "Work Opportuntiy"
    ) {
      if (tokenParseData.data.attributes.role_name === "designer") {
        let localdata = {
          designerProposalTab: notification.attributes.current_status,
        }
        setStorageData("designerProposalTab", JSON.stringify(localdata));
        this.props.navigation.navigate("FindWork")
      } else if (tokenParseData.data.attributes.role_name === "client") {
        let opportunityData = notification.attributes.proposal_data
        const matchedItem = config.dropDownDataStatic.find((item: DropDownElement) => item.value === opportunityData.value);
        if (matchedItem) {
          opportunityData.color = matchedItem.color;
        }
        setStorageData("dropdown", JSON.stringify(opportunityData));
        setStorageData("CardId", JSON.stringify(notification.attributes.navigation_id));
        this.props.navigation.navigate("MyWork")
      }
    }

    if (notification.attributes.headings === "Forum" || notification.attributes.headings === "Forum Post") {
      this.props.navigation.navigate("Forum", { id: notification.attributes.navigation_id })
    }

    if (notification.attributes.headings === "Chat") {
      this.handleNavigationToChatScreen(notification.attributes.sendbird_data)
    }
  }

  navigateToRoute = async (notification: INotification) => {
    this.viewNotification(notification.attributes.id)
    let tokenDetail = await getStorageData("userInfo");
    const tokenParseData = JSON.parse(tokenDetail);

    if (notification.attributes.headings === "Profile") {
      let localdata = {
        account_id: notification.attributes.navigation_id,
        user_role: notification.attributes.user_role
      }
      if (notification.attributes.contents.includes("inspiration")) {
        let localdata = {
          designerProposalTab: "inspiration"
        }
        setStorageData("designerProposalTab", JSON.stringify(localdata));
      }
      setStorageData("PublicId", JSON.stringify(localdata));
      this.props.navigation.navigate("PublicUserProfile")
    }

    if (notification.attributes.headings === "Project") {
      this.props.navigation.navigate("LandingPage", { id: notification.attributes.navigation_id })
    }

    if (notification.attributes.headings === "Active Contract" || notification.attributes.headings === "Contract") {
      if (tokenParseData.data.attributes.role_name === "designer") {
        this.props.navigation.navigate("ActiveContractPage", { id: notification.attributes.navigation_id })
      } else if (tokenParseData.data.attributes.role_name === "client") {
        this.props.navigation.navigate("ContractDetails", { id: notification.attributes.navigation_id })
      }
    }

    this.navigateCodeBreak(notification)
    this.navigationCodeBreak2(notification)
  }

  viewNotification = (id: number) => {
    const endPoint = `${configJSON.getNotificationEndpoint}/${id}`
    const message = this.createMessage(endPoint,configJSON.getDataMethod);
    this.viewNotificationApiCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  // Customizable Area End
}
